import LoginWindow from '../components/login/LoginWindow';
import Carousel from '../components/login/Carousel';
import './Login.scss';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileLogin from 'components/login/MobileLogin';
import { useWindowSize } from 'utils/useWindowSize';

function Login() {
  const windowSize = useWindowSize();

  return (
    <section className="login">
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <LoginWindow />
          </>
        }
        mobile={
          <MobileLogin />
        }
      />
    </section>
  );
}

export default Login;
