import Activity from './Activity'
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import './ActivityWidget.scss';

// Returns all activity items of the given ActivityType
const ActivityWidget = ({ activitiesList }: any) => {
  return (
    <Droppable droppableId={ activitiesList._id }>
      {(provided: DroppableProvided) => (
        <ul className="activity-menu-column" { ...provided.droppableProps } ref={ provided.innerRef }>
          {activitiesList.activities.map(({ _id, name, color, club_url }: any, index: number) => {
            return <Activity key={ _id } name={ name } color={ color } _id={ _id } index={ index } clubUrl={ club_url } />
          })}
          { provided.placeholder } {/*warning message fix*/}
        </ul>
      )}
    </Droppable>   
  )
}
export default ActivityWidget;
