import { createSlice } from '@reduxjs/toolkit'
import { UserInfo, blankUserInfo } from '../interfaces/user-data-interface'

interface SetUserAction {
  type: string;
  payload: {
    userInfo: UserInfo;
    rememberMe: boolean;
  };
}

const storedUserInfo = localStorage.getItem('userInfo') || sessionStorage.getItem('userInfo');

// If there is a user info in local storage, parse it and use it as initial state
const initialState: { userInfo: UserInfo } = {
  userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : blankUserInfo
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: SetUserAction) => {
      state.userInfo = action.payload.userInfo;

      if (action.payload.rememberMe) {
        localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo));
      } else {
        sessionStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo));
      }
    },
    removeUser: (state) => {
      state.userInfo = blankUserInfo;

      localStorage.removeItem('userInfo');
      sessionStorage.removeItem('userInfo');
    }
  }
})

export const { setUser, removeUser } = authSlice.actions

export default authSlice.reducer