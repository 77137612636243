import './Modal.scss'
import "./react-draft-wysiwyg.css";
import Dropdown from '../dropdown/Dropdown';
import { useState, useEffect } from 'react';
import FileUploadArea from './FileUploadArea';
import axios from 'utils/axios';
// import IconPickerModal from './IconPickerModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
// Redux
import { RootState } from '../../redux/store';
import { setModalActivity } from '../../redux/editorSlice'
import { useSelector, useDispatch } from 'react-redux'
import UniversalModal from '../universal-modal/UniversalModal';
import { Tooltip } from 'flowbite-react';
// Wysiwyg editor
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// KeyboardShorcut
import { KeyboardShortcuts } from '../../utils/KeyboardShortcuts';
// SVGs
import { ReactComponent as Trash } from "../../images/trash.svg";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as EyeSlashed } from "../../images/eye-slashed.svg";
import { ReactComponent as EyeForward } from "../../images/eye-forward.svg";
import { ReactComponent as ArrowIcon } from '../../images/arrow.svg';
import { ReactComponent as ClassIcon } from "../../images/activity-icons/class.svg";
import { ReactComponent as GroupIcon } from "../../images/activity-icons/group.svg";
import { ReactComponent as DoubleIcon } from "../../images/activity-icons/double.svg";
import { ReactComponent as SingleIcon } from "../../images/activity-icons/single.svg";
import { ReactComponent as SpeachIcon } from "../../images/activity-icons/speach.svg";
import { ReactComponent as DoubleSpeachIcon } from "../../images/activity-icons/double-speach.svg";
import { ReactComponent as ReadIcon } from "../../images/activity-icons/read.svg";
import { ReactComponent as WriteIcon } from "../../images/activity-icons/write.svg";
import { ReactComponent as LabIcon } from "../../images/activity-icons/lab.svg";
import { ReactComponent as ListenIcon } from "../../images/activity-icons/listen.svg";

const organizationIcons = [
  { name: "class", component: ClassIcon, tooltip: "Celá třída" },
  { name: "group", component: GroupIcon, tooltip: "Ve skupinách" },
  { name: "double", component: DoubleIcon, tooltip: "Ve dvojicích" },
  { name: "single", component: SingleIcon, tooltip: "Samostatně" },
];

const activityIcons = [
  { name: "speach", component: SpeachIcon, tooltip: "Mluvený projev" },
  { name: "double-speach", component: DoubleSpeachIcon, tooltip: "Interakce" },
  { name: "read", component: ReadIcon, tooltip: "Čtení" },
  { name: "write", component: WriteIcon, tooltip: "Psaní" },
  { name: "lab", component: LabIcon, tooltip: "Experiment" },
  { name: "listen", component: ListenIcon, tooltip: "Poslech" },
];

const Modal = (props: any) => {
  const modalActivity = useSelector((state: RootState) => state.editor.modalActivity)
  const [activityDescEditorState, setActivityDescEditorState] = useState(EditorState.createEmpty())
  const dispatch = useDispatch();
  const [ name, setName ] = useState<string>("");
  const [ desc, setDesc ] = useState<string>("");
  const [ size, setSize ] = useState<number>();
  const [ files, setFiles ] = useState<{name: string, visibility: string, url_to_file: string, description: string}[]>([{name: "", visibility: "student", url_to_file: "",description: ""}]);
  const [ gadgets, setGadgets ] = useState<string[]>(['']);
  const [ icons, setIcons ] = useState<string[]>([]);
  const [ urls, setUrls ] = useState<{name: string, visibility: string, description: string}[]>([{name: "", visibility: "student", description: ""}]);

  useEffect(() => {
    setFiles(modalActivity?.files || [{name: "", visibility: "student", description: "", url_to_file: ""}])
    setUrls(modalActivity?.urls || [{name: "", visibility: "student", description: ""}])
    setIcons(modalActivity?.icons || [])
    setName(modalActivity?.name || "")
    setSize(modalActivity?.size || 5)
    setDesc(modalActivity?.description || "")
    setGadgets(modalActivity?.gadgets || [''])
    setActivityDescEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(modalActivity?.description || '<p></p>').contentBlocks)));
  }, [modalActivity]);

  useEffect(() => {
    if(modalActivity !== null) saveChanges()
  }, [name, size, files, icons, urls, desc, gadgets]);

  /**
   * Called when user clicks on the Uložit button.
  */
  const saveAndClose = () => {
    if (name.trim() === "") {
      window.alert("Aktivita musí mít název!")
    } else {
      saveChanges();
      dispatch(setModalActivity(null));
    }
  }

  // When the user clicks on <span> (Uložit), close the modal
  const saveChanges = () => {
    const activity = JSON.parse(JSON.stringify(modalActivity));
    activity.description = desc;
    activity.name = name;
    activity.files = files;
    activity.size = size;
    activity.urls = urls;
    activity.gadgets = gadgets;
    activity.icons = icons;
    props.onActivityChange(activity);
  }

  const organizationIconNames = organizationIcons.map(icon => icon.name);
  const activityIconNames = activityIcons.map(icon => icon.name);
  
  const removeAddIcon = (path: string) => {
    let iconsCopy = JSON.parse(JSON.stringify(icons));
    if (iconsCopy.includes(path)) {
      iconsCopy = iconsCopy.filter((item: string) => item !== path );
    } else {
      if(organizationIconNames.includes(path)){
        iconsCopy = iconsCopy.filter((value: string) => !organizationIconNames.includes(value));
        iconsCopy.push(path);
      } else {
        iconsCopy = iconsCopy.filter((value: string) => !activityIconNames.includes(value));
        iconsCopy.push(path);
      }
    }
    setIcons([...iconsCopy]);
  }
  

  const changeGadget = (event:any, index: number) => {
    const gadgetsCopy = JSON.parse(JSON.stringify(gadgets));
    if (event.target.value.length > 255) return alert("Pomůcka nesmí mít delší název než 255 znaků!");
    gadgetsCopy[index] = event.target.value;
    setGadgets([...gadgetsCopy]);
  }

  const removeGadget = (index: number) => {
    let gadgetsCopy = JSON.parse(JSON.stringify(gadgets));
    gadgetsCopy.splice(index, 1);
    if (!gadgetsCopy.length) gadgetsCopy = [""];
    setGadgets([...gadgetsCopy]);
  }

  const changeFile = (index: number, changeType: string, value: string) => {
    if (changeType === "name" && value.length > 1023) return alert("Soubor nesmí mít delší název než 1023 znaků!");
    if (changeType === "description" && value.length > 1023) return alert("Poznámka nesmí být delší než 1023 znaků!");
  
    setFiles(files => {
      const newFiles = [...files];
      const updatedFile = { ...newFiles[index], [changeType]: value };
      newFiles[index] = updatedFile; 
      return newFiles;
    });
  }

  const removeFile = (index: number) => {
    let filesCopy = JSON.parse(JSON.stringify(files))
    filesCopy.splice(index, 1);
    if (!filesCopy.length) filesCopy = [{name: "", visibility: "student", description: "", url_to_file: ""}];
    setFiles([...filesCopy]);
  }

  const removeUrl = (index: number) => {
    let urlsCopy = JSON.parse(JSON.stringify(urls))
    urlsCopy.splice(index, 1);
    if (!urlsCopy.length) urlsCopy = [{name: "", visibility: "student", description: ""}];
    setUrls([...urlsCopy]);
  }

  const changeUrl = (index: number, changeType: string, value: string) => {
    const urlsCopy = JSON.parse(JSON.stringify(urls));
    if (changeType === "name" && value.length > 1023) return alert("Soubor nesmí mít delší název než 1023 znaků!");
    if (changeType === "description" && value.length > 1023) return alert("Poznámka nesmí být delší než 1023 znaků!");
    urlsCopy[index][changeType] = value;
    setUrls([...urlsCopy]);
  }
  
  const toggleCommentVisibility = (id: string) => {
    const el = document.getElementById(id);
    if (el !== null) el.classList.contains("active") ? el.classList.remove("active") : el.classList.add("active");
  }

  const changeName = (event: any) => {
    if (event.target.value.length > 127) return alert("Název hodiny nesmí mít delší než 127 znaků!");
    setName(event.target.value)
  }

  const onFileUpload = async (index: number, acceptedFiles: File[]) => {
    const [file] = acceptedFiles;

    const formData = new FormData();
    formData.append('activity_id', modalActivity.id);
    formData.append('file', file);
    
    try {
      
      const { data: { file_id, file_name }} = await axios.post('/api/upload-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      changeFile(index, "url_to_file", file_id);
      changeFile(index, "name", file_name);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  return modalActivity === null ? null : (
    <UniversalModal isEdit={true}>
      <KeyboardShortcuts context="activityModal"></KeyboardShortcuts>
      <div className='modal-activity'>
      <div className='modal-input'>
          <label htmlFor="name-edit-input">Název: </label>
          <input id="name-edit-input" maxLength={127} className='text-input' type="text" onChange={(event) => changeName(event)} defaultValue={ modalActivity.name } />
        </div>
        <div className='modal-input'>
          <label htmlFor="desc-edit-input">Popis: </label>
          <WysiwygEditor
            editorState={ activityDescEditorState }
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: ['inline', 'fontSize', 'list', 'emoji', 'remove', 'history'],
              inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] }
            }}
            placeholder="Popis aktivity..."
            onEditorStateChange={ (newState: any) => {
              let hasAtomicValue = false
              newState.getCurrentContent().blockMap.forEach((element: any) => {
                if (element.type === "atomic") hasAtomicValue = true
              })
              if (hasAtomicValue) {
                alert("Používáte nepodporované znaky. Zkopírujte text do poznámkového bloku a obsah znovu zkopírujte a vložte!");
                return;
              }
              if (draftToHtml(convertToRaw(newState.getCurrentContent())).length > 8191) return alert("Popis aktivity nesmí být delší než 8191 raw znaků!");
              setActivityDescEditorState(newState);
              setDesc(draftToHtml(convertToRaw(newState.getCurrentContent())));
      }}
          />
        </div>
        <div className='modal-input'>
          <label htmlFor="size-edit-input">Délka aktivity: </label>
          <input id="size-edit-input" className='text-input' type="number" min='1' 
            onChange={
              (event) => {
                const value = parseInt(event.target.value);
                if(isNaN(value) || value < 1)
                  setSize(1)
                else
                  setSize(value)
              }
            } 
            onWheel={(e) => {
              // disable focus on scroll to prevent value change
              (e.target as HTMLInputElement).blur();
            }}
            defaultValue={ modalActivity.size } />
        </div>
        <div className='modal-input flex'>
          <div className='flex justify-between items-center'>
            <label htmlFor="file-edit-input">Pomůcky: </label>
            <FontAwesomeIcon className='plus' onClick={() => setGadgets([...gadgets, ''])} icon={solid("plus")} />
          </div>
          <div className='inputs'>
            {gadgets.length > 1 ? gadgets?.map( (gadgetName: string, index: number) => {
                return (
                  <div key={index} className="modal-input">
                    <input id="gadgets-edit-input" maxLength={255} className='input-padding' type="text" onChange={(event) => changeGadget(event, index)} value={ gadgetName } />
                    <Trash className='trash' onClick={() => removeGadget(index)}/>
                  </div>  
                  )
              }) : (
                <div className="modal-input">
                  <input id="gadgets-edit-input" maxLength={255} className='input-padding' type="text" onChange={(event) => changeGadget(event, 0)} value={ gadgets[0] || '' } />
                </div>
              )
            }
          </div>
        </div>
        <div className='modal-input flex'>
          <div className='flex justify-between items-center'>
            <label htmlFor="file-edit-input">Soubory: </label>
            <FontAwesomeIcon className='plus' onClick={() => setFiles([...files, {name: "", visibility: "student", description: "", url_to_file: ""}])} icon={solid("plus")} />
          </div>
          <div className='inputs inputs-with-comments'>
            {files?.map( (fileItem: {name: string, visibility: string, description: string}, index: number) => {
              return (
                <div key={index} className="modal-input">
                  <FileUploadArea
                    onFileUpload={(acceptedFiles) => onFileUpload(index, acceptedFiles)}
                    file={fileItem.name}
                  />
                  <div className="dropdown-wrapper" style={{right: files.length > 0 ? "42px" : "10px"}}>
                    <Dropdown
                      label={
                        files[index].visibility === "student" ? (
                          <Eye className="visibility"/>
                        ) : files[index].visibility === "teacher" ? (
                          <EyeSlashed className="visibility"/>
                        ) : files[index].visibility === "after-lesson" ? (
                          <EyeForward className="visibility"/>
                        ) : <Eye className="visibility"/>
                      }
                      upPlacement={true}
                      noArrow={true}
                      defaultClasses={false}
                      menuMiddle={true}
                      menuClasses="mb-[-1rem]"
                    >
                      <Dropdown.Item onClick={() => changeFile(index, "visibility", "student")} className="text-base w-full inline-flex p-3 rounded-md">
                        <Eye className="visibility-dropdown-icon"/>
                        Pro žáka
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeFile(index, "visibility", "teacher")} className="text-base w-full inline-flex p-3 rounded-md">
                        <EyeSlashed className="visibility-dropdown-icon"/>
                        Pro učitele
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeFile(index, "visibility", "after-lesson")} className="text-base w-full inline-flex p-3 rounded-md">
                        <EyeForward className="visibility-dropdown-icon"/>
                        Pro žáka po hodině
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                  
                  {files.length > 0 &&
                  <Trash className='trash' onClick={() => removeFile(index)}/>}
                  <div className="comment" id={`file-comment-${index}`}>
                    <button className='comment-button' onClick={() => toggleCommentVisibility(`file-comment-${index}`)}>
                      <p className='add-comment'>Přidat komentář</p>
                      <ArrowIcon title="comment-arrow" className='comment-arrow' />
                    </button>
                    <textarea maxLength={1023} className='comment-textarea' rows={2} value={fileItem.description}
                    onChange={(event) => changeFile(index, "description", event.target.value)}/>
                  </div>
                </div>  
                )
            })}
          </div>
        </div>
        <div className='modal-input flex'>
          <div className='flex justify-between items-center'>
            <label htmlFor="link-edit-input">Odkazy: </label>
            <FontAwesomeIcon className='plus' onClick={() => setUrls([...urls, {name: "", visibility: "student", description: ""}])} icon={solid("plus")} />
          </div>
          <div className='inputs inputs-with-comments'>
            {urls?.map((urlItem: {name: string, visibility: string, description: string}, index: number) => {
                return (
                  <div key={index} className="modal-input">
                    <input key={index} id="link-edit-input" maxLength={1023} className={urls.length > 1 ? 'input-padding-multi-icons' : 'input-padding'} type="text"
                    onChange={(event) => changeUrl(index, "name", event.target.value)} value={ urls.length > 1 ? urlItem.name : urls[0].name || '' } />
                    <div className="dropdown-wrapper" style={{right: urls.length > 1 ? "42px" : "10px"}}>
                      <Dropdown
                        label={
                          urls[index].visibility === "student" ? (
                            <Eye className="visibility"/>
                          ) : urls[index].visibility === "teacher" ? (
                            <EyeSlashed className="visibility"/>
                          ) : urls[index].visibility === "after-lesson" ? (
                            <EyeForward className="visibility"/>
                          ) : <Eye className="visibility"/>
                        }
                        upPlacement={true}
                        noArrow={true}
                        defaultClasses={false}
                        menuMiddle={true}
                        menuClasses="mb-[-1rem]"
                      >
                        <Dropdown.Item onClick={() => changeUrl(index, "visibility", "student")} className="text-base w-full inline-flex p-3 rounded-md">
                          <Eye className="visibility-dropdown-icon"/>
                          Pro žáka
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => changeUrl(index, "visibility", "teacher")} className="text-base w-full inline-flex p-3 rounded-md">
                          <EyeSlashed className="visibility-dropdown-icon"/>
                          Pro učitele
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => changeUrl(index, "visibility", "after-lesson")} className="text-base w-full inline-flex p-3 rounded-md">
                          <EyeForward className="visibility-dropdown-icon"/>
                          Pro žáka po hodině
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                    
                    {urls.length > 1 && 
                    <Trash className='trash' onClick={() => removeUrl(index)}/>}
                    <div className="comment" id={`url-comment-${index}`}>
                      <button className='comment-button' onClick={() => toggleCommentVisibility(`url-comment-${index}`)}>
                        <p className='add-comment'>Přidat komentář</p>
                        <ArrowIcon title="comment-arrow" className='comment-arrow' />
                      </button>
                      <textarea maxLength={1023} className='comment-textarea' rows={2} value={urlItem.description}
                      onChange={(event) => changeUrl(index, "description", event.target.value)}/>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className='modal-input flex gap-1'>
          <label htmlFor="icon-edit-input">Organizační forma</label>
          <div className='flex gap-4' style={{marginRight: '-14px'}}>
          {organizationIcons?.map( ({ name, component: Icon, tooltip }, index: number) => {
              return (
                <div key={index} className="flex">
                  <Tooltip key={index} id={"organization-icon-" + index} content={tooltip}>
                    <Icon className={icons.includes(name) ? 'icon' : 'icon-light'} onClick={() => removeAddIcon(name)}/>
                  </Tooltip>
                </div>  
              )
            }
            )}
          </div>
        </div>
        <div className='modal-input flex gap-1'>
          <label htmlFor="icon-edit-input">Forma činnosti</label>
          <div className='flex gap-4' style={{marginRight: '-14px'}}>
          {activityIcons?.map( ({ name, component: Icon, tooltip }, index: number) => {
              return (
                <div key={index} className="flex">
                  <Tooltip key={index} id={"activity-icon-" + index} content={tooltip}>
                    <Icon className={icons.includes(name) ? 'icon' : 'icon-light'} onClick={() => removeAddIcon(name)}/>
                  </Tooltip>
                </div>  
              )
            }
            )}
          </div>
        </div>
        <button id="saveButtonActivityModal" type="button" className="save-button-gray" onClick={ saveAndClose }>Uložit</button>
      </div>
    </UniversalModal>
  )
}
export default Modal;
