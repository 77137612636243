import useLessonMenuLogic from "./hooks/useLessonMenuLogic";
import AppBar from "components/mobile-utils/AppBar";
import StudentSelectionMenu from "./StudentSelectionMenu";
import { MenuTypes } from "pages/LessonMenu";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";

const MobileLessonMenu = () => {

  const {
    subjectNames,
    activeSubject,
    unselectedSubjectNames,
    teachingUnitsNames,
    activeTeachingUnit,
    unselectedTeachingUnitsNames,
    lessonNames,
    unselectedLessons,
    lessonProgress,
    setActiveSubject,
    setActiveTeachingUnit,
    setActiveLesson,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setUnselectedSubjectNames,
    setUnselectedTeachingUnitsNames,
    setUnselectedLessons,
    setSubjectsInUserData,
    setTeachingUnitsInUserData,
    setLessonsInUserData,
    obtainLessons,
    handleOpenButton
  } = useLessonMenuLogic();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/subjects") navigate("/subjects")
  }, [])

  // a function for navigating to the next level of menu
  const nextMenu = () => {
    const currentPath = location.pathname;

    if (currentPath === '/subjects') {
      navigate(`/subjects/teaching-units`);
    } else if (currentPath === '/subjects/teaching-units') {
      navigate(`/subjects/lessons`);
    }
  }

  // a function for navigating to the previous level of menu
  const previousMenu = () => {
    if (location.pathname === "/subjects") navigate("/view-selection")
    else navigate(-1);
  }

  // a function that wraps the setSelectionList function and navigates to the next level of menu
  const nextMenuWrapper = (setSelectionList: (list: string[]) => void) => {

    return (list: string[]) => {
      setSelectionList(list);
      nextMenu();
    }
  }

  let title;
  if (location.pathname === '/subjects/teaching-units') {
    title = activeSubject;
  } else if (location.pathname === '/subjects/lessons') {
    title = activeTeachingUnit;
  } else {
    title = 'Předměty';
  }

  return (
    <>
      <AppBar />
      <div className="flex flex-col flex-grow">
        <div className="p-3 border-b border-black border-dashed flex items-center">
          <button
            onClick={previousMenu}
            className="flex items-center justify-center h-7 w-7 border border-black p-1 mr-2 rounded focus:outline-none"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          {title}
        </div>
        <div className="p-3 flex flex-grow flex-col overflow-auto">
          <div className="p-1 border border-black rounded flex-grow">
            <Routes>
              <Route path="/" element={
                <StudentSelectionMenu
                  list={subjectNames}
                  setList={setSubjectsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionSubjectNames)}
                  unselected={unselectedSubjectNames}
                  setUnselected={setUnselectedSubjectNames}
                  setActiveList={setActiveSubject}
                  subjectSelect={false}
                  type={MenuTypes.SUBJECT}
                />
              } />
              <Route path="/teaching-units" element={
                <StudentSelectionMenu
                  list={teachingUnitsNames}
                  setList={setTeachingUnitsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionTeachingUnitsNames)}
                  unselected={unselectedTeachingUnitsNames}
                  setUnselected={setUnselectedTeachingUnitsNames}
                  setActiveList={setActiveTeachingUnit}
                  subjectSelect={true}
                  obtainLessons={obtainLessons}
                  activeSubject={activeSubject}
                  type={MenuTypes.TEACHING_UNIT}
                />
              } />
              <Route path="/lessons" element={
                <StudentSelectionMenu
                  list={lessonNames}
                  setList={setLessonsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionLessons)}
                  unselected={unselectedLessons}
                  setUnselected={setUnselectedLessons}
                  setActiveList={setActiveLesson}
                  subjectSelect={false}
                  lessonSelect={true}
                  lessonProgress={lessonProgress}
                  type={MenuTypes.LESSON}
                  handleOpenButton={handleOpenButton}
                  mobile={true}
                />
              } />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileLessonMenu