import './Timeline.scss';

const Timeline = (props: any) => {
    const lenght = props.lengthInMins/5;
    const timeLine = Array.from(Array(lenght), (x, i) => ((i+1) * 5));

    return (
      <ul className="timeline">
        {timeLine.map((element) => <li key={element}>{element} min</li>)}
      </ul>
    )
  }

export default Timeline