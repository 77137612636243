import { useState, useRef, useEffect, CSSProperties } from "react";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { MenuTypes } from '../../pages/LessonMenu'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { ReactComponent as Eye } from "../../images/eye.svg";
import LessonContextMenu from "./LessonContextMenu";
import EmptyContextMenu from "./EmptyContextMenu";

const closedContextMenu = { visible: false, x: 0, y: 0, type: '' };

const SelectionMenu = (props: any) => {

  const { openErrorSnackbar } = useSnackbar();
  const [contextMenu, setContextMenu] = useState(closedContextMenu);
  const contextMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleDocumentClick(e: MouseEvent) {
      if (contextMenuRef.current && !contextMenuRef.current.contains(e.target as Node)) {
        setContextMenu(closedContextMenu);
      }
    }

    if (contextMenu.visible) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [contextMenu.visible, contextMenuRef]);

  const handleClick = (value: string) => {
    if (props.subjectSelect) {
      if (value === '') return props.setList(null)
      props.obtainLessons(props.activeClass, props.activeSubject, value)
    }
    if (value === "unselected") {
      props.setActiveList("");
      select(-1);
      if (!props.subjectSelect && !props.lessonSelect) props.filter("");
    } else {
      props.setActiveList(value);
      select(props.list.indexOf(value));
      if (!props.subjectSelect && !props.lessonSelect) {
        props.filter(value);
      }
    }
  };

  const getItemStyle = (
    index: number,
    isDragging?: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle | undefined
  ): CSSProperties => {
    // const selectedItem = index !== -1
    // ? props.selectionList[index] === "selected" ? "rgba(128, 7, 170, 0.3)" : "rgba(128, 7, 170, 0.15)"
    // : props.unselected ? "rgb(194 161 210)" : "rgb(234, 222, 240)";
    const selectedItem = !props.unselected ?
      props.selectionList[index] === "selected" ? "rgba(210, 174, 223, 1)" : "rgba(128, 7, 170, 0.1)"
      : index !== -1 ? "#FFF" : "rgba(128, 7, 170, 0.15)";
    const borderColor =
      props.selectionList[index] === "selected"
        ? "rgb(185, 124, 208)"
        : "rgba(210, 174, 223, 1)";

    return {
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      padding: 4,
      margin: "0 0 4px 0",
      border: `solid 1px ${borderColor}`,
      borderRadius: "4px",
      background: isDragging ? "rgb(194, 161, 210)" : selectedItem,
      position: "relative",
      cursor: "pointer",
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    padding: 8,
    width: "100%",
  });

  const onDragEnd = async (result: any) => {
    if (!result.destination) return
    const newItems = [...props.list];
    const selectedIndex = props.selectionList.indexOf("selected");
    const value = props.list[selectedIndex];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    try {
      props.setList(newItems);
      select(newItems.indexOf(value));
      await props.updateClasses(undefined, props.type);
      if (props.type === MenuTypes.LESSON) props.findPublished()
    } catch (error) {
      openErrorSnackbar("Nepodařilo se změnit pořadí.");
    }
  };

  const select = (index: number) => {
    const newSelection = new Array(props.list.length);
    newSelection.fill("unselected");
    props.setUnselected(index === -1 ? true : false);
    newSelection[index] = "selected";
    props.setSelectionList(newSelection);
  };

  const isDragDisabled = () => {
    if (props.type === MenuTypes.CLASS && props.activeSubject) return true
    else return props.unselectedClassNames
  }

  const handleCopy = () => {
    props.handleCopy();
    closeMenu();
  }

  const handleCut = () => {
    props.handleCut();
    closeMenu();
  }

  const handlePaste = () => {
    props.handlePaste();
    closeMenu();
  }

  const closeMenu = () => setContextMenu(closedContextMenu);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"droppable" + props.type}>
        {(provided) => (
          <div
            onContextMenu={(e) => {
              if (props.type === MenuTypes.LESSON) {
                e.preventDefault();
                setContextMenu({ visible: true, x: e.clientX, y: e.clientY, type: 'empty' });
              }
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
            className="h-full"
          >
            <div
              onClick={() => handleClick("unselected")}
              style={getItemStyle(-1)}
              className="whitespace-nowrap cursor-pointer"
            >
              --- Nevybráno ---
            </div>
            {props.list.map((value: string, index: number) => (
              <Draggable
                key={value}
                draggableId={value}
                index={index}
                isDragDisabled={isDragDisabled()}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      index,
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    onClick={() => handleClick(value)}
                    onContextMenu={(e) => {
                      e.stopPropagation();
                      if (props.type === MenuTypes.LESSON) {
                        e.preventDefault();
                        handleClick(value);
                        setContextMenu({ visible: true, x: e.clientX, y: e.clientY, type: 'lesson' });
                      }
                    }}
                  >
                    <div className={`overflow-x-hidden ${!isDragDisabled() && "mr-2.5"}`}>
                      {value}
                    </div>
                    {props.publishedLessons && props.publishedLessons.includes(value) ?
                      <Eye
                        style={{
                          marginLeft: "auto",
                          height: "20px",
                          color: `${props.selectionList[index] === "selected" ? "rgb(185,124,208)" : "rgba(210, 174, 223, 1)"}`
                        }} />
                      :
                      null
                    }
                    {
                      isDragDisabled() ? null :
                        <div
                          className="handler"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: "1px",
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            borderLeft: `1px solid ${props.selectionList[index] === "selected" ? "rgb(185,124,208)" : "rgba(210, 174, 223, 1)"}`,
                            paddingInline: "4.5px",
                            color: `${props.selectionList[index] === "selected" ? "rgb(185,124,208)" : "rgba(210, 174, 223, 1)"}`
                          }}
                          {...provided.dragHandleProps}
                        >
                          <FontAwesomeIcon style={{ height: "10px" }} icon={solid('ellipsis-vertical')} />
                        </div>
                    }
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {contextMenu.visible && props.type === MenuTypes.LESSON && (
        contextMenu.type === 'lesson' ? (
          <LessonContextMenu x={contextMenu.x} y={contextMenu.y} handleCopy={handleCopy} handleCut={handleCut} closeMenu={closeMenu} />
        ) : (
          <EmptyContextMenu x={contextMenu.x} y={contextMenu.y} handlePaste={handlePaste} closeMenu={closeMenu} />
        )
      )}
    </DragDropContext>
  );
};

export default SelectionMenu;
