import './Lesson.scss';
import LessonRow from './LessonRow';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggingStyle, Droppable, DroppableProvided, NotDraggingStyle } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const Lesson = (props: any) => {
  
    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
      width: `100%`,
      minHeight: `calc(var(--editor-five-min-height)/5)`,
      // background: isDragging ? "#efefef" : "white",
      display: `flex`,
      // outline: `1px solid rgba(0, 0, 0, 0.3)`,
      borderRadius: `4px`,
      // overflow: `hidden`,
    
      ...draggableStyle
    });
  
    return (
          <Droppable droppableId="Lesson" type="droppableItem">
            {(provided: DroppableProvided) => (
              <div className="lesson" { ...provided.droppableProps } ref={ provided.innerRef }>
                { props.activitiesList.map( ({ _id, activities }: any, index: number) => { 
                  return (
                    activities.length !== 0 ?
                    <Draggable
                      key={ `lesson-` + _id } 
                      draggableId={ `lesson-` + _id } 
                      index={ index }>

                      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <div
                          ref={ provided.innerRef }
                          { ...provided.draggableProps }
                          style={ getItemStyle(snapshot.isDragging, provided.draggableProps.style) }>
                            {<div className="flex items-center pl-3 pr-3 handler" { ...provided.dragHandleProps }>
                              <FontAwesomeIcon style={{height: "13px"}} icon={solid('ellipsis-vertical')}></FontAwesomeIcon>
                            </div>}
                            {<LessonRow key={ index } _id={ _id } activities={ activities } />}
                        </div>
                    )}
                    </Draggable>
                    : 
                    <Droppable key={index} droppableId={ _id } direction="horizontal">
                      {(provided: DroppableProvided) => (
                        <ul className="lesson-row flex-1 pl-7" { ...provided.droppableProps } ref={ provided.innerRef }>
                      { provided.placeholder } {/* warning message fix */}
                      </ul>
                    )}
                    </Droppable>  
                  );
                })}
                { provided.placeholder } 
              </div>
            )}
          </Droppable>
    )
  }

  export default Lesson