import { PropsWithChildren, useState } from 'react'
import 'components/editor/lesson-window/lesson/accessories/LessonHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react'

interface CalendarMenuWindowProps extends PropsWithChildren<any> {
  lessonName: string | null;
  lessonGoal: string | null;
  lessonCurriculums: string[] | null;
}

const MobileLessonHeader = ({lessonName, lessonGoal, lessonCurriculums}: CalendarMenuWindowProps) => {
  const [goalOpen, setGoalOpen] = useState<boolean>(false);
  const [curriculumsOpen, setCurriculumsOpen] = useState<boolean>(false);

  return (
    <div className="lesson-header space-y-2 pr-4 text-sm">
      <h2 className="text-2xl border-none text-black font-medium">
        {lessonName}
      </h2>
      {
        lessonGoal && lessonGoal.length > 0 &&
        <div>
          <div className='flex gap-2 items-center'>
            <div className='text-md font-semibold'>Cíl:</div>
            <button
              onClick={() => setGoalOpen(!goalOpen)}
              className="flex items-center justify-center h-6 w-6 border border-black p-1 mr-2 rounded focus:outline-none"
            >
              <FontAwesomeIcon icon={goalOpen ? faChevronUp : faChevronDown} />
            </button>
          </div>
          <SlideDownTransition isOpen={goalOpen}>
            <div className="mt-2">
              {lessonGoal}
            </div>
          </SlideDownTransition>
        </div>
      }
      {
        lessonCurriculums && lessonCurriculums.length > 0 &&
        <div>
          <div className='flex gap-2 items-center'>
            <div className='text-md font-semibold'>Výstupy:</div>
            <button
              onClick={() => setCurriculumsOpen(!curriculumsOpen)}
              className="flex items-center justify-center h-6 w-6 border border-black p-1 mr-2 rounded focus:outline-none"
            >
              <FontAwesomeIcon icon={curriculumsOpen ? faChevronUp : faChevronDown} />
            </button>
          </div>
          <SlideDownTransition isOpen={curriculumsOpen}>
            <ul className='list-disc list-inside divide-y divide-dashed divide-black'>
              {lessonCurriculums && lessonCurriculums.map((curriculum: string, index: number) => (
                <li key={index} className='py-2'>{curriculum}</li>
              ))}
            </ul>
          </SlideDownTransition>
        </div>
      }
    </div>
  )
}

const SlideDownTransition = ({ isOpen, children }: { isOpen: boolean, children: React.ReactNode }) => (
  <Transition
    show={isOpen}
    enter="transition ease-out duration-200 transform"
    enterFrom="opacity-0 -translate-y-2"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-in duration-200 transform"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 -translate-y-2"
  >
    {children}
  </Transition>
);

export default MobileLessonHeader;
