import './LoginWindow.scss';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
// SVGs
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as EyeSlashed } from "../../images/eye-slashed.svg";
import { ReactComponent as EduboIcon } from "../../images/edubo.svg";
import useLogin from './useLogin';
import InfoFooter from 'components/info-pages/InfoFooter';

function LoginWindow() {
  const [passwordShown, setPasswordShown] = useState(false);    
  const navigate = useNavigate()
  const { register, onSubmit, handleSubmit, errors } = useLogin();

  const togglePassword = (e: { stopPropagation: () => void; } ) => {
    e.stopPropagation();
    setPasswordShown(!passwordShown);
  };

  return (
    <section className="login_window">
      <div className='login_container'>
        <EduboIcon className='logo'/>
        <form className="data" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <div className='desc'>E-MAIL:</div>
            <input placeholder="Zadejte e-mail" {...register("email")}></input>
            {errors.email && <p className="absolute mt-1 text-sm text-red-600">{errors.email.message}</p>}
          </div>
          <div className="w-full">
            <div className="flex flex-col relative">
              <div className='desc'>HESLO:</div>
              <input type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte heslo" {...register("password")}></input>
              {passwordShown ? <Eye className="eye shown" onClick={togglePassword} /> : <EyeSlashed className="eye" onClick={togglePassword} />}
            </div>
            {errors.password && <p className="absolute mt-1 text-sm text-red-600">{errors.password.message}</p>}
            {errors.apiError && <p className="absolute mt-1 text-sm text-red-600">{errors.apiError?.message}</p>}
          </div>
          <button type="submit" className="select-none">Přihlásit se</button>
          <button type="button" className="register" onClick={() => navigate("/registration")}>Registrovat se</button>
          <div className="flex items-center justify-center remember-me">
            <input id="remember" type="checkbox" className="square-checkbox" {...register("remember")} />
            <label htmlFor="remember" className="ml-2">ZAPAMATOVAT SI PŘIHLÁŠENÍ</label>
          </div>
          <div className='flex items-center justify-center forgotten-password'>
          <Link to="/changepassword">ZAPOMENUTÉ HESLO</Link>
          </div>
        </form>
      </div>
      <InfoFooter page={"login"}/>
    </section>
  )
}

export default LoginWindow