import { Calendar } from 'react-calendar';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import { useModal } from "contexts/ModalProvider";
import './CalendarModal.scss'
// SVGs
import { ReactComponent as Next } from "images/student-lesson-menu-icons/next.svg";
import { ReactComponent as Previous } from "images/student-lesson-menu-icons/previous.svg";

const CalendarModal = (props: any) => {
  const { closeModal } = useModal()

  return (
    <div className='mobile-calendar-modal'>
      <KeyboardShortcuts context="mobileCalendarModal" />
      <h1 className='calendar-modal-heading'>Kalendář</h1>
      <Calendar
        className="student-calendar-content"
        value={props.selectedDate}
        onChange={(newDate: any) => props.setSelectedDate(new Date(newDate))}
        locale='cs-CS'
        maxDate={new Date("2050-12-31")}
        minDate={new Date("2023-06-01")}
        tileClassName={'calendar-tile'}
        nextLabel={<Next />}
        prevLabel={<Previous />}
        next2Label={null}
        prev2Label={null}
        minDetail={'year'}
        showFixedNumberOfWeeks={true}
        onClickDay={closeModal}
      />
      <button id="closeButtonMobileCalendarModal" className="close-button" onClick={closeModal}>Zavřít</button>
    </div>
  )
}
export default CalendarModal;