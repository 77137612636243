import { useState, useEffect } from 'react';
import eduboSmall from 'images/edubo_small.svg';
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import SideBar from './SideBar';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './SideBar.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const AppBar = (props: any) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const menu = useSelector((state: RootState) => state.editor.menu);
  
  const navigate = useNavigate()
  const handleButtonClick = () => {
    setOpen(!open);
  };

  const navigateToViewSelection = () => {
    if (window.location.pathname === "/lesson-view") navigate(menu === 'calendar' ? '/calendar' : '/subjects');
    else navigate('/view-selection');
  };

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [location]);

  return (
    <div className={`w-full z-50 sticky top-0 ${props.className} app-bar`} style={{ backgroundColor: "#8007AA" }}>
    <div className="flex items-center justify-between p-2">
      <img 
            src={eduboSmall} 
            alt="edubo" 
            className="h-6 w-6 mr-2" 
            onClick={navigateToViewSelection}
            style={{ cursor: 'pointer' }}
          />
      <button className="open-sidebar" onClick={handleButtonClick}>
        <ArrowIcon />
      </button>
    </div>
    <SideBar currentPath={location.pathname} open={open} onClose={handleButtonClick} />
  </div>
  );
}

AppBar.propTypes = {
  className: PropTypes.string
};

export default AppBar;
