import { createSlice } from '@reduxjs/toolkit'
import LessonActivitiesData from '../dummy-data/LessonActivitiesData.json';

export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    modalActivity: null as any,
    sumOfRowsMins: 0 as number,
    notes: LessonActivitiesData.notes || "",
    goal: "" as any,
    curriculums: null as any,
    lessonName: LessonActivitiesData.name || "Nová hodina",
    lessonId: "",
    subjectId: "",
    teachersId: "",
    changedLesson: false,
    linearView: true,
    class: "",
    menu: "calendar"
  },
  reducers: {
    setModalActivity: (state, action) => {
        state.modalActivity = action.payload
    },
    setSumOfRowsMins: (state, action) => {
        state.sumOfRowsMins = action.payload
    },
    setNotes: (state, action) => {
        state.notes = action.payload
    },
    setLessonName: (state, action) => {
        state.lessonName = action.payload
    },
    setGoal: (state, action) => {
      state.goal = action.payload
    },
    setCurriculums: (state, action) => {
      state.curriculums = action.payload
    },
    updateLessonCurriculums: (state, action) => {
      state.curriculums = action.payload;
    },
    setLessonId: (state, action) => {
      state.lessonId = action.payload
    },
    setSubjectId: (state, action) => {
      state.subjectId = action.payload
    },
    setTeachersId: (state, action) => {
      state.teachersId = action.payload
    },
    setChangedLesson: (state, action) => {
      state.changedLesson = action.payload
    },
    setLinearView: (state, action) => {
      state.linearView = action.payload
    },
    setClass: (state, action) => {
      state.class = action.payload
    },
    setMenu: (state, action) => {
      state.menu = action.payload
    }
  },
})

export const {
  setModalActivity,
  setSumOfRowsMins,
  setNotes,
  setLessonName,
  setGoal,
  setCurriculums,
  setSubjectId,
  setLessonId,
  setTeachersId,
  updateLessonCurriculums,
  setChangedLesson,
  setLinearView,
  setClass,
  setMenu
} = editorSlice.actions

export default editorSlice.reducer