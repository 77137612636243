import './RegistrationWindow.scss';
import { Link, useNavigate } from "react-router-dom";
import useRegistration from './useRegistration';
import { useState } from 'react';
// SVGs
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as EyeSlashed } from "../../images/eye-slashed.svg";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { ReactComponent as EduboIcon } from "../../images/edubo.svg";

function RegistrationWindow() {
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    const { register, handleSubmit, errors, onSubmit, isSuccessfullySubmitted } = useRegistration();

    // Password toggle handler
    const togglePassword = (e: { stopPropagation: () => void; } ) => {
      e.stopPropagation();
      setPasswordShown(!passwordShown);
    };

    return (
      <section className="registration_window">
        <div className="registration_container">
          <EduboIcon className='logo'/>
          <form className="data" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full">
              <div className='desc'>E-mail:</div>
              <input placeholder="Zadejte e-mail" id="email" {...register("email")}/>
              {errors.email && <p className="absolute mt-1 text-sm text-red-600">{errors.email.message}</p>}
            </div>
            <div className="w-full">
              <div className="flex flex-col relative">
                <div className='desc'>Heslo:</div>
                <input type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte heslo" id="password" {...register('password')}/>
                {passwordShown ? <Eye className="eye shown" onClick={togglePassword} /> : <EyeSlashed className="eye" onClick={togglePassword} />}
              </div>
              {errors.password && <p className="absolute mt-1 text-sm text-red-600">{errors.password.message}</p>}
            </div>
            <div className="w-full">
              <div className='desc'>Heslo znovu:</div>
              <input type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Ověření hesla" id="confirmPassword" {...register('confirmPwd')}/>
              {errors.confirmPwd && <p className="absolute mt-1 text-sm text-red-600">{errors.confirmPwd.message}</p>}
            </div>
            <div className="w-full">
              <div className='desc'>Klíč k registraci:</div>
              <input type="text" placeholder="Klíč k registraci" id="registrationKey" {...register('key')} />
              {errors.key && <p className="absolute mt-1 text-sm text-red-600">{errors.key?.message}</p>}
            </div>
            <div className="flex items-center justify-center gdpr-wrapper-check">
              <input id="gdpr" type="checkbox" className="square-checkbox" {...register("gdpr")} />
              <label htmlFor="gdpr" className="ml-2">Souhlasím se <Link className='link-to-gdpr' to={"/gdpr"} target="_blank" rel="noopener noreferrer">zpracováním osobních údajů</Link></label>
              {errors.gdpr && <p className="absolute mt-12 text-sm text-red-600">{errors.gdpr?.message}</p>}
              {errors.apiError && <p className="absolute mt-12 text-sm text-red-600">{errors.apiError?.message}</p>}
            </div>
            <button>Registrovat se</button>
            {isSuccessfullySubmitted && (<p className="mb-3 ml-3 text-sm text-green-600 successfullySubmitted">Registrace proběhla v pořádku.</p>)}
            <div className='flex justify-center items-center'>
            <div className='back-to-login' onClick={() => navigate("/")}>
            <ArrowIcon className='back-to-login-arrow' />
              <Link to={"/"} className="back-to-login-text">Zpět na přihlášení</Link>
            </div>
            </div>
          </form>
        </div>
      </section>
    )
  }

  export default RegistrationWindow