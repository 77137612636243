import "./Snackbar.scss";
import { ReactComponent as CloseIcon } from "../../images/close.svg";

interface ErrorSnackbarProps {
  message: string;
  fade: string;
  closeSnackbar: () => void;
}

const ErrorSnackbar = ({ message, fade, closeSnackbar }: ErrorSnackbarProps) => (
  <div className={`fixed top-0 left-1/2 transform -translate-x-1/2 mt-8 max-w-md ${fade}`} onClick={closeSnackbar}>
    <div className="bg-red-100 border border-red-700 text-red-700 rounded relative text-teal-900 px-4 py-3 shadow-md">
      <div className="flex items-center">
        <p className="mr-2">{message}</p>
        <CloseIcon className='h-4 w-4 cursor-pointer' />
      </div>
    </div>
  </div>
)

export default ErrorSnackbar;