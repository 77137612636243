import './InfoWindows.scss';

const GdprWindow = () => {

  return (
    <section className='gdpr-window'>
      <h1>GDPR</h1>
      <h3>Účel Zpracování Dat:</h3>
      <p>
        EDUBO shromažďuje a zpracovává osobní údaje (e-mail a heslo) za účelem poskytování a správy přístupu k výukové 
        platformě. Při registraci žáka dochází k přiřazení k třídě, což je viditelné pouze třídnímu učiteli.
      </p>
      <h3>Zabezpečení a Přístup k Údajům:</h3>
      <p>
        Zabezpečení uživatelských údajů je pro nás prioritou. Přístup k údajům mají pouze oprávnění zaměstnanci a třídní 
        učitelé, a to pouze pro účely správy a výuky. Rodičům, kteří si založí účet, je zaručeno, že jejich přiřazení k 
        studentskému účtu zůstane neviditelné pro ostatní.
      </p>
      <h3>Práva Uživatelů:</h3>
      <p>
        Uživatelé mají právo požádat o přístup ke svým údajům, jejich opravu, výmaz nebo omezení zpracování. K tomu je možné
        kontaktovat nás na podpora@eduklub.cz.
      </p>
    </section>
  )
}

export default GdprWindow;