import axios from "utils/axios";
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AssignedCurriculum, Curriculum, ModalProps } from '../../interfaces/user-data-interface'
import './ModalLessonMenu.scss'
import { KeyboardShortcuts } from '../../utils/KeyboardShortcuts'
import { useThrowAsyncError } from '../../utils/ErrorHandling'
import { iClass } from "interfaces/classes-interface";

export const Modal = (props: ModalProps) => {
  const throwAsyncError = useThrowAsyncError();
  const [inputValue, setInputValue] = useState<string>("")
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const [curriculumJson, setCurriculumJson] = useState<Curriculum[]>([])
  const [curriculumShow, setCurriculumShow] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedGoals, setSelectedGoals] = useState<string[]>([])
  const [assignedCurriculums, setAssignedCurriculums] = useState<AssignedCurriculum[]>([])
  const [currentClasses, setCurrentClasses] = useState<string[]>([]);
  const [classesSelectValue, setClassesSelectValue] = useState<string>("");

  useEffect(() => {
    if (props.curriculumArray[0])
      obtainCurriculum()
    setInputValue(props.defaultInput)
  }, [props.curriculumArray, props.defaultInput])

  useEffect(() => {
    if (props.curriculumArray[1] !== "newSubject") {
      const selectedCurriculum = assignedCurriculums.find(curriculum => curriculum.id_of_subject === props.curriculumArray[1]);
      if(selectedCurriculum)
        setSelectedGoals(selectedCurriculum.curriculums);
    }
  }, [assignedCurriculums])

  useEffect(() => {
    if (props.showClassesList) {
      getClasses().then((classes) => {
        setCurrentClasses(classes.filter((classItem) => !props.classNames.includes(classItem)));
      });
    }
  }, []);

  const handleInputChange = (e: any) => setInputValue(e.target.value);

  // Get all classes
  const getClasses = async (): Promise<string[]> => {
    const res = await axios.get("/api/class?fields=name");
    const classes = res.data.map((classItem: iClass) => classItem.name)
    //const classes: iClass[] = res.data;
    return classes;
  }

  const obtainCurriculum = () => {
    axios.get("/api/curriculum/" + userInfo._id)
    .then((result: any) => { 
      const curriculums_list = result.data.curriculums_list
      curriculums_list.sort((a: any, b: any) => a.subject.localeCompare(b.subject, "cs"));
      setCurriculumJson(curriculums_list)
      setAssignedCurriculums(result.data.assigned_curriculums)
    })
    .catch(err => throwAsyncError(err))
  }

  const handleCurriculumShow = () => {
    obtainCurriculum()
    setCurriculumShow(!curriculumShow)
  }

  const isGoalChecked = (goal: string) => selectedGoals.includes(goal);

  const handleGoalChange = (e: any, goal: string) => {
    const newSelectedGoals = [...selectedGoals];
    if (e.target.checked) {
      newSelectedGoals.push(goal);
    } else {
      const index = newSelectedGoals.indexOf(goal);
      newSelectedGoals.splice(index, 1);
    }
    setSelectedGoals(newSelectedGoals);
  }

  const handleSelectAll = () => {
    if (curriculumJson) {
      const subjectCurriculums = curriculumJson?.find((curriculum) => curriculum.subject === selectedSubject)?.curriculums ?? [];
      if (!subjectCurriculums.every((goal) => selectedGoals.includes(goal))) {
        setSelectedGoals((prevGoals) => [...prevGoals, ...(curriculumJson.find((curriculum) => curriculum.subject === selectedSubject)?.curriculums ?? [])]);
      } else {
        setSelectedGoals((prevGoals) => {
          const subjectCurriculums = curriculumJson.find((curriculum) => curriculum.subject === selectedSubject)?.curriculums ?? [];
          return prevGoals.filter((goal) => !subjectCurriculums.includes(goal));
        });
      } 
    }
  }

  const isEverythingChecked = () => {
    const subjectCurriculums = curriculumJson?.find((curriculum) => curriculum.subject === selectedSubject)?.curriculums ?? [];
    return subjectCurriculums.every((goal) => selectedGoals.includes(goal));
  };

  const callbackRef = useCallback((inputElement: HTMLInputElement | null) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const callbackFunctionHandle = () => {
    if (props.curriculumArray[1] !== "newSubject") {
      if (classesSelectValue !== "") {
        props.callbackFunction(classesSelectValue);
      } else {
        props.callbackFunction(inputValue);
      }
    } else {
      props.callbackFunction(inputValue, selectedGoals)
    }
  }

  return !(props.inputShow && props.showConfirmForm) ? (
    <div className="modal">
      <KeyboardShortcuts context="lessoMenuModal"></KeyboardShortcuts>
      {!curriculumShow ? (props.inputShow &&!props.showConfirmForm && (
          <div className="modal-content overflow-auto">
            <div className='lesson-menu-modal'>
              <h1>{props.title}</h1>
              {
                props.showClassesList && (
                  <div className="flex flex-col">
                    <label htmlFor="lesson-menu-modal-select-class">Vyberte existující třídu:</label>
                    <select id="lesson-menu-modal-select-class" className={"mt-4 rounded-md " + (inputValue !== "" ? "disabled" : "")}
                     value={classesSelectValue}
                     onChange={
                      (e) => {
                        setClassesSelectValue(e.target.value);
                      }
                     }
                     disabled={inputValue !== ""}>
                      <option value="">--- Vyberte třídu ---</option>
                      {currentClasses.map((c, index) => (
                        <option key={"class" + index} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>

                  </div>
                )
              }
              <label>{props.label}</label>
              <input className={"modal-input " + (classesSelectValue !== "" ? "disabled" : "")} type="text"
               maxLength={63}
               value={inputValue}
               ref={callbackRef}
               onChange={handleInputChange}
               disabled={classesSelectValue !== ""}
               />
              {props.curriculumArray[0] ? (
                 <div className="tooltip-container">
                <button className='outputs' onClick={handleCurriculumShow} disabled={!curriculumJson.length}>Přiřadit výstupy k předmětu</button>
                {(!curriculumJson || curriculumJson.length === 0) && (
                    <span className="tooltip-text">
                      Nemáte nahraný žádný CSV soubor s výstupy
                    </span>
                  )}
                </div>
              ) : null}
              <div className='flex gap-3'>
                <button id="closeButtonLessoMenuModal" className="close-button" onClick={props.closeModal}>Zavřít</button>
                <button id="saveButtonLessoMenuModal" className="save-button" onClick={callbackFunctionHandle}>Uložit</button>
              </div>
            </div>
          </div>
        )) : (
          <div className="modal-content lesson-menu-modal" style={{maxWidth: "calc(100vw - 100px)", minWidth: "auto"}}>
            <div className='goals-header'>
            <h1>
              Přiřazení cílů
            </h1>
            <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
              <option value="" disabled>
                Vyber vzdělávací oblast
              </option>
              {curriculumJson?.map((csvSubject) => (
                <option key={csvSubject.subject} value={csvSubject.subject}>{csvSubject.subject}</option>
              ))}
            </select>
            </div>
            {selectedSubject && (
              <div className='flex w-full flex-col gap-2 place-self-start'>
                <div className='select-all'>
                  <label className='curriculum flex items-start select-none'>
                  <input className='mt-1' type="checkbox" checked={isEverythingChecked()} onChange={() => handleSelectAll()}/>
                      Vybrat vše
                  </label>
                </div>
                {curriculumJson?.find((curriculum) => curriculum.subject === selectedSubject)?.curriculums.map((goal) => (
                  <div key={goal}>
                    <label className='curriculum flex items-start'>
                      <input className='mt-1' type="checkbox" value={goal} checked={isGoalChecked(goal)}
                        onChange={(e) => handleGoalChange(e, goal)}/>
                      {goal}
                    </label>
                  </div>
                  ))}
              </div>
            )}
            {(props.curriculumArray[1] !== "newSubject") ? (
              <div className='flex gap-3'>
                <button id="closeButtonLessoMenuModal" className="close-button" onClick={props.closeModal}>Zavřít</button>
                <button id="saveButtonLessoMenuModal" className="save-button" onClick={() => props.callbackFunction(inputValue, selectedGoals)}>Uložit</button>
              </div>
             ) : (
              <div className='flex gap-3'>
                <button id="saveButtonLessoMenuModal" className="save-button" onClick={() => setCurriculumShow(!curriculumShow)}>Uložit</button>
            </div>
             )}
          </div>
      )}

      {props.showConfirmForm && (
        <div className="modal-content">
          <div className='lesson-menu-modal'>
            <h1>{props.title}</h1>
            <label>{props.label}</label>
            <div className='flex gap-3'>
              <button id="closeButtonLessoMenuModal" className="close-button" onClick={props.closeModal}>Zavřít</button>
              <button id="saveButtonLessoMenuModal" className='save-button' onClick={() => props.callbackFunction(true)}>{props.confirmFormButtonValue}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
