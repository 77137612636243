import { useMobileQuery } from "utils/useMediaQuery";

const TimelineStudent = ({ timeline, timelineLinesHeight }: { timeline: string[], timelineLinesHeight: number[] }) => {

  const isMobile = useMobileQuery();

  return (
    <div className={`timeline-container ${isMobile ? "timeline-student-mobile mx-4" : "timeline-student mr-12"}`}>
      {timeline && timeline.map((min, index) => {
      if (min.substring(0,1) === "a") {
        const lineIndex = parseInt(min.substring(1));

        return (
          <div className='timeline-line' style={{height: timelineLinesHeight[lineIndex]}} key={index}/>
        )
      } else {
        return (
        <li key={index}>
          {min}
        </li>
        )
      }
      })}
    </div>
    );
}

export default TimelineStudent;