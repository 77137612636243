import { setLinearView } from '../../redux/editorSlice';
import { Logout } from '../nav/logout/Logout';
import Dropdown from "../dropdown";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useRef } from 'react';
import { parse } from 'papaparse';
import axios from "utils/axios";
import { useThrowAsyncError } from '../../utils/ErrorHandling';
import { iUserData, iLessonMenuIndexes } from '../../interfaces/user-data-interface';
import { useSnackbar } from '../../contexts/SnackbarProvider';
// SVGs
import { ReactComponent as DynamicSelect } from "../../images/dynamic-select.svg";
import { ReactComponent as LinearSelect } from "../../images/linear-select.svg";
import mainLogo from '../../images/logo-main.svg';
import { Button } from 'flowbite-react';

interface LessonMenuNavProps {
  userData: iUserData;
  activeClass: string;
  activeSubject: string;
  activeTeachingUnit: string;
  getActiveItemsIndexes: () => iLessonMenuIndexes;
  reloadUserData: () => void;
}

export const LessonMenuNav = ({userData, activeClass, activeSubject, activeTeachingUnit, getActiveItemsIndexes, reloadUserData}: LessonMenuNavProps) => {
  const linearView = useSelector((state: RootState) => state.editor.linearView)
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const zipFileInputRef = useRef<HTMLInputElement>(null);
  const csvFileInputRef = useRef<HTMLInputElement>(null);
  const lessonFileInputRef = useRef<HTMLInputElement>(null);
  const throwAsyncError = useThrowAsyncError();
  const { openErrorSnackbar, openSnackbar } = useSnackbar();

  const handleCsvFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (!file)
      return;
    parse<any[]>(file, {
      header: false,
      skipEmptyLines: true,
      complete: (results: any) => completeParse(results.data)
    })
  }

  const completeParse = (results: any) => {
    const curriculumList: any[] = []
    results.forEach((row: string[]) => {
      const subjectName = row[0]
      const subjectGoal = row[1]
      const matchingObj = curriculumList.find(obj => obj.subject === subjectName)
      if (matchingObj)
        matchingObj.curriculums.push(subjectGoal)
      else
        curriculumList.push({subject: subjectName, curriculums: [subjectGoal]})
    })

    axios.post("/api/curriculum", {
      curriculum_list: curriculumList
    })
    .then(() => {
      if (csvFileInputRef.current)
      csvFileInputRef.current.value = '';
    })
    .catch(err => throwAsyncError(err))
  }

  const handleZipFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (!file || activeClass === "" || activeSubject === "" || !userData)
      return;
    const indexes = getActiveItemsIndexes()
    const id_of_class = userData.classes[indexes.activeClassIndex]._id
    const id_of_subject = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]._id
    const id_of_user = userData.id_of_user
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id_of_class', id_of_class);
    formData.append('id_of_subject', id_of_subject);
    formData.append('id_of_user', id_of_user);
    try {
      await axios.post("/api/import-teaching-unit", formData);
      await reloadUserData();
      openSnackbar("Výukový celek byl úspěšně importován");
    } catch(error: any) {
      if (error.response && error.response.status === 409) {
        openErrorSnackbar("Výukový celek s tímto jménem již existuje!");
      } else {
        openErrorSnackbar("Při importování došlo k chybě!");
      }
    }
    if (zipFileInputRef.current) zipFileInputRef.current.value = '';
  }

  const handleLessonFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (!file || activeTeachingUnit === "" || !userData)
      return;
    const indexes = getActiveItemsIndexes()
    const id_of_teaching_unit = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex].teaching_units[indexes.activeTeachingUnitIndex]._id
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id_of_teaching_unit', id_of_teaching_unit);
    try {
      await axios.post("/api/import-lesson", formData);
      await reloadUserData();
      openSnackbar("Výuková hodina byla úspěšně importována");
    } catch(error: any) {
      if (error.response && error.response.status === 409) {
        openErrorSnackbar("Výuková hodina s tímto jménem již existuje!");
      } else {
        openErrorSnackbar("Při importování došlo k chybě!");
      }
    }
    if (lessonFileInputRef.current) lessonFileInputRef.current.value = '';
  }


  return (
    <nav className="nav">
      <div className="left-menu">
      <img src={mainLogo} alt="main-logo" className="logo" onClick={() => navigate(userInfo.role === 'student' ? '/view-selection' : '/lesson-menu')}/>
      <input
        type="file"
        id="uploadZip"
        accept=".zip"
        onChange={handleZipFileUpload}
        hidden
        ref={zipFileInputRef}/>
      <input
        type="file"
        accept=".csv"
        onChange={handleCsvFileUpload}
        hidden
        ref={csvFileInputRef}/>
      <input
        type="file"
        accept=".zip"
        onChange={handleLessonFileUpload}
        hidden
        ref={lessonFileInputRef}/>
      <button hidden onClick={() => dispatch(setLinearView(!linearView))} id={"changeViewButton"}></button>
      <button hidden disabled={!activeClass || !activeSubject} onClick={() => {if (activeTeachingUnit) {if (lessonFileInputRef.current) lessonFileInputRef.current.click();}}} id={"importLessonButton"}></button>
      <Dropdown
        className='menu-select'
        label='Nahrát'
        id={"uploadDropdown"}>

        <Dropdown.Item
          className='menu-select-item'>
          <span style={{padding: "0"}} onClick={() => {if (csvFileInputRef.current) csvFileInputRef.current.click();}}>Přidat vlastní CSV výstupy</span>
        </Dropdown.Item>
        <Dropdown.Item
          className='menu-select-item'
          disabled={!activeClass || !activeSubject}>
          <span style={{padding: "0"}} onClick={() => {if (activeClass && activeSubject) {if (zipFileInputRef.current) zipFileInputRef.current.click();}}}>Nahrát výukový celek</span>
        </Dropdown.Item>
        <Dropdown.Item
          className='menu-select-item'
          disabled={!activeTeachingUnit}>
          <span style={{padding: "0"}} onClick={() => {if (activeTeachingUnit) {if (lessonFileInputRef.current) lessonFileInputRef.current.click();}}}>Nahrát novou hodinu</span>
        </Dropdown.Item>
      </Dropdown>
      <div className="tooltip-container">
        <Button color="light"
          disabled={!activeClass}
          onClick={
            () => {
              if (!userData) return;

              const currentClass = userData.classes[getActiveItemsIndexes().activeClassIndex]
              if (!currentClass) {
                alert("Nejprve vyberte třídu!");
                return;
              }

              const count = prompt('Zadejte počet klíčů, které chcete vygenerovat pro studenty.\nPro zobrazení všech klíču napište "0".', "1");
              if (count === null) return;
              const classId = currentClass._id;

              window.open(`/pdf/class/${classId}/keys?count=${count}`, '_blank');
            }
          }
          >
          Generovat klíče
        </Button>
        {!activeClass && (
          <span className="tooltip-text">
            Vyberte třídu pro generování klíčů pro studenty!
          </span>
        )}
      </div>
      <Dropdown
        className='menu-select'
        preLabel="Režim zobrazení: "
        label={linearView ? "Lineární" : "Dynamické"}
        svg={linearView ? <LinearSelect/> : <DynamicSelect/>}
        id={"viewDropdown"}>
          
        <Dropdown.Item
          className='menu-select-item'
          onClick={() => dispatch(setLinearView(true))}
          >
          <LinearSelect/>Lineární zobrazení
          </Dropdown.Item>
          <Dropdown.Item
          onClick={() => dispatch(setLinearView(false))}
          className='menu-select-item'
          >
          <DynamicSelect/>Dynamické zobrazení
        </Dropdown.Item>
      </Dropdown>
      </div>
      <div className="right-menu">
        <Logout />
      </div>
    </nav>
  );
}