import './SaveModal.scss';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import { useModal } from '../../../contexts/ModalProvider';
// Modal that asks the user, if they want to save the lesson before exiting/creating a new one

const SaveModal = (props: any) => {
  
  const { closeModal } = useModal();

  const onDoNotSaveButtonClick = () => {
    props.onSaveComplete();
    closeModal();
  };
  
  const onSaveButtonClick = async () => {
    await props.saveLesson("database", true);
    props.onSaveComplete();
    closeModal();
  };

  return (
    <div className='save-modal'>
      <KeyboardShortcuts context="saveModal"></KeyboardShortcuts>
      <h1>Uložit hodinu</h1>
      <label>Chcete uložit otevřenou hodinu?</label>
      <div className='flex gap-3'>
        <button id="closeButtonSaveModal" className="close-button" onClick={ onDoNotSaveButtonClick }>Neukládat</button>
        <button id="saveButtonSaveModal" className="save-button" onClick={ onSaveButtonClick }>Uložit</button>
      </div>
    </div>
  )
}
export default SaveModal;