import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/authSlice';

// registration logic hook
export default () => {

  type Form = {
    email: string;
    password: string;
    remember: boolean;
    apiError: string
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const formSchema = yup.object().shape({
    email: yup.string()
      .required("Toto pole je povinné")
      .email("E-mail není ve validním formátu")
      .max(320, "E-mail není ve validním formátu"),
    password: yup.string()
      .required("Toto pole je povinné")
      .max(100, "Heslo nesmí být delší než 100 znaků!"),
    remember: yup.boolean()
  })

  const { setError, register, handleSubmit, formState: { errors } } = useForm<Form>({
    resolver: yupResolver(formSchema)
  });

  const onSubmit = (data: Form) => {
    axios.post("/api/login", {
      email: data.email,
      password: data.password,
      remember: data.remember
    })
      .then(res => {
        dispatch(setUser({ userInfo: res.data.user, rememberMe: data.remember }));
        res.data.user.role === "student" || res.data.user.role === "parent" ? navigate("/view-selection") : navigate("/lesson-menu");
      }).catch(err => {
        if (!err.response.data.errorMessage) {
          setError("apiError", {
            type: "server",
            message: "Někde nastala chyba zkuste to znovu!",
          });
        }
        if (err.response.data.errorMessage?.includes("Incorrect")) {
          setError("password", {
            type: "server",
            message: "Nesprávný e-mail nebo heslo!"
          });
        } else {
          setError("apiError", {
            type: "server",
            message: "Někde nastala chyba zkuste to znovu!",
          });
        }
      })
  }

  return {
    register,
    handleSubmit,
    errors,
    onSubmit
  }
}