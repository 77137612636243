import "./ViewSelectionWindow.scss";
import { useNavigate } from "react-router-dom";

function ViewSelectionWindow() {
  const navigate = useNavigate();

  const onSelectSubjects = () => {
    navigate("/subjects");
  };

  const onSelectCalendar = () => {
    navigate("/calendar");
  };

  return (
    <section className="view_selection_window">
      <div className="view_selection_container">
        <h1>Vyberte pohled:</h1>
        <div className="view_selection">
          <button type="button" className="calendar-button" onClick={onSelectCalendar}>
            Kalendář
          </button>
          <button type="button" className="subjects-button" onClick={onSelectSubjects}>
            Předměty
          </button>
        </div>
      </div>
    </section>
  );
}

export default ViewSelectionWindow;
