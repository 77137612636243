import './NotesStudent.scss'; 

interface NotesStudentProps {
  notes: string | null;
}

const NotesStudent = ({notes}: NotesStudentProps) => {

  return (
    <div className="notesStudent p-6 border-t">
      <span className="mr-2 border-none text-black font-medium"> Poznámky:</span> <span>{notes}</span> 
    </div>
  );
};

export default NotesStudent;
