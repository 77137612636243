import AppBar from "components/mobile-utils/AppBar";
import useResetPassword from "./useResetPassword";
import './MobilePasswordResetNewPassword.scss';
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as EyeSlashed } from "../../images/eye-slashed.svg";
import { useState } from 'react';

function MobilePasswordResetNewPassword() {
  const { registerPassword, resetPassword, handleSubmitPassword, errorsPassword, isSuccessfullySubmitted } = useResetPassword();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const navigate = useNavigate();

  const togglePassword = (e: { stopPropagation: () => void; } ) => {
    e.stopPropagation();
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="w-full h-mobile-full">
      <AppBar />
      <section className="password_reset_new_password_window_mobile w-full px-10">
        <form className="data w-full" onSubmit={handleSubmitPassword(resetPassword)}>
          <div className="w-full relative">
            <div className='desc'>Zadejte nové heslo:</div>
            <input {...registerPassword("newPassword")} className='w-full' type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte nové heslo" />
            {errorsPassword.newPassword && <p className="message absolute text-xs text-red-600">{errorsPassword.newPassword.message}</p>}
          </div>
          <div className="w-full relative">
            <div className="flex flex-col relative">
              <div className='desc'>Potvrďte nové heslo:</div>
              <input {...registerPassword("confirmPassword")} className='w-full' type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Znovu zadejte nové heslo" />
              {passwordShown ? <Eye className="eye shown" onClick={togglePassword} /> : <EyeSlashed className="eye" onClick={togglePassword} />}
            </div>
            {errorsPassword.confirmPassword && <p className="message absolute text-xs text-red-600">{errorsPassword.confirmPassword.message}</p>}
            {errorsPassword.apiError && <p className={`absolute text-xs text-red-600 ${errorsPassword.confirmPassword ? "message-lower" : "message"}`}>{errorsPassword.apiError?.message}</p>}
            {isSuccessfullySubmitted && (<p className="message text-xs absolute text-green-600">Heslo bylo změněno!</p>)}
          </div>
          <button type="submit" className="select-none mt-12 submit">Potvrdit</button>
        </form>
        <div className='flex justify-center items-center'>
          <button className='back-to-login' onClick={() => navigate("/")}>
            <ArrowIcon className='back-to-login-arrow' />
            <span className="back-to-login-text">Zpět na přihlášení</span>
          </button>
        </div>
      </section>
    </div>
  )
}

export default MobilePasswordResetNewPassword;