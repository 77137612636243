import { Link } from 'react-router-dom';
import './InfoFooter.scss';

type InfoFooterProps = {
  page: "about" | "notes" | "contacts" | "gdpr" | "login";
}

const InfoFooter = ({page}: InfoFooterProps) => {

  return (
    <footer className='info-footer'>
      {page !== "login" && <Link to={"/"}><button type="button" className='back-to-login'>Zpět na přihlášení</button></Link>}
      <div className='refs'>
        {page !== "about" && <Link to={"/about"}>O nás</Link>}
        {page !== "notes" && <Link to={"/notes"}>Archiv aktualit</Link>}
        {page !== "contacts" && <Link to={"/contacts"}>Kontakty</Link>}
        {page !== "gdpr" && <Link to={"/gdpr"}>GDPR</Link>}
      </div>
    </footer>
  )
}

export default InfoFooter;