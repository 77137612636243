import { PropsWithChildren } from 'react'
import mainLogo from '../../../images/logo-main.svg';
import './StudentLessonMenuNav.scss'
import Dropdown from '../../dropdown';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Logout } from 'components/nav/logout/Logout';
import { useNavigate } from 'react-router-dom';
import { isMacSafariFirefox } from 'utils/browserUtils';
import { Button } from 'flowbite-react';
import { useModal } from '../../../contexts/ModalProvider';
import ParentalAccess from './parental-access/ParentalAccess';

interface StudentLessonMenuNavProps extends PropsWithChildren<any> {
  lessonData: {_id: string;subject: string;teachingUnit: string;teacher: string;teachers_data_id: string;times: string[];} | undefined,
}

const StudentLessonMenuNav = ({lessonData}: StudentLessonMenuNavProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const navigate = useNavigate()
  const { showModal } = useModal();

  const openPDFExport = () => {
    if (!lessonData) return
    const url = `/pdf/student/${lessonData.teachers_data_id}/${lessonData._id}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          throw new Error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  }

  return (
    <nav className="student-nav">
      <div className="student-left-menu">
        <img src={mainLogo} alt="main-logo" className="student-logo" id={"logoButton"} onClick={() => navigate(userInfo.role === 'student' ? '/view-selection' : '/lesson-menu')}/>
        <button hidden onClick={openPDFExport} id={"exportButton"}></button>
        <Dropdown
          label = "Soubor"
          disabled = {false}
          upPlacement = {false}
          noArrow = {false}
          defaultClasses = {true}
          menuMiddle = {true}
        >
          <Dropdown.Item
            disabled={!lessonData}
            onClick={() => openPDFExport()}
          >
            Exportovat do PDF
          </Dropdown.Item>
        </Dropdown>
        <Button
          onClick={() => navigate(location.pathname === '/calendar' ? '/subjects' : '/calendar')}
          color="light"
          id={"changeViewButton"}>
          Přepnout pohled { location.pathname === '/calendar' ? '(Předměty)' : '(Kalendář)' }
        </Button>
        {userInfo.role === 'student' && <Button color="light" onClick={() => showModal(<ParentalAccess/>)}>Rodičovský přístup</Button>}
      </div>
      <div className="student-right-menu">
        <Logout/>
      </div>
    </nav>
  )
}
export default StudentLessonMenuNav;