import React, { useState } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import "./LessonLengthModal.scss";
import { useModal } from '../../../contexts/ModalProvider';

interface LessonLengthModalProps {
  duration: string;
  setDuration: (duration: string) => void;
  setLengthInMins: (lengthInMins: string) => void;
}

const LessonLengthModal = ({
  duration,
  setDuration,
  setLengthInMins,
}: LessonLengthModalProps) => {

  const { closeModal } = useModal();
  const [inputDuration, setInputDuration] = useState(duration);
  const sumOfRowsMins = useSelector(
    (state: RootState) => state.editor.sumOfRowsMins,
  )

  const handleCustomTimeBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    let value = parseFloat(e.target.value);
    if (value % 5 === 0) return;
    value = Math.round(value / 10) * 10;
    e.target.value = value.toString();
    setInputDuration(e.target.value);
  }

  const handleCustomTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredDuration = Number(e.target.value);
    if (
      enteredDuration > -1 &&
      Math.floor(enteredDuration) === enteredDuration &&
      enteredDuration !== Infinity
    ) {
      setInputDuration(e.target.value);
    }
  }

  const handleSaveLessonLengthClick = () => {
    // validate input
    if (inputDuration === '') {
      alert('Zadejte délku hodiny!');
      return;
    }

    // if input is less than 5, set it to 5
    const sanitizedDuration = Number(inputDuration) < 5 ? 5 : Number(inputDuration);

    if (sanitizedDuration > 720) {
      setInputDuration('720');
      alert('Délka hodiny nesmí být delší než 12 hodin!');
      return;
    }

    if (sumOfRowsMins > sanitizedDuration) {
      alert('Délka aktivit nesmí být delší než délka hodiny!');
      return;
    }

    // round to nearest 5 and convert to string because that's what the Editor expects
    const roundedDuration = String(Math.round(sanitizedDuration / 5) * 5);

    setDuration(String(roundedDuration));
    setLengthInMins(String(roundedDuration));

    closeModal();
  }

  return (
    <div className="length-modal">
      <KeyboardShortcuts context="lessonLengthModal"></KeyboardShortcuts>
      <h1>Vlastní délka hodiny</h1>

      <input
        id="duration"
        type="number"
        step="5"
        min="5"
        className="form-input mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 delkaDropDown"
        value={inputDuration}
        onBlur={handleCustomTimeBlur}
        onChange={handleCustomTimeChange}
        onWheel={(e) => {
          // disable focus on scroll to prevent value change
          (e.target as HTMLInputElement).blur();
        }}
      />
      <div className='flex gap-3'>
        <button id="closeButtonLessonLengthModal" type="button" className="close-button" onClick={closeModal}>Zrušit</button>
        <button id="saveButtonLessonLengthModal" type="button" className="save-button" onClick={handleSaveLessonLengthClick}>Uložit</button>
      </div>
    </div>
  );
}
export default LessonLengthModal;