import React from 'react'
import ActivityType from './ActivityType';
import { Droppable, DroppableProvided } from "react-beautiful-dnd";

// Returns all ActivityType elements
const ActivityTypes = (props: any) => {
  return (
    <Droppable droppableId={ 'activity-types' }>
      {(provided: DroppableProvided) => (
        <ul className="activity-types-column" { ...provided.droppableProps } ref={ provided.innerRef }>
          {
            props.activityTypes.map((activityType: any, index: number) => {
                return <ActivityType key={ activityType._id } _id={ activityType._id } name={ activityType.name } color={ activityType.color } onClick={ props.onClick } index={ index } />
            })
          }
          { provided.placeholder } {/*warning message fix*/}
        </ul>
      )}
    </Droppable>
  )
}
export default ActivityTypes;
