import { useModal } from 'contexts/ModalProvider';
import './UniversalModal.scss'


// Universal modal template
const UniversalModal = (props: any) => {
    const { closeModal } = useModal();

    const handleOutsideContentClick = () => {
        // If isEdit modal check focus
        // Check if input is focused or in DraftEditor
        // If so, don't close modal
        if (props.isEdit) {
            const activeElement = document.activeElement;
            if (activeElement && activeElement.tagName === 'INPUT') return;
            if (activeElement && activeElement.tagName === 'TEXTAREA') return;
            if (activeElement && activeElement.className.includes('DraftEditor')) return;
            const saveButtonActivityModal = document.querySelector<HTMLButtonElement>("#saveButtonActivityModal");
            if (saveButtonActivityModal) saveButtonActivityModal.click();
        } else {
          closeModal();
        }
    };

    return (
      <div className="modal" onClick={ handleOutsideContentClick }>
        <div className="modal-content" onClick={ e => e.stopPropagation() }>
          { props.children }
        </div>
      </div>
    )
}
export default UniversalModal;
