import ContextMenuBase from 'utils/ContextMenuBase';

interface LessonContextMenuProps {
  x: number;
  y: number;
  handleCopy: () => void;
  handleCut: () => void;
  closeMenu: () => void;
}

const LessonContextMenu = ({ x, y, handleCopy, handleCut, closeMenu }: LessonContextMenuProps) => {
  return (
    <ContextMenuBase x={x} y={y} closeMenu={closeMenu}>
      <ul className="space-y-1 text-sm">
        <li
          className="cursor-pointer hover:bg-[#8007aa1a] px-2 py-1 rounded"
          onClick={handleCopy}
        >
          Kopírovat
        </li>
        <li
          className="cursor-pointer hover:bg-[#8007aa1a] px-2 py-1 rounded"
          onClick={handleCut}
        >
          Vyjmout
        </li>
      </ul>
    </ContextMenuBase>
  );
};

export default LessonContextMenu;
