import { CSSProperties } from "react";
import { MenuTypes } from '../../../pages/StudentLessonMenu'
import './StudentSelectionMenu.scss'
import { Tooltip } from 'flowbite-react';
// SVGs
import { ReactComponent as Pending } from "../../../images/student-lesson-menu-icons/pending.svg";
import { ReactComponent as Ongoing } from "../../../images/student-lesson-menu-icons/ongoing.svg";
import { ReactComponent as Done } from "../../../images/student-lesson-menu-icons/done.svg";

const StudentSelectionMenu = (props: any) => {
  // Handles the click event for a given value
  const handleClick = (value: string) => {
    if (props.subjectSelect) {
      if (value === '') return props.setList(null)
      props.obtainLessons(props.activeSubject, value)
    }
    props.setActiveList(value);
    select(props.list.indexOf(value));
    if (props.type === MenuTypes.LESSON) {
      if (props.mobile) props.handleOpenButton(value)
    } 
  };

  // Returns the CSS properties for styling an item based on its index
  const getItemStyle = (index: number): CSSProperties => {
    const selectedItem = props.selectionList[index] === "selected" ? "rgba(210, 174, 223, 1)" : "rgba(128, 7, 170, 0.1)";
    const borderColor =
      props.selectionList[index] === "selected"
        ? "rgb(185, 124, 208)"
        : "rgba(210, 174, 223, 1)";

    return {
      userSelect: "none",
      width: "100%",
      padding: 4,
      margin: "0 0 4px 0",
      border: `solid 1px ${borderColor}`,
      borderRadius: "4px",
      background: selectedItem,
      position: "relative",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer"
    };
  };

  // Updates the selection state based on the index
  const select = (index: number) => {
    const newSelection = new Array(props.list.length);
    newSelection.fill("unselected");
    newSelection[index] = "selected";
    props.setSelectionList(newSelection);
  };

  return (
    <div className="student-selection-menu">
      {props.list.map((value: string, index: number) => (
        <div className="student-lesson" key={index}>
          <div
            onClick={() => handleClick(value)}
            style={getItemStyle(index)}
          >
            {value}
          </div>
          {props.type === MenuTypes.LESSON
            ? <Tooltip
              className="status-tooltip"
              content={props.lessonProgress[index] === "pending"
                ? "Neproběhlá hodina"
                : props.lessonProgress[index] === "ongoing"
                  ? "Aktivní hodina"
                  : props.lessonProgress[index] === "done"
                    ? "Dokončená hodina"
                    : null}
              placement='right'
              style='dark'
              trigger='hover'
            >
              {props.lessonProgress[index] === "pending"
                ? <Pending className="student-status-icon pending" />
                : props.lessonProgress[index] === "ongoing"
                  ? <Ongoing className="student-status-icon ongoing" />
                  : props.lessonProgress[index] === "done"
                    ? <Done className="student-status-icon done" />
                    : null}
            </Tooltip>
            : null
          }
        </div>
      ))}
    </div>
  );
};

export default StudentSelectionMenu;