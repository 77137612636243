import './LessonRow.scss';
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggingStyle, Droppable, DroppableProvided, NotDraggingStyle } from "react-beautiful-dnd";
import { setModalActivity } from '../../../../redux/editorSlice';
import { useDispatch } from 'react-redux';
import { iActivity } from '../../../../interfaces/activity-interface';
import { ReactComponent as SettingIcon } from '../../../../images/setting.svg';
import { ReactComponent as ClassIcon } from '../../../../images/activity-icons/class.svg';
import { ReactComponent as DoubleIcon } from '../../../../images/activity-icons/double.svg';
import { ReactComponent as GroupIcon } from '../../../../images/activity-icons/group.svg';
import { ReactComponent as SingleIcon } from '../../../../images/activity-icons/single.svg';
import { ReactComponent as SpeachIcon } from "../../../../images/activity-icons/speach.svg";
import { ReactComponent as DoubleSpeachIcon } from "../../../../images/activity-icons/double-speach.svg";
import { ReactComponent as ReadIcon } from "../../../../images/activity-icons/read.svg";
import { ReactComponent as WriteIcon } from "../../../../images/activity-icons/write.svg";
import { ReactComponent as LabIcon } from "../../../../images/activity-icons/lab.svg";
import { ReactComponent as ListenIcon } from "../../../../images/activity-icons/listen.svg";
import { FunctionComponent, SVGProps } from 'react';

const ICONS: Record<string, FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>> = {
  "class": ClassIcon,
  "double": DoubleIcon,
  "group": GroupIcon,
  "single": SingleIcon,
  "speach": SpeachIcon, 
  "double-speach": DoubleSpeachIcon,
  "read": ReadIcon,
  "write": WriteIcon,
  "lab": LabIcon,
  "listen": ListenIcon,
};

const ICON_TITLES: Record<string, string> = {
  "class": "Celá třída",
  "double": "Ve dvojicích",
  "group": "Ve skupinách",
  "single": "Samostatně",
  "speach": "Mluvený projev", 
  "double-speach": "Interakce",
  "read": "Čtení",
  "write": "Psaní",
  "lab": "Experiment",
  "listen": "Poslech",
};

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined, size: number, color: string, beforeAfter: boolean) => {
  function hex_to_RGB(hex: any) {
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    return {
      r: parseInt(m[1], 16) + 10,
      g: parseInt(m[2], 16) + 10,
      b: parseInt(m[3], 16) - 10
    };
  }
  const rgb = hex_to_RGB(color);
  return {
    background: isDragging ? `rgba(` + rgb.r + `, ` + rgb.g + `, ` + rgb.b + `)` : color,
    height: beforeAfter ? "100%" : `calc(var(--editor-five-min-height)/5*` + size + `)`,
    ...draggableStyle
  }
}

const organizeIcons = ["class", "double", "group", "single"]

interface LessonRowProps {
  _id: string,
  // activities array of iActivity objects
  activities: iActivity[],
  beforeAfter?: boolean
}

const LessonRow = ({ _id, activities, beforeAfter = false }: LessonRowProps) => {
  const dispatch = useDispatch()

  return (
    <Droppable droppableId={_id} direction="horizontal">
      {(provided: DroppableProvided) => (
        <ul className={beforeAfter ? "lesson-before-after" : "lesson-row"} {...provided.droppableProps} ref={provided.innerRef}>
          {activities.map((activity: any, index: number) => {
            return (
              <Draggable
                key={activity._id}
                draggableId={activity._id}
                index={index}
              >

                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (

                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="activity-container"
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, activity.size, activity.color, beforeAfter)}
                  >
                    <div className={activity.size > 1 || beforeAfter ? 'activity-content' : 'activity-content-small'}>
                      <div className='flex'>
                      {organizeIcons.includes(activity.icons?.filter((icon: string) => icon !== '')[1]) ?
                          activity.icons?.reverse().filter((icon: string) => icon !== '').map((path: string, index: number) => {
                            const IconComponent = ICONS[path];
                            return IconComponent ? <IconComponent title={ICON_TITLES[path]} key={index} className='icon' /> : null;
                          }) : 
                          activity.icons?.filter((icon: string) => icon !== '').map((path: string, index: number) => {
                            const IconComponent = ICONS[path];
                            return IconComponent ? <IconComponent title={ICON_TITLES[path]} key={index} className='icon' /> : null;
                          })
                        }
                      </div>
                      <div className="text" style={{
                        paddingLeft: activity.icons?.length > 0 ? '10px' : '0px'
                      }}>
                        {activity.name}
                      </div>
                      <div className="edit-button-container">
                        <button onClick={() => dispatch(setModalActivity(activity))} className={`edit-button ${activity.size === 1 && !beforeAfter ? 'small-edit-button' : ''}`}>
                        <SettingIcon className='icon' title="edit" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              </Draggable>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  )
}

export default LessonRow