import './ActivityType.scss';
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as EvokaceIcon } from "../../images/icons/Evokace.svg";
import { ReactComponent as HodnoceniIcon } from "../../images/icons/Hodnocení.svg";
import { ReactComponent as ObjevovaniIcon } from "../../images/icons/Objevování.svg";
import { ReactComponent as ProcvicovaniIcon } from "../../images/icons/Procvičování.svg";
import { ReactComponent as VykladIcon } from "../../images/icons/Výklad.svg";
import { ReactComponent as ZdrojeIcon } from "../../images/icons/Zdroje.svg";
import { ReactComponent as ShrnutiIcon } from "../../images/icons/Shrnutí.svg";
import { ReactComponent as ZpetneVazbyIcon } from "../../images/icons/Zpětné vazby.svg";

const ICONS: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  'Evokace': EvokaceIcon,
  'Hodnocení': HodnoceniIcon, 
  'Objevování': ObjevovaniIcon, 
  'Procvičování': ProcvicovaniIcon,
  'Shrnutí': ShrnutiIcon,
  'Výklad': VykladIcon,
  'Zdroje': ZdrojeIcon,
  'Zpětné vazby': ZpetneVazbyIcon
}

// Returns activity type element
const ActivityType = ({ _id, name, color, onClick, index }: { _id: string, name: string, color: string, onClick: any, index: number }) => {
  // Sets the style of the draggable items
/*   const getItemStyle = ( isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined ) => ({
    ...draggableStyle,
    // transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)'
  }) */
  const Icon = ICONS[name];
  
  return (
    <Draggable draggableId={ _id } index={ index }>
      {( provided: DraggableProvided ) => (
        <div ref={ provided.innerRef }
          { ...provided.draggableProps }
          { ...provided.dragHandleProps }
          style={ provided.draggableProps.style }>
          <div className='activity-type' style={{ "backgroundColor": color }} onClick={ () => onClick(_id) }>
            {Icon ? <Icon className='scale-100' /> : null}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default ActivityType;