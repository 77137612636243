import { useState, createContext, useContext, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import UniversalModal from '../components/universal-modal/UniversalModal';

interface ModalContextType {
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
  modalOpen: boolean;
}
export interface ModalProps {
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const modalActivity = useSelector((state: RootState) => state.editor.modalActivity);

  useEffect(() => {
    if (!modalActivity) setModalOpen(false);
  }, [modalActivity])

  const showModal = (content: ReactNode) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const contextValue: ModalContextType = {
    showModal,
    closeModal,
    modalOpen,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modalOpen && modalContent && <UniversalModal>{modalContent}</UniversalModal>}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const currentContext = useContext(ModalContext);

  if (!currentContext) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return currentContext;
};
