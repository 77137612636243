import Carousel from '../components/login/Carousel';
import './Login.scss';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobilePasswordResetEmail from 'components/passwordReset/MobilePasswordResetEmail';
import PasswordResetEmailWindow from '../components/passwordReset/PasswordResetEmailWindow';
import { useWindowSize } from 'utils/useWindowSize';

function PasswordResetEmail() {
  const windowSize = useWindowSize();

  return (
    <section className="passwordReset">
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <PasswordResetEmailWindow />
          </>
        }
        mobile={
          <MobilePasswordResetEmail />
        }
      />
    </section>
  );
}

export default PasswordResetEmail;
