import './PasswordReset.scss';
import { Link, useNavigate } from "react-router-dom";
import useResetPassword from './useResetPassword';
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { ReactComponent as EduboIcon } from "../../images/edubo.svg";
import InfoFooter from 'components/info-pages/InfoFooter';

function PasswordResetEmailWindow() {
  const navigate = useNavigate()
  const { registerEmail, requestToken, handleSumbitEmail, errorsEmail, isSuccessfullySubmitted } = useResetPassword();

  return (
    <section className="password_reset_window">
      <div className='password_reset_container'>
        <EduboIcon className='logo'/>
        <form className="data" onSubmit={handleSumbitEmail(requestToken)}>
          <div className="w-full">
            <div className='desc'>E-MAIL:</div>
            <input {...registerEmail("email")} maxLength={320} placeholder="Zadejte e-mailovou adresu" />
            {errorsEmail.email && <p className="absolute mt-1 text-sm text-red-600">{errorsEmail.email.message}</p>}
            {errorsEmail.apiError && <p className={`absolute text-sm text-red-600 ${errorsEmail.email ? "mt-5" : "mt-1"}`}>{errorsEmail.apiError?.message}</p>}
            {isSuccessfullySubmitted && (<p className="mt-1 text-sm absolute text-green-600">E-mail byl úspěšně odeslán!</p>)}
          </div>
          <button type="submit" className="select-none">Odeslat</button>
        </form>
        <div className='flex justify-center items-center'>
          <div className='back-to-login' onClick={() => navigate("/")}>
            <ArrowIcon className='back-to-login-arrow' />
            <Link to={"/"} className="back-to-login-text">Zpět na přihlášení</Link>
          </div>
        </div>
      </div>
      <InfoFooter page={"login"}/>
    </section>
  )
}

export default PasswordResetEmailWindow;