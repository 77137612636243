import { useEffect, useState } from 'react';
import './Nav.scss';
import mainLogo from '../../images/logo-main.svg';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
//import UploadModal from './nav-modals/UploadModal';
import DownloadModal from './nav-modals/DownloadModal';
import SaveModal from './nav-modals/SaveModal';
import LessonLengthModal from './nav-modals/LessonLengthModal';
import ConfirmModal from './nav-modals/ConfirmModal';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { setNotes, setLessonName, setCurriculums, setGoal } from '../../redux/editorSlice';
import { Button } from 'flowbite-react';
import Dropdown from "../dropdown";
import { useModal } from '../../contexts/ModalProvider';
import { isMacSafariFirefox } from 'utils/browserUtils';
import axios from "utils/axios";
import { removeUser } from '../../redux/authSlice';

const Nav = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const lessonActivities = [...props.lessonActivities];
  // Delka hodiny dropdown
  const [duration, setDuration] = useState(props.lessonData.length_in_mins);
  // Selectory Reduxu
  const storeSumOfRowsMins = useSelector((state: RootState) => state.editor.sumOfRowsMins)
  const lessonName = useSelector((state: RootState) => state.editor.lessonName)
  const notes = useSelector((state: RootState) => state.editor.notes)
  const goal = useSelector((state: RootState) => state.editor.goal)
  const curriculums = useSelector((state: RootState) => state.editor.curriculums)

  const dataId = useSelector((state: RootState) => state.auth.userInfo.data_id);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const { showModal } = useModal();

  useEffect(() => {
    setDuration(props.lessonData.length_in_mins)
  }, [props.lessonData.length_in_mins])

  const clearLessonCanvas = () => {
    showModal(
      <ConfirmModal
        title={"Vymazat plátno"}
        message={"Opravdu chcete vymazat plátno?"}
        onConfirm={newLesson}
      />
    )
  };

  // Creates an empty lesson in the editor
  const newLesson = () => {
    const newLessonActivities = lessonActivities
    newLessonActivities.splice(
      1,
      newLessonActivities.length,
      {
        _id: '1',
        activities: [],
      },
      {
        _id: '2',
        activities: [],
      },
      {
        _id: uuidv4(),
        activities: [],
      },
    )
    props.setActivities([...newLessonActivities])
    dispatch(setNotes(''))
    dispatch(setLessonName('Nová hodina'))
    dispatch(setCurriculums([]))
    dispatch(setGoal(''))
    props.setLengthInMins('45')
    setDuration('45')
  }

  const handleLessonLengthClick = (value: string) => {
    if (value === 'Vlastní délka hodiny') {
     
      showModal(
        <LessonLengthModal
          duration={duration}
          setDuration={setDuration}
          setLengthInMins={props.setLengthInMins}
        />
      )
      
    } else {
      if (storeSumOfRowsMins > Number(value)) {
        alert('Délka aktivit nesmí být delší než délka hodiny!')
      } else {
        
        setDuration(String(value))
        props.setLengthInMins(String(value))
      }
    }
  }

  const handleSaveComplete = () => {
    navigate('/lesson-menu')
  }

  const isLessonChanged = (onCheck?: () => void) => {
    if (!onCheck) onCheck = handleSaveComplete
    const currentActivities = JSON.parse(JSON.stringify(lessonActivities)).slice(1);
    const initActivities = JSON.parse(JSON.stringify(props.initActivities)).slice(1);
    const changed = JSON.stringify(removeIds(initActivities)) !== JSON.stringify(removeIds(currentActivities)) ||
      props.initMetaData[0] !== lessonName ||
      props.initMetaData[1] !== notes ||
      props.initMetaData[2] !== goal ||
      props.initMetaData[3].slice().sort().join(',') !== curriculums.slice().sort().join(',') ||
      String(props.initMetaData[4]) !== String(duration);

    if (changed) {
      showModal(
        <SaveModal
          newLesson={newLesson}
          saveLesson={props.handleSaveLesson}
          onSaveComplete={onCheck}
        />
      )
    } else {
      onCheck();
    }
  }

/*   const uploadLessonClick = () => {
    showModal(
      <UploadModal
          lessonActivities={props.lessonActivities}
          setActivities={props.setActivities}
          setLengthInMins={props.setLengthInMins}
          setDuration={setDuration}
      />
    )
  } */

  const downloadLessonClick = () => {
    showModal(
      <DownloadModal saveLesson={props.handleSaveLesson} />
    )
  }

  // Function that remove all id key:value pairs from object
  const removeIds = (array: any[]) => {
    if (!array) return [];

    return array.map((obj) => {
      for (const key in obj) {
        if (key === "_id") {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          obj[key] = removeIds(obj[key]);
        }
      }
      return obj;
    });
  };

  function openPDFExport(path: string) {
    const url = `/pdf/${path}/${dataId}/${props.lessonId}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          throw new Error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  }

  const handleLogout = async () => {
    dispatch(removeUser());
    await axios.get('/api/logout')
    navigate("/");
}

  return (
    <>
      <nav className="nav">
        <img src={mainLogo} alt="main-logo" id={"logoButton"} className="logo" onClick={() => userInfo.role === "student" ? navigate('/view-selection') : isLessonChanged()}/>
        <button hidden onClick={() => props.handleSaveLesson("database")} id={"saveButton"}></button>
        <button hidden onClick={() => {props.handleSaveLessonWithOptions({location: "database",redirect: false,callback: () => openPDFExport("student")});}} id={"pdfExportButton"}></button>
        <ul className="flex flex-row gap-3 items-center">
          <Dropdown label="Soubor" id={"fileDropdown"}>
            <Dropdown.Item
            onClick={() => isLessonChanged()}
            >
              Nová výuková hodina
            </Dropdown.Item>
            <Dropdown.Item
            onClick={clearLessonCanvas}
            >
              Nové plátno
            </Dropdown.Item>
            {/*
            <Dropdown.Item
            onClick={uploadLessonClick}
            >
              Nahrát výukovou hodinu
            </Dropdown.Item>
            */}
            <Dropdown.Item
            onClick={() => props.handleSaveLesson("database")}
            >
              Uložit výukovou hodinu
            </Dropdown.Item>
            <Dropdown.Item
            onClick={downloadLessonClick}
            >
              Stáhnout výukovou hodinu na disk
            </Dropdown.Item>
            <Dropdown.Item 
            onClick={() => isLessonChanged()}
            >
              Zpět do menu
            </Dropdown.Item>
          </Dropdown>

          <Dropdown label="PDF export" id={"pdfExportDropdown"}>

              <Dropdown.Item
              onClick={() => {
                // Save lesson to database, do not redirect
                props.handleSaveLessonWithOptions({
                  location: "database",
                  redirect: false,
                  callback: () => openPDFExport("teacher")
                });
                }}>
                Pro učitele
              </Dropdown.Item>

              <Dropdown.Item
              onClick={() => {
                // Save lesson to database, do not redirect
                props.handleSaveLessonWithOptions({
                  location: "database",
                  redirect: false,
                  callback: () => openPDFExport("student")
                });
                }}>
                Pro žáka
              </Dropdown.Item>

              <Dropdown.Item
              onClick={() => {
                // Save lesson to database, do not redirect
                props.handleSaveLessonWithOptions({
                  location: "database",
                  redirect: false,
                  callback: () => openPDFExport("after-lesson")
                });
                }}>
                Pro žáka po hodině
              </Dropdown.Item>
              
            </Dropdown>

          <Dropdown label={"Délka hodiny: " + duration} id={"lessonLengthDropdown"}>
            <Dropdown.Item
            onClick={() => handleLessonLengthClick('45')}
            >
              45
            </Dropdown.Item>
            <Dropdown.Item
            onClick={() => handleLessonLengthClick('60')}
            >
              60
            </Dropdown.Item>
            <Dropdown.Item
            onClick={() => handleLessonLengthClick('90')}
            >
              90
            </Dropdown.Item>
            <Dropdown.Item
            onClick={() => handleLessonLengthClick('Vlastní délka hodiny')}
            > 
              Vlastní
            </Dropdown.Item>
          </Dropdown>
        </ul>
      </nav>
      <Button 
        onClick={() => isLessonChanged(handleLogout)}
        color="light">
          Odhlásit se
      </Button>
    </>
  )
}

export default Nav
