import { useLessonContext } from '../LessonContext';

const Notes = () => {
  const { notes } = useLessonContext();

  return (
    <div className='overflow-y-auto max-h-[75vh]'>
      {notes && notes !== "" ? notes : "Není zde žádná poznámka."}
    </div>
  );
};

export default Notes;