export type iUserData = {
    _id: string;
    ts_created: string;
    ts_updated: string;
    id_of_user: string;
    author: string;
    classes: iClass[];
} | undefined;

export interface iClass {
    _id:string;
    name:string;
    subjects: iSubject[];
}

export interface iDaysOfUsage {
    ts_from: any;
    ts_to: any;
}

export interface iSubject {
    _id: string;
    name: string;
    teaching_units: iTeachingUnit[];
}

export interface iTeachingUnit {
    _id: string;
    name: string;
    notes: string;
    lessons: iMenuLesson[]
}

export interface iMenuLesson {
    _id: string;
    name: string;
    days_of_usage: iDaysOfUsage[];
    published: boolean;
}

export interface curriculumArray {
    0: boolean
    1: string | undefined
}
  
export interface ModalProps {
    title: string,
    label: string,
    inputShow: boolean,
    showConfirmForm: boolean,
    confirmFormButtonValue: string | null,
    closeModal: any,
    callbackFunction: any,
    curriculumArray: curriculumArray,
    defaultInput: string,
    classNames: string[]
    showClassesList?: boolean,
}
  
export interface Curriculum {
    subject: string,
    curriculums: string[]
}
  
export interface AssignedCurriculum {
    id_of_subject: string
    curriculums: string[]
}

export interface iLessonMenuIndexes {
    activeClassIndex: number,
    activeSubjectIndex: number,
    activeTeachingUnitIndex: number,
    activeLessonIndex: number
}

export interface UserInfo {
    _id?: string;
    data_id?: string;
    email?: string;
    ts_created?: string;
    role?: string;
    class_id?: string;
}

export const blankUserInfo: UserInfo = {
    _id: undefined,
    data_id: undefined,
    email: undefined,
    class_id: undefined
}