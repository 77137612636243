import "../../editor/lesson-window/lesson/LessonRow.scss";
import { iActivity } from "../../../interfaces/activity-interface";
import { useEffect, useRef, useState } from "react";
import { useMobileQuery } from "utils/useMediaQuery";
// SVGs
import { ReactComponent as Trash } from "../../../images/trash.svg";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as EyeSlashed } from "../../../images/eye-slashed.svg";
import { ReactComponent as EyeForward } from "../../../images/eye-forward.svg";
import { ReactComponent as ArrowIcon } from '../../../images/arrow.svg';
import { ReactComponent as ClassIcon } from "../../../images/activity-icons/class.svg";
import { ReactComponent as GroupIcon } from "../../../images/activity-icons/group.svg";
import { ReactComponent as DoubleIcon } from "../../../images/activity-icons/double.svg";
import { ReactComponent as SingleIcon } from "../../../images/activity-icons/single.svg";
import { ReactComponent as SpeachIcon } from "../../../images/activity-icons/speach.svg";
import { ReactComponent as DoubleSpeachIcon } from "../../../images/activity-icons/double-speach.svg";
import { ReactComponent as ReadIcon } from "../../../images/activity-icons/read.svg";
import { ReactComponent as WriteIcon } from "../../../images/activity-icons/write.svg";
import { ReactComponent as LabIcon } from "../../../images/activity-icons/lab.svg";
import { ReactComponent as ListenIcon } from "../../../images/activity-icons/listen.svg";
import { ReactComponent as EvokaceIcon } from "../../../images/icons/Evokace.svg";
import { ReactComponent as HodnoceniIcon } from "../../../images/icons/Hodnocení.svg";
import { ReactComponent as ObjevovaniIcon } from "../../../images/icons/Objevování.svg";
import { ReactComponent as ProcvicovaniIcon } from "../../../images/icons/Procvičování.svg";
import { ReactComponent as VykladIcon } from "../../../images/icons/Výklad.svg";
import { ReactComponent as ZdrojeIcon } from "../../../images/icons/Zdroje.svg";
import { ReactComponent as ShrnutiIcon } from "../../../images/icons/Shrnutí.svg";
import { ReactComponent as ZpetneVazbyIcon } from "../../../images/icons/Zpětné vazby.svg";

interface ActivityProps {
  activity: iActivity,
  beforeAfter?: boolean,
  onActivityClick: () => void, 
  isOpen: boolean,
  everOpened?: boolean;
  materialVisibility: string;
}

const iconMapping = {
  "trash": Trash,
  "eye": Eye,
  "eye-slashed": EyeSlashed,
  "eye-forward": EyeForward,
  "arrow": ArrowIcon,
  "class": ClassIcon,
  "group": GroupIcon,
  "double": DoubleIcon,
  "single": SingleIcon,
  "speach": SpeachIcon,
  "double-speach": DoubleSpeachIcon,
  "read": ReadIcon,
  "write": WriteIcon,
  "lab": LabIcon,
  "listen": ListenIcon,
  'Evokace': EvokaceIcon,
  'Hodnocení': HodnoceniIcon, 
  'Objevování': ObjevovaniIcon, 
  'Procvičování': ProcvicovaniIcon,
  'Shrnutí': ShrnutiIcon,
  'Výklad': VykladIcon,
  'Zdroje': ZdrojeIcon,
  'Zpětné vazby': ZpetneVazbyIcon
};

const Activity = ({ activity, beforeAfter = false, onActivityClick, isOpen, everOpened, materialVisibility }: ActivityProps) => {
  const [defaultSize, setDefaultSize] = useState<number>(0);
  const activityRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobileQuery();

  useEffect(() => {
    setDefaultSize(activityRef.current?.clientHeight ? activityRef.current?.clientHeight : 0)
  }, [])

  const {
    description,
    gadgets,
    icon, icons,
    name, size,
    urls, files
  } = activity;

  const isEmptyDescription = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent?.trim() === '';
  }

  const hasNoContent = !icons && isEmptyDescription(description);

  const linkify = (text: string) => {
    const urlRegex = /(\b((https?|ftp|file):\/\/|www\.)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
      return `<a href="${url.match(/^https?:/) ? url : '//' + url}" target="_blank" rel="noreferrer">${url}</a>`;
    });
  }

  const handleLinkClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <div
      className={`activity-container${isMobile ? "-mobile" : ""}`}
      style={{
        height: isOpen ? "auto" : 
          beforeAfter && everOpened ? "69px" : 
            beforeAfter ? "36px" : `calc(var(--editor-five-min-height${isMobile ? "-mobile" : ""})/5*${size})`,
        backgroundColor: "rgba(128, 7, 170, 0.15)",
        borderBottom: "1px solid rgb(179, 179, 179)",
        minHeight: beforeAfter ? "" : defaultSize + 2
      }}
      ref={activityRef}
      onClick={onActivityClick}>
      {!isOpen ? (
        <div className={size > 1 ? "activity-content" : "activity-content-small"}>
          <div className={size > 1 ? "icons-closed" : "icons-closed-small"}>
            {icons?.map((iconName: string, index: number) => {
              if (iconName === "icons" || iconName === "") return;
              const IconComponent = iconMapping[iconName as keyof typeof iconMapping];
              return <IconComponent key={index} className="icon" />;
            })}
          </div>
          <div
            className="text"
            style={{paddingLeft: !isMobile && icons && icons.length > 0 ? "10px" : "0px"}}>
            {name}
          </div>
        </div>
      ) : (
        <div className={`activity-content-open p-4 ${hasNoContent ? "no-content" : ""}`}>
          <div className="flex items-center mb-1" style={{ justifyContent: hasNoContent ? "center" : "flex-start" }}>
            {(() => {if (icon === null) {return} const IconComponent = iconMapping[icon as keyof typeof iconMapping];
              if (IconComponent) {
                return <IconComponent className="icon mr-4" />;
              }
              return null;
            })()}
            <h2 className={`font-semibold info-container ${isMobile ? "text-sm" : "text-lg"}`}>{name}</h2>
          </div>
          <div className="flex flex-wrap mb-1 items-end">
            {icons && icons.filter(icon => icon.trim() !== "").map((icon, index) => {
              if (icon.trim() === "icons" || icon.trim() === "") return null;
              const IconComponent = iconMapping[icon as keyof typeof iconMapping];
              return (
                <IconComponent key={index} className="icon mr-4" />
              );
            })}
            <h3 className="size-text">Délka:</h3>
            {size} {size === 1 ? "minuta" : size > 4 ? "minut" : "minuty"}
          </div>
          {description && !isEmptyDescription(description) && (
            <div className="info-container description-container">
              <h3>Popis:</h3>
              <div
                className="activity-description"
                dangerouslySetInnerHTML={{__html: description && !isEmptyDescription(description) ? linkify(description) : ""}}
              ></div>
            </div>
          )}
          {gadgets && gadgets.some((gadget) => gadget.trim() !== "") && (
            <div className="mb-2 info-container">
              <h3>Pomůcky:</h3>
              <p className="text-break">{gadgets.filter((gadget) => gadget.trim() !== "").join(", ")}</p>
            </div>
          )}
          {files && files.some((file) => file.name.trim() !== "") &&
            files.filter((file) => file.name.trim() !== "").some(({ visibility }) => {
              if (visibility !== "teacher" && ((visibility === "after-lesson" && materialVisibility === "after-lesson") || visibility === "student")) {return true;}
            }) && (
              <div className="mb-2 info-container">
                <h3>Soubory:</h3>
                {files.filter((file) => file.name.trim() !== "").map(({ url_to_file, name, description, visibility }, index) => {
                    if (visibility !== "teacher" &&((visibility === "after-lesson" && materialVisibility === "after-lesson") || visibility === "student"))
                      return (
                        <div key={index}>
                          <a href={name.match(/^https?:/) ? name : "/api/get-file/" + url_to_file} className="underline text-blue-500 text-break" target="_blank" rel="noreferrer" onClick={handleLinkClick}>{name}</a>
                          <p className="text-break">{description}</p>
                        </div>
                      );
                  })}
              </div>
            )}
          {urls && urls.some((url) => url.name.trim() !== "") &&
            urls.filter((url) => url.name.trim() !== "").some(({ visibility }) => {
              if (visibility !== "teacher" && ((visibility === "after-lesson" && materialVisibility === "after-lesson") || visibility === "student")) {return true;}
            }) && (
              <div className="mb-2 info-container">
                <h3>Odkazy:</h3>
                {urls.filter((url) => url.name.trim() !== "").map(({ name, description, visibility }, index) => {
                    if (visibility !== "teacher" &&((visibility === "after-lesson" && materialVisibility === "after-lesson") || visibility === "student"))
                      return (
                        <div key={index}>
                          <a href={name.match(/^https?:/) ? name : "//" + name} className="underline text-blue-500 text-break" target="_blank" rel="noreferrer" onClick={handleLinkClick}>{name}</a>
                          <p className="text-break">{description}</p>
                        </div>
                      );
                  })}
              </div>
            )}
        </div>
      )}
    </div>
  )
}

export default Activity;