import AppBar from 'components/mobile-utils/AppBar';
import './MobileCalendarMenu.scss'
import CalendarModal from './CalendarModal'
import { useModal } from 'contexts/ModalProvider';
import MobileCalendarLessons from './MobileCalendarLessons';
// SVGs
import { ReactComponent as Next } from "images/student-lesson-menu-icons/next.svg";
import { ReactComponent as Previous } from "images/student-lesson-menu-icons/previous.svg";
import { useLayoutEffect, useState } from 'react';

const MobileCalendarMenu = (props: any) => {
  const { showModal } = useModal();
  const [lessonDatas, setLessonDatas] = useState<{ name: string, subject: string, teachingUnit: string, teacher: string, time: string}[]>([]);

  useLayoutEffect(() => {
    if (!props.calendarDayData) return
    const newLessonDatas = []
    for (const lesson of props.calendarDayData) {
      let formattedTime = ""
      const optionsFrom: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
      const optionsTo: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
      for (const time of lesson.days_of_usage) {
        if (new Date(time.ts_from).getDate() !== props.selectedDate.getDate())
          continue
        const dateFrom = new Date(time.ts_from);
        const dateTo = new Date(time.ts_to);
        const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(',', '');
        const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(',', '');
        formattedTime = formattedFrom + " - " + formattedTo
        break
      }
      const newLessonData = {
        "_id": lesson._id,
        "name": lesson.name,
        "subject": lesson.subject,
        "teachingUnit": lesson.teaching_unit,
        "teacher": lesson.teachers_email,
        "teachers_data_id": lesson.teachers_data_id,
        "time": formattedTime
      }
      newLessonDatas.push(newLessonData)
    }
    setLessonDatas(newLessonDatas)
  }, [props.calendarDayData])

  const previousDay = () => {
    const date = props.selectedDate;
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    
    if (date.getFullYear() !== prevDate.getFullYear() || date.getMonth() !== prevDate.getMonth()) {
      props.obtainCalendarData();
    }
    props.setSelectedDate(new Date(prevDate))
  }

  const nextDay = () => {
    const date = props.selectedDate;
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() + 1);
    
    if (date.getFullYear() !== prevDate.getFullYear() || date.getMonth() !== prevDate.getMonth()) {
      props.obtainCalendarData();
    }
    props.setSelectedDate(new Date(prevDate))
  }

  return (
    <>
      <AppBar className='mobile-calendar-lesson-menu-nav' />
      <div className="mobile-calendar-lesson-menu-header">
        <div className="mobile-calendar-lesson-menu-header-date">
          <Previous onClick={previousDay} className='change-date-buttons' />
          <span>{props.selectedDate.toLocaleDateString()}</span>
          <Next onClick={nextDay} className='change-date-buttons' />
        </div>
        <button
          className="mobile-calendar-lesson-menu-calendar-toggle"
          onClick={() => showModal(<CalendarModal selectedDate={props.selectedDate} setSelectedDate={props.setSelectedDate} />)}>
          Kalendář
        </button>
      </div>
      <div className="mobile-calendar-lesson-menu-content">
        <MobileCalendarLessons lessonProgress={props.lessonProgress} lessonDatas={lessonDatas} selectedDate={props.selectedDate} />
      </div>
    </>
  )
}

export default MobileCalendarMenu;