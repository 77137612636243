import axios from "utils/axios";
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { removeUser } from '../../../redux/authSlice';
import { Button } from 'flowbite-react';

export const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = async () => {
        await axios.get('/api/logout')
        dispatch(removeUser());
        navigate("/");
    }

    return (
        <Button 
        onClick={handleLogout}
        color="light">
            Odhlásit se
        </Button>
    );
}