import './MaterialDescriptionModal.scss';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import { useModal } from "contexts/ModalProvider";

const MaterialDescriptionModal = (props: any) => {
  const { closeModal } = useModal();

  return (
    <div className='material-description-modal'>
      <KeyboardShortcuts context="materialDescriptionModal" />
      <h1>Popis uloženého materiálu:</h1>
      {props.description ?
        <p>{props.description}</p>
      :
        <p>Tento materiál nemá žádný popis.</p>
      }
      <div className="button-group">
        {props.url_to_file && (
          <a href={`/api/get-file/${props.url_to_file}`} target="_blank" rel="noopener noreferrer">
            <button className='download-button-modal px-2 py-1 text-sm text-white bg-[#8007AA] rounded-md'>
              Stáhnout
            </button>
          </a>
        )}
        <button id="closeButtonMaterialDescriptionModal" className="close-button" onClick={closeModal}>
          Zavřít
        </button>
      </div>
    </div>
  )
}

export default MaterialDescriptionModal;
