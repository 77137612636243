import Lesson from './lesson/Lesson'
import Timeline from './timeline/Timeline'
import './LessonWindow.scss'
import Notes from './lesson/notes/Notes'
import Accessories from './lesson/accessories/Accessories'
import LessonHeader from './lesson/accessories/LessonHeader'
import LessonRow from './lesson/LessonRow'

interface LessonWindowProps {
  subjectId: string | undefined
  lessonId: string | undefined
  lengthInMins: number
  activitiesBefore: any
  activitiesAfter: any
  activitiesList: any[]
}

const LessonWindow = (props: LessonWindowProps) => {
  return (
    <div className="lesson-window">
      <div className="lesson-header-container flex-nowrap whitespace-nowrap flex items-center border-b border-gray-300 pl-6 flex-shrink-0">
        <LessonHeader/>
      </div>
      <div className="flex-nowrap whitespace-nowrap h-20 flex items-center border-b border-gray-300 pl-6">
        <div className="w-44 pr-10 font-medium select-none">Před hodinou:</div>
          <LessonRow _id={props.activitiesBefore._id} activities={props.activitiesBefore.activities} beforeAfter={true} />
        </div>
      <div className="main-lesson">
        <Timeline lengthInMins={props.lengthInMins} />
        <Lesson activitiesList={props.activitiesList} />
        <Accessories
          activitiesBefore={props.activitiesBefore.activities}
          activitiesList={props.activitiesList}
          activitiesAfter={props.activitiesAfter.activities} />
      </div>
      <div className="flex-nowrap whitespace-nowrap h-20 flex items-center border-y border-gray-300 pl-6">
        <div className="w-44 pr-12 font-medium select-none">Po hodině:</div>
          <LessonRow _id={props.activitiesAfter._id} activities={props.activitiesAfter.activities} beforeAfter={true} />
        </div>
      <Notes />
    </div>
  )
}

export default LessonWindow