import { useEffect, useRef, ReactNode } from 'react';

interface ContextMenuBaseProps {
  x: number;
  y: number;
  closeMenu: () => void;
  children: ReactNode;
}

const ContextMenuBase = ({ children, x, y, closeMenu }: ContextMenuBaseProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, closeMenu]);

  return (
    <div ref={ref} style={{ position: 'absolute', top: y, left: x, zIndex: 1 }} className="bg-white border-gray-200 border p-2 rounded shadow-lg">
      {children}
    </div>
  );
};

export default ContextMenuBase;
