import { useState, useLayoutEffect, createRef } from 'react';
import '../../editor/lesson-window/LessonWindow.scss';
import { iLessonActivityItem } from '../../../interfaces/lesson-activity-interface';
import LessonRow from './LessonRow';
import '../../editor/lesson-window/timeline/Timeline.scss';
import './LessonStudent.scss';
import TimelineStudent from './TimelineStudent';

interface LessonStudentProps {
  activitiesList: iLessonActivityItem[];
  openActivityRow: (index: number) => void;
  lengthOfLesson: number;
  materialVisibility: string;
}

const LessonStudent = ({ activitiesList, openActivityRow, lengthOfLesson, materialVisibility }: LessonStudentProps) => {
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
  const [timeline, setTimeline] = useState<string[]>([]);
  const activityRefs = new Array(activitiesList.length).fill(null).map(() => createRef<HTMLDivElement>());
  const [prevHeight, setPrevHeight] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [timelineLinesHeight, setTimelineLinesHeight] = useState(new Array(0));

  useLayoutEffect(() => {
    if (activitiesList.length > 0) setTimelineLinesHeight(new Array(activitiesList.length).fill(0));
    setTimelineMins();
  }, [activitiesList]);

  useLayoutEffect(() => {
    if (prevHeight === 0) return;
    const newHeight = (activityRefs[selectedIndex]?.current?.clientHeight ?? 0) - 1;
    let delta = newHeight - prevHeight;
    if (delta < 0) delta = 0;
    setTimelineLinesHeight(prevLinesHeight => prevLinesHeight.map((height, index) => {
      if (index === selectedIndex) return delta;
      return height;
    }));
    setPrevHeight(0);
  }, [prevHeight])

  const handleRowClick = (index: number) => {
    setSelectedIndex(index);
    setPrevHeight(parseInt(String((activityRefs[index]?.current?.clientHeight ?? 0) - 1)));
    const newOpenRowIndex = openRowIndex === index ? null : index;
    openActivityRow(newOpenRowIndex || 0);
    setOpenRowIndex(newOpenRowIndex);
  };

  const setTimelineMins = () => {
    const newTimeline: string[] = [];
    let activityIndex = 0;
    let size = 0;

    for (let i = 0; i <= lengthOfLesson + 5; i=i+5) {
      if (i !== 0 && i % 5 === 0) {
        // eslint-disable-next-line
        while (true) {
          if (activityIndex === activitiesList.length) break;
          if (!activitiesList[activityIndex].activities[0] || !activitiesList[activityIndex].activities[0].size) break;
          if (parseInt(String((size + activitiesList[activityIndex].activities[0].size) / i)) === 0) {
            size = size + activitiesList[activityIndex].activities[0].size;
            newTimeline.push("a" + activityIndex);
            activityIndex++;
          } else {
            break;
          }
        }
        if ((activityIndex === activitiesList.length && activitiesList.length !== 0) || i > lengthOfLesson) break;
        newTimeline.push(i + " min");
      }
    }
    setTimeline(newTimeline);
  }

  return (
    <div className="student-lesson-view">
      <TimelineStudent timeline={timeline} timelineLinesHeight={timelineLinesHeight}/>
      <div className='activities-list'>
      {activitiesList && activitiesList.map(({activities}: any, index) => {

        return (
          <div className='flex' key={index} ref={activityRefs[index]}>
            <LessonRow key={index} activities={activities} openActivityRow={() => handleRowClick(index)} isRowOpen={openRowIndex === index} materialVisibility={materialVisibility}/>
          </div>
        )
      })}
      </div>
    </div>
    );
};

export default LessonStudent;
