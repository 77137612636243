
const StudentInfoMenu = (props: any) => {

  return (
    <div className="gap-1 flex flex-col">
      <div><span className="student-calendar-heading-small">Předmět: </span>{props.lessonData.subject}</div>
      <div><span className="student-calendar-heading-small">Výukový celek: </span>{props.lessonData.teachingUnit}</div>
      <div><span className="student-calendar-heading-small">Vyučující: </span>{props.lessonData.teacher}</div>
      <div className="flex flex-row">
        <div className="w-min mr-1">
          <span className="student-calendar-heading-small">Čas: </span>
        </div>
        <div className="flex-col">
          {props.lessonData.times.map((time: string, index: number) => {
            return <div key={index} className="">{time}</div>
          })}
        </div>
      </div>
    </div>

  );
};

export default StudentInfoMenu;
