import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from "utils/axios";
import { useNavigate } from "react-router-dom";

// registration logic hook
export default () => {

  type Form = {
    email: string;
    password: string;
    confirmPwd: string;
    key: string;
    gdpr: boolean;
    apiError: string;
  }

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    email: yup.string()
      .required("Toto pole je povinné")
      .email("E-mail není ve validním formátu")
      .max(320, "E-mail není ve validním formátu")
      ,
    password: yup.string()
      .required("Toto pole je povinné")
      .min(6, "Heslo musí být minimálně 6 znaků dlouhé")
      .max(100, "Heslo nesmí být delší než 100 znaků!")
      ,
    confirmPwd: yup.string()
      .required("Toto pole je povinné")
      .oneOf([yup.ref("password")], "Hesla se neshodují")
      ,
    key: yup.string()
      .required("Toto pole je povinné")
      ,
    gdpr: yup.boolean()
      .required("Toto pole je povinné")
      .isTrue("Toto pole je povinné")
  })

  const {setError, register, handleSubmit, formState: { errors } } = useForm<Form>({ 
    resolver: yupResolver(formSchema)
  });

  const onSubmit = (data: Form) => {
    axios.post("/api/registration",{
      email: data.email,
      password: data.password,
      key: data.key,
      gdpr: data.gdpr
    })
    .then(() => {
      setIsSuccessfullySubmitted(true);
      setTimeout(() => {
        navigate("/");
      }, 750);
    }).catch(err => {
      if (!err.response.data.errorMessage) {
        setError("apiError", {
          type: "server",
          message: "Někde nastala chyba zkuste to znovu!",
        });
      }
      if(err.response.data.errorMessage?.includes("already exists")) {
        setError("apiError", {
          type: "server",
          message: 'Tento e-mail je již používán!',
        });
      } else if(err.response.data.errorMessage?.includes("Key is expired!")) {
        setError("apiError", {
          type: "server",
          message: 'Platnost klíče vypršela!',
        });
      } else if(err.response.data.errorMessage?.includes("Key is already used!")) {
        setError("apiError", {
          type: "server",
          message: 'Klíč byl již použit!',
        });
      } else if(err.response.data.errorMessage?.includes("Incorrect key!")) {
        setError("apiError", {
          type: "server",
          message: 'Nesprávný klíč!',
        });
      } else {
        setError('apiError', {
          type: "server",
          message: 'Někde nastala chyba zkuste to znovu!',
        });
      }
    })
  }
  
  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    isSuccessfullySubmitted
  }
}