// Library imports
import { Calendar } from 'react-calendar'
// Components
import CalendarSelectionMenu from '../../student/calendar-menu/CalendarSelectionMenu';
import CalendarInfoMenu from '../../student/calendar-menu/CalendarInfoMenu';
import StudentMaterialsOverview from '../../student/materials-overview/StudentMaterialsOverview';
import StudentLessonMenuNav from '../../student/nav/StudentLessonMenuNav';
import LessonPreview from './LessonPreview';
// import LessonPdfPreview from './LessonPdfPreview';
// Media and stylesheets
import './CalendarMenuWindow.scss';
// SVGs
import { ReactComponent as Next } from "../../../images/student-lesson-menu-icons/next.svg";
import { ReactComponent as Previous } from "../../../images/student-lesson-menu-icons/previous.svg";
import { setLessonId, setTeachersId } from 'redux/editorSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PropsWithChildren, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { CalendarData } from 'interfaces/student-user-data-interface';
import { RootState } from 'redux/store';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';

interface CalendarMenuWindowProps extends PropsWithChildren<any> {
  activeLesson: string | undefined,
  setActiveLesson: React.Dispatch<React.SetStateAction<string | undefined>>,
  selectionLessons: string[],
  setSelectionLessons: React.Dispatch<React.SetStateAction<string[]>>,
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  savedMaterials: {name: string;type: string;url: string;description: string;}[] | undefined,
  lessonNames: string[],
  lessonData: {_id: string;subject: string;teachingUnit: string;teacher: string;teachers_data_id: string;times: string[];} | undefined,
  lessonProgress: string[] | undefined,
  calendarDayData: CalendarData[] | undefined
  setSavedMaterials: React.Dispatch<React.SetStateAction<{name: string;type: string;url: string;description: string;}[] | undefined>>
  setLessonData: React.Dispatch<React.SetStateAction<{_id: string;subject: string;teachingUnit: string;teacher: string;teachers_data_id: string;times: string[];} | undefined>>
  setActiveLessonIndex: React.Dispatch<React.SetStateAction<number>>,
  rateLessonMaterials: any,
  lessonRating: string | undefined,
}

const CalendarMenuWindow = ({activeLesson, setActiveLesson, selectionLessons, setSelectionLessons,
   selectedDate, setSelectedDate, savedMaterials, lessonNames, lessonData, lessonProgress, calendarDayData,
    setSavedMaterials, setLessonData, setActiveLessonIndex,
  rateLessonMaterials, lessonRating}: CalendarMenuWindowProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const studentsEmail = useSelector((state: RootState) => state.auth.userInfo.email)
  const studentsClass = useSelector((state: RootState) => state.editor.class)
  const [activeLessonId, setActiveLessonId] = useState<string>();
  const [activeTeachersId, setActiveTeachersId] = useState<string>();

  useEffect(() => {
    setLessonData(undefined);
    setSavedMaterials(undefined);
    updateSelections(selectionLessons, setSelectionLessons, lessonNames, activeLesson);
    if (activeLesson && calendarDayData) {
      const lesson = calendarDayData.find((lesson) => lesson.name === activeLesson)
      if (!lesson) return
      setActiveLessonId(lesson._id)
      setActiveTeachersId(lesson.teachers_data_id)
      getMaterials(lesson._id, lesson.teachers_data_id)
      if (!lesson) return
      const times = []
      const optionsFrom: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
      const optionsTo: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
      for (const time of lesson.days_of_usage) {
        if (new Date(time.ts_from).getDate() !== selectedDate.getDate())
          continue
        const dateFrom = new Date(time.ts_from);
        const dateTo = new Date(time.ts_to);
        const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(',', '');
        const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(',', '');
        times.push(`${formattedFrom} - ${formattedTo}`);
      }
      const newLessonData = {
        "_id": lesson._id,
        "subject": lesson.subject,
        "teachingUnit": lesson.teaching_unit,
        "teacher": lesson.teachers_email,
        "teachers_data_id": lesson.teachers_data_id,
        "times": times
      }
      setLessonData(newLessonData)
    }
  }, [activeLesson])

  // Get materials of the lesson from API endpoint
  const getMaterials = async (lessonId: string | undefined, teachersId: string | undefined) => {
    if (!lessonId || !teachersId) return
    const materials = await axios.get("/api/lesson/" + lessonId + "/" + teachersId + "/student-materials")
    const newMaterials: any[] = []
    materials.data.files.forEach((file: any) => {
      const newFile = {
        "name": file.name,
        "type": "file",
        "description": file.description,
        "url_to_file": file.url_to_file
      }
      newMaterials.push(newFile)
    })
    materials.data.urls.forEach((url: any) => {
      const newFile = {
        "name": url.name,
        "type": "url",
        "description": url.description,
        "url": ""
      }
      newMaterials.push(newFile)
    })
    setSavedMaterials(newMaterials)
  }

  // Updates the selection state of given selection list (lessonNames) based on the given active item and list
  const updateSelections = (
    selection: Array<string>,
    setSelection: React.Dispatch<React.SetStateAction<string[]>>,
    list?: Array<string>,
    active?: string
  ) => {
    const newSelection = new Array(selection.length);
    newSelection.fill("unselected")
    if (list && active) {
      const indexOfActiveItem = list.indexOf(active)
      newSelection[indexOfActiveItem] = "selected"
    }
    setSelection(newSelection)
  }

  const handleOpenButton = () => {
    if (!lessonData) return;
    dispatch(setLessonId(lessonData._id));
    dispatch(setTeachersId(lessonData.teachers_data_id));
    navigate('/lesson-view');
  }

  return (
    <>
      {/* Navbar */}
      <KeyboardShortcuts context="studentCalendarMenu"></KeyboardShortcuts>
      <StudentLessonMenuNav 
        lessonData={lessonData}
      />
      {/* Left column */}
      <div className="student-calendar-left-column">
        {/* Name of the students class */}
        <div className='student-calendar-box-heading'>
          <label className="student-calendar-heading-small">{studentsEmail}</label><br />
          <label className="student-calendar-heading-medium">{studentsClass}</label>
        </div>
        {/* Calendar for viewing lessons */}
        <div className="student-calendar-box">
          <div className='student-calendar-select-box-heading'>
            <label className="student-calendar-heading-medium">Kalendář</label>
          </div>
          <div className="student-calendar-container">
            <Calendar
              className="student-calendar-content"
              value={selectedDate}
              onChange={(newDate: any) => setSelectedDate(new Date(newDate))}
              locale='cs-CS'
              maxDate={new Date("2050-12-31")}
              minDate={new Date("2023-06-01")}
              tileClassName={'calendar-tile'}
              nextLabel={<Next />}
              prevLabel={<Previous />}
              next2Label={null}
              prev2Label={null}
              minDetail={'year'}
              showFixedNumberOfWeeks={true}
            />
          </div>
        </div>
        {/* Menu for selecting "hodiny" */}
        <div className="student-calendar-select-box">
          <div className='student-calendar-select-box-heading'>
            <label className="student-calendar-heading-medium">Hodiny</label>
          </div>
          <div className='student-calendar-elements-box'>
            <CalendarSelectionMenu
              list={lessonNames}
              selectionList={selectionLessons}
              setSelectionList={setSelectionLessons}
              setActiveList={setActiveLesson}
              lessonProgress={lessonProgress}
              setActiveLessonIndex={setActiveLessonIndex}
            />
          </div>
        </div>
      </div>
      {/* Middle column */}
      <div className="student-calendar-middle-column">
        {/* Content for middle column */}
        <div className="student-calendar-lection">
          <div className='student-calendar-select-box-heading'>
            <label className="student-calendar-heading-big">Náhled hodiny</label>
          </div>
          <div className='student-calendar-lesson-preview'>
            <LessonPreview
            activeLessonId={activeLessonId}
            activeTeachersId={activeTeachersId}/>
            {/* <LessonPdfPreview lessonId={props.lessonId} dataId={props.dataId} /> */}
          </div>
        </div>
        <StudentMaterialsOverview materialsList={savedMaterials}
         rateLessonMaterials={rateLessonMaterials}
         lessonRating={lessonRating}
          />
      </div>
      {/* Right column */}
      <div className="student-calendar-right-column">
        {/* Content for right column */}
        <div className='flex flex-col gap-5'>
          <label className="student-calendar-heading-medium">Popis hodiny:</label>
          {lessonData ? (<CalendarInfoMenu lessonData={lessonData} /*activeTeachingUnit={activeTeachingUnit}*/ />) : null}
        </div>
        <div className="student-calendar-info-buttons">
          <button className="student-calendar-open" disabled={!activeLesson} id={"openButton"} onClick={handleOpenButton}>Otevřít</button>
        </div>
      </div>
    </>
  )
}

export default CalendarMenuWindow;