import { useState } from 'react';
import '../../editor/lesson-window/LessonWindow.scss';
import LessonHeaderStudent from './LessonHeaderStudent';
import AccessoriesStudent from './AccessoriesStudent';
import NotesStudent from '../NotesStudent';
import LessonStudent from './LessonStudent';
import LessonRow from './LessonRow';
import { useLessonContext } from './LessonContext';

const LessonWindowStudent = () => {

  const { activities, activitiesBefore, activitiesAfter, lengthOfLesson, lessonName, lessonGoal, lessonCurriculums, notes, materialVisibility } = useLessonContext();

  const [isAnyActivityOpen, setIsAnyActivityOpen] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);

  const handleRowClick = (index: number) => {
    if (openRowIndex === index) setOpenRowIndex(null);
    else setOpenRowIndex(index);
    setIsAnyActivityOpen(true);
  }

  return (
    <div className="lesson-window">
      <div className="lesson-header-container flex-nowrap whitespace-nowrap flex items-center border-b border-gray-300 pl-6 flex-shrink-0">
        <LessonHeaderStudent
          lessonName={lessonName}
          lessonGoal={lessonGoal}
          lessonCurriculums={lessonCurriculums}
        />
      </div>
      {activitiesBefore.length > 0 ?
      <div className={`flex-nowrap whitespace-nowrap flex items-center border-b border-gray-300 pl-6 ${isAnyActivityOpen ? 'h-auto' : 'h-20'}`}>
        <div className="w-40 pr-10 border-none text-black font-medium select-none">Před hodinou:</div>
        <LessonRow 
          beforeAfter
          key={0} 
          activities={activitiesBefore}
          openActivityRow={() => handleRowClick(0)}
          isRowOpen={openRowIndex === 0}
          materialVisibility={materialVisibility}
        /> 
      </div>
      : null}
      <div className="main-lesson">
        <LessonStudent activitiesList={activities} lengthOfLesson={lengthOfLesson} openActivityRow={handleRowClick} materialVisibility={materialVisibility}/>
        <AccessoriesStudent />
      </div>
      {activitiesAfter.length > 0 ?
      <div className={`flex-nowrap flex items-center border-t border-gray-300 pl-6 ${isAnyActivityOpen ? 'h-auto' : 'h-20'}`}>
        <div className="w-40 pr-10 border-none text-black font-medium select-none">Po hodině:</div>
        <LessonRow 
          beforeAfter
          key={1} 
          activities={activitiesAfter}
          openActivityRow={() => handleRowClick(1)}
          isRowOpen={openRowIndex === 1}
          materialVisibility={materialVisibility}
        /> 
      </div>
      : null}
      <NotesStudent
        notes={notes}
      />
    </div>
  );
};

export default LessonWindowStudent;
