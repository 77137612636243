import { iLesson } from "interfaces/lesson-activity-interface";
import { iClass, iLessonMenuIndexes, iUserData } from "interfaces/user-data-interface";
import { PropsWithChildren, useEffect, useState } from "react";

import "./InfoMenu.scss"

import { ReactComponent as Unused } from "images/student-lesson-menu-icons/unused.svg";
import { ReactComponent as Downvote } from "images/student-lesson-menu-icons/downvote.svg";
import { ReactComponent as Neutral } from "images/student-lesson-menu-icons/neutral.svg";
import { ReactComponent as Upvote } from "images/student-lesson-menu-icons/upvote.svg";

interface InfoMenuProps extends PropsWithChildren<any> {
	userData: iUserData;
	lessonData: iLesson;
	getActiveItemsIndexes: () => iLessonMenuIndexes;
  updateClasses: (classes: iClass[]) => void;
  reloadUserData: () => void;
}

const InfoMenu = ({userData, lessonData, getActiveItemsIndexes, updateClasses, reloadUserData}: InfoMenuProps) => {
	const [lessonUsage, setLessonUsage] = useState<string[]>([]);
	const [tsCreated, setTsCreated] = useState<string>("");
	const [tsUpdated, setTsUpdated] = useState<string>("");

	useEffect(() => {
		if (!userData || !lessonData) return
		defaultValues();
		dataProccess();
	}, [lessonData, userData]);

	const defaultValues = () => {
		setLessonUsage([]);
		setTsCreated("");
		setTsUpdated("");
	};

	const dataProccess = () => {
		if (!userData || !lessonData) return;
    const indexes = getActiveItemsIndexes()
		const optionsFrom: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric',hour: 'numeric', minute: 'numeric' };
		const optionsTo: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
		const lessonInfo = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
			.teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex];
		for (const time of lessonInfo.days_of_usage) {
			const dateFrom = new Date(time.ts_from);
			const dateTo = new Date(time.ts_to);
			const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(',', '');
			const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(',', '');
			const result = `${formattedFrom} - ${formattedTo}`;
			setLessonUsage(lessonUsage => [...lessonUsage, result]);
		}
		const tsCreatedRaw = new Date(lessonData.ts_created);
		const tsUpdatedRaw = new Date(lessonData.ts_updated);
		setTsCreated(tsCreatedRaw.toLocaleString('cs-CZ', optionsFrom).replace(',', ''));
		setTsUpdated(tsUpdatedRaw.toLocaleString('cs-CZ', optionsFrom).replace(',', ''));
	};

  const unpublishLesson = async (index: number) => {
    if (!userData || !lessonData) return;
    const indexes = getActiveItemsIndexes()
    userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex]
      .days_of_usage = 
        userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex]
        .days_of_usage.filter((_, i) => i !== index);
    if (userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
    .teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex]
    .days_of_usage.length === 0) userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
    .teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex]
    .published = false;
    await updateClasses(userData.classes);
    await reloadUserData();
  }


  return (
    <section className="lesson-informations">
      <div>Délka hodiny: {lessonData?.length_in_mins} minut</div>
      <div>Vytvořeno: {tsCreated}</div>
      <div>Upraveno: {tsUpdated}</div>
      {(lessonUsage.length > 0) ? 
      <div className="lesson-usage-container">
        <label className="heading-small">Použití hodiny: </label>
          <div className="usages">
            {lessonUsage.map((value: string, index: number) => (
              <div key={index} className="lesson-usage">
                {value}
                <button className="unpublish-button" onClick={() => unpublishLesson(index)}>Skrýt</button>
              </div>
            ))}
          </div>
      </div> : null }

      <div className="lesson-rating-container mr-1">
        <label className="heading-small">Hodnocení materiálů: </label>
        
        <div className="flex flex-row items-center justify-between w-full mt-1">
            <div className="flex flex-row items-center justify-between w-12">
              <Upvote
              className="rating-icon"
              /> { lessonData?.ratings?.filter((rating) => rating.rating === "upvote").length }
            </div>
            <div className="flex flex-row items-center justify-between w-12">
              <Neutral 
              className="rating-icon"
              /> { lessonData?.ratings?.filter((rating) => rating.rating === "neutral").length }
            </div>
            <div className="flex flex-row items-center justify-between w-12">
              <Downvote 
              className="rating-icon"
              /> { lessonData?.ratings?.filter((rating) => rating.rating === "downvote").length }
            </div>
            <div className="flex flex-row items-center justify-between w-12">
              <Unused 
              className="rating-icon"
              /> { lessonData?.ratings?.filter((rating) => rating.rating === "unused").length }
            </div>
        </div>
      </div>

    </section>
  );
};

export default InfoMenu;
