export const CarouselPaths = [
  {
    path: "url('/slides/slide1.png')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide2.png')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide3.png')",
    url: "https://www.mironet.cz/"
  }
];

export const MobileCarouselPaths = [
  {
    path: "url('/slides/slide1.png')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide2.png')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide3.png')",
    url: "https://www.mironet.cz/"
  }
];