import { useState, useEffect, useRef } from 'react'
import Gadgets from './Gadgets';
import SavedMaterials from './SavedMaterials';
import Notes from './Notes';
import { useModal } from 'contexts/ModalProvider';

const MobileBottomBar = () => {

  const [openTab, setOpenTab] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const { modalOpen } = useModal();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (!modalOpen) setOpenTab(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, modalOpen]);

  const openTabContent = () => {
    switch (openTab) {
      case "Pomůcky":
        return <Gadgets />
      case "Uložené materiály":
        return <SavedMaterials />
      case "Poznámky":
        return <Notes />
      default:
        return null;
    }
  };

  return (
    <div className="flex-shrink-0" ref={ref}>
      <div className='bg-white fixed inset-x-0 bottom-0 border-t border-black border-dashed py-2 px-4'>
        <div className='flex justify-between'>
          {["Pomůcky", "Uložené materiály", "Poznámky"].map(text => (
            <button
              key={text}
              onClick={(e) => {
                e.stopPropagation();
                setOpenTab(openTab === text ? null : text);
              }}
              className={`px-2 py-1 max-[350px]:text-xs text-sm text-white ${openTab === text ? `bg-[#8007aa4d]` : `bg-[#8007AA]`} rounded-md`}
            >
              {text}
            </button>
          ))}
        </div>
        {
          openTab &&
          <div className='mt-2 min-h-[40vh] flex'>
            <div className='border border-black overflow-hidden rounded-md p-2 flex-grow'>
              {openTabContent()}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default MobileBottomBar;
