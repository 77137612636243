import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { removeUser } from '../../redux/authSlice';

interface ProtectedRouteProps {
  allowedRoles?: string[];
  userIsNeeded?: boolean;
  redirectLoggedUser?: boolean;
  children: JSX.Element;
}

const ProtectedRoute = ({ allowedRoles, userIsNeeded = false, redirectLoggedUser = false, children }: ProtectedRouteProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  useEffect(() => {
    if (redirectLoggedUser) {
      switch (userInfo.role) {
        case 'teacher':
          navigate('/lesson-menu');
          break;
        case 'student':
          navigate('/view-selection');
          break;
        case 'parent':
          navigate('/view-selection');
          break;
      }
    }

    if (userIsNeeded && (!userInfo._id || (allowedRoles && userInfo.role && !allowedRoles.includes(userInfo.role)))) {
      // If there's no user logged in or the logged-in user's role is not allowed,
      // remove the user information from Redux state and local storage and navigate to login page
      dispatch(removeUser());
      navigate("/");
    }
  }, [userInfo, userIsNeeded, allowedRoles, redirectLoggedUser, dispatch, navigate]);

  // Check if the current route requires a user
  if (!userIsNeeded) {
    return children
  }

  // If a user is required, check if there is a user logged in (userInfo._id is not undefined)
  // And if allowedRoles is defined, check if the logged-in user's role is included in allowedRoles
  if (userInfo._id && (!allowedRoles || (userInfo.role && allowedRoles.includes(userInfo.role)))) {
    return children
  }

  // Wait for useEffect to do its work
  return null;
}


export default ProtectedRoute;