import './ParentalAccess.scss';
import { useModal } from '../../../../contexts/ModalProvider';
import axios from 'utils/axios';
import { useEffect, useState } from 'react';
import { parentKey } from 'interfaces/student-user-data-interface';


const ParentalAccess = () => {
  const { closeModal } = useModal();
  const [parentKeys, setParentKeys] = useState<parentKey[]>([]);

  useEffect(() => {
    getParentKeys();
  }, [])

  const getParentKeys = async () => {
    const response = await axios.get('api/current-student/parent-keys');
    if (response.status !== 200) {
      console.error("Couldn't get parent keys");
      return;
    }
    if (!response.data.keys || response.data.keys.length < 1) {
      console.error("No parent keys");
      return;
    }
    setParentKeys(response.data.keys);
  }

  return (
    <div className="parental-access">
      <div className="text-content"> 
        <h2 className="title">Rodičovské klíče</h2>
        {parentKeys.map((key, index) => (
          <div className='key' key={index}>
            <p>{key.key}</p>
            {key.used && <span className="used">(použitý)</span>

            }
          </div>
        ))}
      </div>
      <button className="close-modal" onClick={closeModal}>Zavřít</button>
    </div>
  );
};

export default ParentalAccess;
