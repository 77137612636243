import React from "react";
import './StudentMaterialsOverview.scss'
import { useModal } from '../../../contexts/ModalProvider';
import MaterialDescriptionModal from "../material-description-modal/MaterialDescriptionModal";
// SVGs
import { ReactComponent as Unused } from "../../../images/student-lesson-menu-icons/unused.svg";
import { ReactComponent as Downvote } from "../../../images/student-lesson-menu-icons/downvote.svg";
import { ReactComponent as Neutral } from "../../../images/student-lesson-menu-icons/neutral.svg";
import { ReactComponent as Upvote } from "../../../images/student-lesson-menu-icons/upvote.svg";
import { ReactComponent as Url } from "../../../images/student-lesson-menu-icons/url.svg";
import { ReactComponent as File } from "../../../images/student-lesson-menu-icons/file.svg";
import { ReactComponent as Redirect } from "../../../images/student-lesson-menu-icons/redirect.svg";
import { useSelector } from "react-redux";

const StudentMaterialOverview = (props: any) => {
  const { showModal } = useModal();
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  return (
    <div className="student-materials-overview">
      <div className="materials-overview-heading">
        <label className="student-heading-medium">Přehled materiálů k hodině:</label>
        {userInfo.role === 'student' && (
          <div className="rating-buttons">
            <button
            onClick={() => {props.rateLessonMaterials("upvote")}}
            disabled={props.lessonRating === 'upvote'}
            >
            <Upvote className={`rating-button ${props.lessonRating === 'upvote' ? 'selected' : ''}`}
            />
            </button>

            <button
            onClick={() => {props.rateLessonMaterials("neutral")}}
            disabled={props.lessonRating === 'neutral'}
            >
            <Neutral className={`rating-button ${props.lessonRating === 'neutral' ? 'selected' : ''}`}
            />
            </button>

            <button
            onClick={() => {props.rateLessonMaterials("downvote")}}
            disabled={props.lessonRating === 'downvote'}
            >
            <Downvote className={`rating-button ${props.lessonRating === 'downvote' ? 'selected' : ''}`}
            />
            </button>

            <button
            onClick={() => {props.rateLessonMaterials("unused")}}
            disabled={props.lessonRating === 'unused'}
            >
            <Unused className={`rating-button ${props.lessonRating === 'unused' ? 'selected' : ''}`}
            />
            </button>

          </div>
        )}
      </div>
      <div className="materials">
        {props.materialsList?.map((material: {type: string, name: string, description: string, url_to_file: string}, index: number) => {
          return (
            <div className="materials-item-wrapper" key={index}>
              {material.type === "file"
                ? <>
                  <File className="material-type-icon" />
                  <div className="materials-item" onClick={() => showModal(<MaterialDescriptionModal description={material.description} url_to_file={material.url_to_file} />)}>
                    <span className="material-name">
                      {material.name}
                    </span>
                  </div>
                </>
                : <>
                  <Url className="material-type-icon" />
                  <div className="materials-item" onClick={() => showModal(<MaterialDescriptionModal description={material.description} />)}>
                    <span className="material-name">
                      {material.name}
                    </span>
                    <a
                      href={material.name.startsWith('http') ? material.name : `//${material.name}`}
                      target="_blank"
                      rel="noreferrer"
                      className="url-redirect"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Redirect/>
                    </a>
                  </div>
                </>
              }
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}
export default StudentMaterialOverview;