import { useState } from "react";
import '../../editor/lesson-window/LessonWindow.scss';
import { iActivity } from '../../../interfaces/activity-interface';
import Activity from './Activity';
import { useMobileQuery } from "utils/useMediaQuery";

interface LessonRowProps {
  activities: iActivity[],
  beforeAfter?: boolean,
  openActivityRow: (index: number) => void
  isRowOpen: boolean;
  materialVisibility: string;
}

const LessonRow = ({ activities, beforeAfter = false, openActivityRow, materialVisibility }: LessonRowProps) => {
  const [isOpen, setIsOpen] = useState(false); 

  const isMobile = useMobileQuery();

  const handleActivityClick = () => {
    setIsOpen(!isOpen); 
    openActivityRow(activities[0].index);
  }

  return (
    <ul className={beforeAfter ? `lesson-before-after${isMobile ? "-mobile" : ""}` : `lesson-row`}>
      {
        activities.map((activity: iActivity, index: number) =>
          <Activity
            key={index}
            activity={activity}
            beforeAfter={beforeAfter}
            onActivityClick={handleActivityClick} 
            isOpen={isOpen}
            materialVisibility={materialVisibility}
          />
        )
      }
    </ul>
  )
}

export default LessonRow;