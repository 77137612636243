import { useState } from 'react';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import './DownloadModal.scss';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import { useModal } from '../../../contexts/ModalProvider';

interface DownloadModalProps {
  saveLesson: (location: string, navigateAfterSave?: boolean, fileName?: string) => void;
  saveAfterError?: boolean;
}

// The user can download and rename the edited lesson through this modal
const DownloadModal = ({ saveLesson, saveAfterError = false }: DownloadModalProps) => {

  const { closeModal } = useModal();
  const lessonName = useSelector((state: RootState) => state.editor.lessonName)
  const [fileName, setFileName] = useState(lessonName);

  const save = () => {
    saveLesson("drive", undefined, fileName);
    closeModal();
  }

  return (
    <div className='save-modal'>
      <KeyboardShortcuts context="downloadModal" />
      {
        !saveAfterError ?
          <h1>Název souboru:</h1> :
          <h1>Hodinu se nepodařilo uložit. Můžete si ji ale stáhnout a později nahrát ručně.</h1>
      }
      <input
        id="download-file-name-input"
        type="text"
        onChange={e => setFileName(e.target.value)}
        placeholder={"Nová hodina"}
        className={"text-black rounded"}
        value={fileName}
        onFocus={e => e.target.select()}
      />
      <div className='flex gap-3'>
        <button id="closeButtonDownloadModal" className="close-button" onClick={closeModal}>Zrušit</button>
        <button id="saveButtonDownloadModal" className="save-button" onClick={save}>Stáhnout</button>
      </div>
    </div>
  )
}
export default DownloadModal;