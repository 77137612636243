import axios from 'axios';
import { removeUser } from '../redux/authSlice';
import { store } from '../redux/store';

const instance = axios.create();

instance.interceptors.response.use(function (response) {
    // If the request succeeds, just return the response
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        // If it's 401 (Unauthorized), remove user info from Redux store
        store.dispatch(removeUser());

        // We cannot use the useNavigate hook outside the Router component
        // so we remove the user info from the Redux store and the ProtectedRoute will redirect to the login page
        // because the user is not logged in anymore
    }

    // Return the error
    return Promise.reject(error);
});

export default instance;
