import './InfoPage.scss';
import Carousel from '../components/login/Carousel';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import { useWindowSize } from 'utils/useWindowSize';
import { Link } from 'react-router-dom';
import InfoFooter from 'components/info-pages/InfoFooter';
import AppBar from 'components/mobile-utils/AppBar';
import { useSelector } from 'react-redux';
import AboutWindow from 'components/info-pages/AboutWindow';
import ContactsWindow from 'components/info-pages/ContactsWindow';
import GdprWindow from 'components/info-pages/GdprWindow';
import UpdateNoteWindow from 'components/info-pages/UpdateNoteWindow';

type InfoPageProps = {
  page: "about" | "notes" | "contacts" | "gdpr" | "login";
}

type TPages = {
  [key: string]: JSX.Element;
}

const pages: TPages = {
  about: <AboutWindow/>,
  contacts: <ContactsWindow/>,
  gdpr: <GdprWindow/>,
  notes: <UpdateNoteWindow/>
}

const InfoPage = ({page}: InfoPageProps) => {
  const windowSize = useWindowSize();
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  return (
    <section className="info-page">
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <div className='main-content'>
              {pages[page]}
              <InfoFooter page={page}/>
            </div>
          </>
        }
        mobile={
          <>
            <div className='main-content mobile'>
              <AppBar/>
              {pages[page]}
              {userInfo._id === undefined && <Link to={"/"}><button type="button" className='back-to-login'>Zpět na přihlášení</button></Link>}
            </div>
          </>
        }
      />
      </section>
  )
}

export default InfoPage;