import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { iLessonActivityItem } from 'interfaces/lesson-activity-interface';
import { iActivity } from 'interfaces/activity-interface';
import { RootState } from 'redux/store';

interface LessonContextProps {
  activities: iLessonActivityItem[];
  activitiesBefore: iActivity[];
  activitiesAfter: iActivity[];
  lengthOfLesson: number;
  gadgetsBefore: string[] | null;
  gadgets: string[] | null;
  gadgetsAfter: string[] | null;
  savedMaterials: { type: string, name: string, description: string, visibility: string, url_to_file: string }[];
  notes: string | null;
  lessonName: string | null;
  lessonGoal: string | null;
  lessonCurriculums: string[] | null;
  materialVisibility: string;
  lessonRating: string | null | undefined;
  rateLessonMaterials: ((rating: string) => void) | undefined;
}

const initialContext: LessonContextProps = {
  activities: [],
  activitiesBefore: [],
  activitiesAfter: [],
  lengthOfLesson: 0,
  gadgetsBefore: null,
  gadgets: null,
  gadgetsAfter: null,
  savedMaterials: [],
  notes: null,
  lessonName: null,
  lessonGoal: null,
  lessonCurriculums: [],
  materialVisibility: "student",
  lessonRating: null,
  rateLessonMaterials: undefined
}

export const LessonContext = createContext<LessonContextProps>(initialContext);

export const LessonProvider = ({ children }: { children: ReactNode }) => {
  const [activities, setActivities] = useState<iLessonActivityItem[]>([]);
  const [activitiesBefore, setActivitiesBefore] = useState<iActivity[]>([]);
  const [activitiesAfter, setActivitiesAfter] = useState<iActivity[]>([]);
  const [lengthOfLesson, setLengthOfLesson] = useState<number>(0);
  const [gadgetsBefore, setGadgetsBefore] = useState<string[] | null>([]);
  const [gadgets, setGadgets] = useState<string[] | null>([]);
  const [gadgetsAfter, setGadgetsAfter] = useState<string[] | null>([]);
  const [savedMaterials, setSavedMaterials] = useState<{ type: string, name: string, description: string, visibility: string, url_to_file: string }[]>([]);
  const [notes, setNotes] = useState<string>("");
  const [lessonName, setLessonName] = useState<string>("");
  const [lessonGoal, setLessonGoal] = useState<string>("");
  const [lessonCurriculums, setLessonCurriculums] = useState<string[]>([]);
  const [materialVisibility, setMaterialVisibility] = useState<string>("student");
  const [lessonRating, setLessonRating] = useState<string>();

  const lessonId = useSelector((state: RootState) => state.editor.lessonId);
  const teachersId = useSelector((state: RootState) => state.editor.teachersId);

  useEffect(() => {
    const loadData = async () => {
      const { data: { length_in_mins, activities_list, activities_before, activities_after, notes, name, goal, curriculums } } = await axios.get("/api/lesson/" + lessonId);
      setActivities(activities_list);
      setActivitiesBefore(activities_before);
      setActivitiesAfter(activities_after);
      setLengthOfLesson(length_in_mins);
      setGadgetsBefore(getGadgets(activities_before));
      setGadgetsAfter(getGadgets(activities_after));
      setLessonName(name)
      setLessonGoal(goal)
      setLessonCurriculums(curriculums)
      setNotes(notes)

      const { data: { message } } = await axios.get("api/student/material-visibility/" + teachersId + "/" + lessonId);
      setMaterialVisibility(message)

      const gadgetsDuring: string[] = [];
      activities_list.forEach((activityList: any) => {
        const gadgets = getGadgets(activityList.activities);
        if (gadgets !== null) {
          gadgets.forEach((gadget: string) => {
            if (!gadgetsDuring.includes(gadget) && gadget !== "") gadgetsDuring.push(gadget);
          });
        }
      });
      setGadgets(gadgetsDuring);

      updateSavedMaterials(activities_list, activities_before, activities_after, message);
    }

    loadData();
    getSetLessonMaterialsRating();
  }, []);

  const getGadgets = (list: any) => {
    const arr: string[] = [];
    list.forEach((activity: any) =>
      activity.gadgets?.forEach((gadget: string) => {
        if (!arr.includes(gadget) && gadget !== "") arr.push(gadget);
      })
    );
    return arr.length > 0 ? arr : null;
  };

  const updateSavedMaterials = (activities_list: iLessonActivityItem[], activities_before: iActivity[], activities_after: iActivity[], visibility: string) => {
    const newSavedMaterials: { type: string, name: string, description: string, visibility: string, url_to_file: string }[] = [];

    const mapActivities = (activities: any[]) => {
      activities.map((activity) => {
        newSavedMaterials.push(...getActivityFilesAndUrls(activity, visibility));
      });
    }

    mapActivities(activities_before);

    activities_list.map((row: any) => {
      mapActivities(row.activities);
    });

    mapActivities(activities_after);

    setSavedMaterials(newSavedMaterials);
  };

  // Retrieves the names of files and URLs from the given activity object
  const getActivityFilesAndUrls = (activity: any, visibility: string) => {
    const filesAndUrls: { type: string, name: string, description: string, visibility: string, url_to_file: string }[] = []

    const pushItems = (items: any[], type: string) => {
      items?.map((item: { name: string, description: string, visibility: string, url_to_file: string }) => {
        if (item.name && item.name !== "" && item.visibility !== "teacher")
          if ((item.visibility === "after-lesson" && visibility === "after-lesson") || item.visibility === "student") {
            filesAndUrls.push({
              type,
              name: item.name,
              description: item.description,
              visibility: item.visibility,
              url_to_file: item.url_to_file
            });
          }
      });
    };

    pushItems(activity.files, "file");
    pushItems(activity.urls, "url");

    return filesAndUrls;
  }

  const rateLessonMaterials = async (rating: string) => {
    try {
      const response = await axios.post(`/api/lesson/${lessonId}/rate`, {
        rating: rating
      });

      if (response.status === 200) {
        setLessonRating(rating);
      } else {
        alert("Nepodařilo se uložit hodnocení");
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        alert("Nelze hodnotit znovu nebo hodnocení není povoleno");
      } else {
        alert("Nepodařilo se uložit hodnocení");
      }
    }
  };

  const getSetLessonMaterialsRating = async () => {
    try {
      const response = await axios.get(`/api/student/lesson/${lessonId}/rating`);

      if (response.status === 200) {
        setLessonRating(response.data.rating);
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setLessonRating("");
      } else {
        throw error;
      }
    }
  };

  return (
    <LessonContext.Provider
      value={{
        activities,
        activitiesBefore,
        activitiesAfter,
        lengthOfLesson,
        gadgetsBefore,
        gadgets,
        gadgetsAfter,
        savedMaterials,
        notes,
        lessonName,
        lessonGoal,
        lessonCurriculums,
        materialVisibility,
        lessonRating,
        rateLessonMaterials
      }}
    >
      {children}
    </LessonContext.Provider>
  )
}

export const useLessonContext = () => useContext(LessonContext);
