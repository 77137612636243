import { iLessonActivityItem } from '../interfaces/lesson-activity-interface';
import { v4 as uuidv4 } from 'uuid';

export const startIndexOfMainActivities = 3;
// calculate how long are activities on timeline
export const calculateSumOfRowsMins = (activitiesList: iLessonActivityItem[], startIndexOfMainActivities: number) => {
  let sum = 0;
  for (let i = startIndexOfMainActivities; i < activitiesList.length; i++) { // sum of used minutes
    if(activitiesList[i].activities.length !== 0){
      let max = 0;
      for (let j = 0; j < activitiesList[i].activities.length; j++) {
        max = max > activitiesList[i].activities[j].size ? max : activitiesList[i].activities[j].size;
      }
      sum += max;
    }
  }
  return sum
}

// allow only grouped activities, allow adding activities only to the end - restriction by the lengthInMin
export const normalizeActivites = (activitiesList: iLessonActivityItem[], lengthInMin: number) => {
  const sum = calculateSumOfRowsMins(activitiesList, startIndexOfMainActivities);
  let index = activitiesList.length;
  while (index >= startIndexOfMainActivities) {
    if(activitiesList[index]?.activities.length === 0){
      activitiesList.splice(index, 1);
    }
    index--;
  }
  if( sum < lengthInMin ){
    activitiesList.push({
      _id: uuidv4(), 
      activities: []
    })
  }
  return activitiesList;
}

export const getMovedActivities = (activitiesCopy: any, destination: any, source: any, lengthInMins: any, activityTypeId: any) => {
  const srcIndex = activitiesCopy.findIndex((obj: { _id: any; }) => obj._id === source.droppableId);
  const destIndex = activitiesCopy.findIndex((obj: { _id: any; }) => obj._id === destination.droppableId);

  if (destination.droppableId === 'Lesson') { // Row moving
    const [removed] = activitiesCopy.splice(source.index + startIndexOfMainActivities, 1); 
    activitiesCopy.splice(destination.index + startIndexOfMainActivities, 0, removed); 
  } else if(source.droppableId === activityTypeId && destination.droppableId !== activityTypeId) { // New activity 
    const maxSize = Math.max(...activitiesCopy[destIndex].activities.map((o: { size: any; }) => o.size));
    const copy = JSON.parse(JSON.stringify(activitiesCopy[srcIndex].activities[source.index])); // Deep copy item from old row and stores it
    if(maxSize > 0){
      copy.size = maxSize
    }
    copy._id = uuidv4();
    activitiesCopy[destIndex].activities.splice(destination.index, 0, copy); // Inserts the dragged item into new row
  } else if (source.droppableId !== activityTypeId && destination.droppableId === activityTypeId) { //  Deletes item dropped into menu from lesson window
    activitiesCopy[srcIndex].activities.splice(source.index, 1); //
  } else {
    const maxSize = Math.max(...activitiesCopy[destIndex].activities.map((o: { size: any; }) => o.size));
    const [removed] = activitiesCopy[srcIndex].activities.splice(source.index, 1); // Removes dragged item from old row and stores it
    if(maxSize > 0){
      removed.size = maxSize
    }
    activitiesCopy[destIndex].activities.splice(destination.index, 0, removed); // Inserts the dragged item into new row
  }
  activitiesCopy = normalizeActivites(activitiesCopy, lengthInMins)

  return activitiesCopy
}

export const newActivityType = () => {
  return {
    _id: uuidv4(),
    name: "Typ aktivit",
    description: "popis typu aktivity",
    color: "#696969",
    activities: [
      {
        _id: uuidv4(),
        name: "Aktivita 1",
        size: 5,
        color: "#696969",
        description: "popis aktivity",
        icons: ["name"],
        files: ["soubor1", "sobor2"],
        url: ["url1", "url2"],
        gadgets: ["none"]
      }
    ]
  }
}