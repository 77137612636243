import './InfoWindows.scss';

const UpdateNoteWindow = () => {

  return (
    <section className='update-notes-window'>
      <h1>Archiv aktualit</h1>
      <h2>Verze 1.0.0</h2>
      <p>
        Finální verze aplikace EDUBO
      </p>
    </section>
  )
}

export default UpdateNoteWindow;