import React, { useEffect, useState } from 'react';
import axios from "utils/axios";
import './LessonHeader.scss';
import { Badge } from 'react-bootstrap';
import { Tooltip } from 'flowbite-react';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {setLessonName, setGoal, setCurriculums, updateLessonCurriculums} from '../../../../../redux/editorSlice';
import { KeyboardShortcuts } from '../../../../../utils/KeyboardShortcuts';

const LessonHeader = () => {
  const dispatch = useDispatch()
  const lessonId = useSelector((state: RootState) => state.editor.lessonId)
  const subjectId = useSelector((state: RootState) => state.editor.subjectId)
  const [assignedCurriculums, setAssignedCurriculums] = useState<string[]>([])
  const [selectedCurriculums, setSelectedCurriculums] = useState<string[]>([])
  const [lesson, setLesson] = useState<any>({})
  const [showModal, setShowModal] = useState(false)
  const lessonName: string = useSelector((state: RootState) => state.editor.lessonName,)
  const lessonNotes: string = useSelector((state: RootState) => state.editor.notes,)
  const lessonGoal: string = useSelector((state: RootState) => state.editor.goal,)
  const lessonCurriculums: string[] = useSelector((state: RootState) => state.editor.curriculums,)

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const response = await axios.get(`/api/lesson/${lessonId}`)
        setLesson(response.data)
        dispatch(setCurriculums(response.data.curriculums))
      } catch (error) {
        console.error('Error fetching lesson:', error)
      }
    }
    fetchLesson()
  }, [lessonId])

  useEffect(() => {
    setLesson({
      ...lesson,
      name: lessonName,
      notes: lessonNotes,
      goal: lessonGoal,
      curriculums: lessonCurriculums,
    })
  }, [lessonName, lessonNotes, lessonGoal, lessonCurriculums])

  useEffect(() => {
    fetchAssignedCurriculums()
  }, [lessonCurriculums])

  const fetchAssignedCurriculums = async () => {
    try {
      const response = await axios.get(`/api/curriculum/assign/${subjectId}`);
      const combinedCurriculums: string[] = lessonCurriculums !== null &&  lessonCurriculums.length > 0 ? Array.from(new Set([...response.data, ...lessonCurriculums])) : [...response.data];
      setAssignedCurriculums(Array.from(new Set([...combinedCurriculums])));
    } catch (error) {
      console.error('Error fetching assigned curriculums:', error)
    }
  }

  const toggleModalTag = (curriculum: string) => {
    if (selectedCurriculums.includes(curriculum)) {
      setSelectedCurriculums((prev) => prev.filter((c) => c !== curriculum))
    } else {
      setSelectedCurriculums((prev) => [...prev, curriculum])
    }
  }

  const handleSave = async () => {
    try {
      const updatedLesson = {
        ...lesson,
        curriculums: selectedCurriculums,
      }
      setLesson(updatedLesson)
      dispatch(updateLessonCurriculums(selectedCurriculums))
    } catch (error) {
      console.error('Error saving lesson:', error)
    }
  }

  const handleLessonNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 63) return alert("Název hodiny nesmí být delší než 63 znaků!");
    dispatch(setLessonName(e.target.value));
  }

  const handleGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 511) return alert("Cíl nesmí být delší než 511 znaků!");
    dispatch(setGoal(e.target.value));
  }

  return (
    <div className="lesson-header w-full">
      <input
        type="text"
        className="text-2xl border-none focus:ring-0 focus:outline-none lesson-name-input w-full"
        value={lessonName}
        maxLength={63}
        onChange={handleLessonNameChange}
        placeholder="Název hodiny"
      />
      <div className="goal-container w-full">
        <span className="lesson-goal">Cíl: </span>
        <input
          type="text"
          className="border-none focus:ring-0 focus:outline-none lesson-goal-input w-full"
          value={lessonGoal}
          maxLength={511}
          onChange={handleGoalChange}
          placeholder="Něco žáka naučit"
        />
      </div>

      <div className="assigned-curriculums">
        <div className="outputs">
          <span
            onClick={() => {
              setShowModal(true)
              setSelectedCurriculums(lessonCurriculums)
            }}
          >
            <span className="plus-sign">
              {showModal ? (
                <FontAwesomeIcon className={`minus`} icon={faMinus} />
              ) : (
                <FontAwesomeIcon className={`plus`} icon={faPlus} />
              )}
            </span>

            Výstupy:{' '}
            
          </span>
          {lessonCurriculums
            ? lessonCurriculums.map((curriculum: string, index: number) => (
              <Tooltip key={index} id="curriculum-tag" content={curriculum}>
                <Badge>{curriculum}</Badge>
              </Tooltip>
              ))
            : ""}
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <KeyboardShortcuts context="lessonHeaderModal"></KeyboardShortcuts>
          <div
            className="modal-content lesson-header-modal"
            style={{ maxWidth: 'calc(100vw - 100px)', minWidth: 'auto' }}
          >
            <div className="goals-header">
              <h1>Vyberte výstupy hodiny</h1>
            </div>
            <div className="goals">
              {assignedCurriculums.length >= 1 ? assignedCurriculums.map((curriculum: string, index: number) => (
                <div key={index} className="curriculum-item">
                  <input
                    type="checkbox"
                    id={`curriculum-${index}`}
                    checked={selectedCurriculums.includes(curriculum)}
                    onChange={() => toggleModalTag(curriculum)}
                  />
                  <label htmlFor={`curriculum-${index}`}>{curriculum}</label>
                </div>
              )) : "Nemáte přidělené žádné výstupy k předmětu! Přidělte je při úpravě předmětu v menu."}
            </div>
            <div className="buttons">
              <button
                id="closeButtonLessonHeader"
                className="cancel-button"
                onClick={() => setShowModal(false)}
              >
                Zavřít
              </button>
              <button
                id='saveButtonLessonHeader'
                className="save-button"
                onClick={() => {
                  setShowModal(false)
                  handleSave()
                }}
              >
                Uložit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LessonHeader

