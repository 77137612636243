import RegistrationWindow from '../components/registration/RegistrationWindow';
import Carousel from '../components/login/Carousel';
import './Registration.scss';
import MobileRegistration from 'components/registration/MobileRegistration';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import { useWindowSize } from 'utils/useWindowSize';

function Registration() {
  const windowSize = useWindowSize();

  return (
    <section className="registration">
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <RegistrationWindow />
          </>
        }
        mobile={
          <MobileRegistration />
        }
      />
    </section>
  )
}

export default Registration