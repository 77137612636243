import { useModal } from 'contexts/ModalProvider';
import { ReactComponent as Url } from "images/student-lesson-menu-icons/url.svg";
import { ReactComponent as File } from "images/student-lesson-menu-icons/file.svg";
import { ReactComponent as Redirect } from "images/student-lesson-menu-icons/redirect.svg";
import { useLessonContext } from '../LessonContext';
import { ReactComponent as Unused } from "images/student-lesson-menu-icons/unused.svg";
import { ReactComponent as Downvote } from "images/student-lesson-menu-icons/downvote.svg";
import { ReactComponent as Neutral } from "images/student-lesson-menu-icons/neutral.svg";
import { ReactComponent as Upvote } from "images/student-lesson-menu-icons/upvote.svg";
import MaterialDescriptionModal from 'components/student/material-description-modal/MaterialDescriptionModal';
import "./SavedMaterials.scss"
import { useSelector } from 'react-redux';

const VoteButtons = [
  { component: Upvote, key: 'upvote' },
  { component: Neutral, key: 'neutral' },
  { component: Downvote, key: 'downvote' },
  { component: Unused, key: 'unused' },
];

const SavedMaterials = () => {
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const { savedMaterials, lessonRating, rateLessonMaterials } = useLessonContext();
  const { showModal } = useModal();

  return (
    <div className="overflow-y-auto max-h-[75vh] flex flex-col">
      <div className="overflow-y-auto overflow-x-hidden h-full scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300">
        {userInfo.role === 'student' && (
          <div className="flex space-x-2 items-center justify-center pb-2">
            <span className="text-sm">Hodnocení materiálů:</span>
            {VoteButtons.map(({ component: SVG, key }) => (
              <button
              onClick={() => {
                if (rateLessonMaterials !== undefined) rateLessonMaterials(key)
              }}
              disabled={lessonRating === key}
              key={key}
              >
                <SVG key={key} 
                className={`w-6 h-6 p-1 border border-black rounded ${lessonRating === key ? 'selected' : ''}`}
                />
              </button>
            ))}
          </div>
        )}
        {savedMaterials?.map((material: { type: string, name: string, description: string, visibility: string, url_to_file: string }, index: number) => {
          return (
            <div className="flex space-x-1" key={index}>
              {material.type === "file"
                ? <>
                  <File className="w-7 h-7 p-1 border border-black rounded text-black" />
                  <div
                    className="flex justify-between items-center w-full h-7 p-1 mb-1 bg-[#8007AA26] rounded overflow-hidden cursor-pointer"
                    onClick={() => showModal(<MaterialDescriptionModal description={material.description} url_to_file={material.url_to_file} />)}
                  >
                    <div className='truncate'>{material.name}</div>
                  </div>
                </>
                : <>
                  <Url className="w-7 h-7 p-1 border border-black rounded text-black" />
                  <div
                    className="flex justify-between items-center w-full h-7 p-1 mb-1 bg-[#8007AA26] rounded overflow-hidden cursor-pointer"
                    onClick={() => showModal(<MaterialDescriptionModal description={material.description} />)}
                  >
                    <div className="truncate flex-grow">{material.name}</div>
                    <div className="flex-none">
                      <a
                        href={material.name.startsWith('http') ? material.name : `https://${material.name}`}
                        target="_blank"
                        rel="noreferrer"
                        className="w-6 h-6 border border-black rounded flex items-center justify-center"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <Redirect className="w-4 h-4" />
                      </a>
                    </div>
                  </div>
                </>
              }
            </div>
          )
        }
        )}
      </div>
    </div>
  )
}
export default SavedMaterials;
