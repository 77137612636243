import './InfoWindows.scss';

const ContactsWindow = () => {

  return (
    <section className='contacts-window'>
      <h1>Kontakty</h1>
      <p>
        Pokud máte dotazy, připomínky nebo byste se chtěli dozvědět více o tom, jak může EDUBO a Eduklub 
        pomoci ve vaší výuce, neváhejte nás kontaktovat.
      </p>
      <h2>Kontaktní Email:</h2>
      <ul>
        <li>Pro obecné dotazy: podpora@eduklub.cz</li>
        <li>Pro technickou podporu: podpora@eduklub.cz</li>
        <li>Pro obchodní dotazy: podpora@eduklub.cz</li>
      </ul>
      <p>
        Pro více informací nás můžete také navštívit naše webové stránky.
      </p>
    </section>
  )
}

export default ContactsWindow;