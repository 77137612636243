import '../../editor/lesson-window/lesson/accessories/LessonHeader.scss'
import { Badge } from 'react-bootstrap';
import { Tooltip } from 'flowbite-react';
import { PropsWithChildren } from 'react';

interface LessonHeaderStudentProps extends PropsWithChildren<any> {
  lessonName: string | null,
  lessonGoal: string | null,
  lessonCurriculums: string[] | null,
}

const LessonHeaderStudent = ({lessonName, lessonGoal, lessonCurriculums}: LessonHeaderStudentProps) => {

  return (
    <div className="lesson-header space-y-2">
      <h2 className="text-2xl border-none text-black font-medium">
        {lessonName}
      </h2>
      {lessonGoal ? 
        <div className="goal-container">
          <span className="lesson-goal">Cíl: </span>
          <span className="border-none">
            {lessonGoal}
          </span>
        </div>
        : null
      }
      {lessonCurriculums && lessonCurriculums.length > 0 ? 
          <div className="assigned-curriculums">
            <div className="outputs">
              <span>Výstupy:{' '}</span>
              {lessonCurriculums
                ? lessonCurriculums.map((curriculum: string, index: number) => (
                  <Tooltip key={index} id="curriculum-tag" content={curriculum}>
                    <Badge>{curriculum}</Badge>
                  </Tooltip>
                ))
                : ''}
            </div>
          </div>
          : null
      }
    </div>
  )
}

export default LessonHeaderStudent
