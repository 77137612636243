import Carousel from '../components/login/Carousel';
import './Login.scss';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import PasswordResetNewPasswordWindow from 'components/passwordReset/PasswordResetNewPasswordWindow';
import MobilePasswordResetNewPassword from 'components/passwordReset/MobilePasswordResetNewPassword';
import { useWindowSize } from 'utils/useWindowSize';

function PasswordResetNewPassword() {
  const windowSize = useWindowSize();

  return (
    <section className="passwordReset">
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <PasswordResetNewPasswordWindow />
          </>
        }
        mobile={
          <MobilePasswordResetNewPassword />
        }
      />
    </section>
  );
}

export default PasswordResetNewPassword;