import './SideBar.scss';
import { ReactComponent as SmileIcon } from '../../images/smile.svg';
import { ReactComponent as ArrowIcon } from '../../images/arrow.svg';
import { Link, To, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Logout } from 'components/nav/logout/Logout';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { isMacSafariFirefox } from 'utils/browserUtils';
import axios from 'utils/axios';
import { parentKey } from 'interfaces/student-user-data-interface';

const SideBarContent = ({ currentPath, onClose }: { currentPath: string, onClose: () => void }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownParentsOpen, setDropdownParentsOpen] = useState(false);
  const viewOption = currentPath === "/calendar" ? "předměty" : "kalendář";
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const studentsClass = useSelector((state: RootState) => state.editor.class)
  const username = userInfo.email;
  const menu = useSelector((state: RootState) => state.editor.menu);
  const teachersId= useSelector((state: RootState) => state.editor.teachersId);
  const lessonId = useSelector((state: RootState) => state.editor.lessonId);
  const [parentKeys, setParentKeys] = useState<parentKey[]>([]);
  const navigate = useNavigate();
  const navigateTo = (path: To) => {
    navigate(path);
    onClose();
  };

  useEffect(() => {
    if (!isDropdownParentsOpen) return;
    getParentKeys();
  }, [isDropdownParentsOpen])

  const openPDFExport = () => {
    const url = `/pdf/student/${teachersId}/${lessonId}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          throw new Error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  }

  const getParentKeys = async () => {
    const response = await axios.get('api/current-student/parent-keys');
    if (response.status !== 200) {
      console.error("Couldn't get parent keys");
      return;
    }
    if (!response.data.keys || response.data.keys.length < 1) {
      console.error("No parent keys");
      return;
    }
    setParentKeys(response.data.keys);
  }

  switch(true) {
    case /^\/calendar/.test(currentPath):
    case /^\/subjects/.test(currentPath):
      return (
        <div className="sidebar-content">
            <button className="flex items-center close-sidebar" onClick={onClose}>
                <ArrowIcon />
            </button>
            <div className="icon-container">
                <SmileIcon className="smile-icon" />
                <div className="username-class-container">
                    <p>{username}</p>
                    <p>{studentsClass}</p>
                </div>
            </div>
            <div className="logout-container">
              <Logout />
            </div>
            <div className="view-container">
            <button className="view-button" onClick={() => navigateTo(currentPath === "/calendar" ? "/subjects" : "/calendar")}>
              <ArrowIcon />
              Přepnout pohled ({viewOption})
            </button>
            {userInfo.role === 'student' && (
              <div className='parental-access-dropdown'>
                <button className={`view-button ${isDropdownParentsOpen ? 'open' : ''}`} onClick={() => setDropdownParentsOpen(!isDropdownParentsOpen)}>
                  <ArrowIcon />
                  Rodičovský přístup
                </button>
                {isDropdownParentsOpen && (
                  <div className="dropdown-content">
                    {parentKeys.map((key, index) => (
                      <div className='key' key={index}>
                        <p>{key.key}</p>
                        {key.used && <span className="used">(použitý)</span>

                        }
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="refs">
            <Link to={"/about"}>O nás</Link>
            <Link to={"/notes"}>Archiv aktualit</Link>
            <Link to={"/contacts"}>Kontakty</Link>
            <Link to={"/gdpr"}>GDPR</Link>
          </div>
        </div>
      );

    case /^\/view-selection/.test(currentPath):
      return (
        <div className="sidebar-content">
          <button className="flex items-center close-sidebar" onClick={onClose}>
            <ArrowIcon />
          </button>
          <div className="icon-container">
            <SmileIcon className="smile-icon" />
            <div className="username-class-container">
                <p>{username}</p>
                <p>{studentsClass}</p>
            </div>
          </div>
          <div className="logout-container">
            <Logout />
          </div>
          {userInfo.role === 'student' && (
            <div className='parental-access-dropdown'>
              <button className={`view-button ${isDropdownParentsOpen ? 'open' : ''}`} onClick={() => setDropdownParentsOpen(!isDropdownParentsOpen)}>
                <ArrowIcon />
                Rodičovský přístup
              </button>
              {isDropdownParentsOpen && (
                <div className="dropdown-content">
                  {parentKeys.map((key, index) => (
                    <div className='key' key={index}>
                      <p>{key.key}</p>
                      {key.used && <span className="used">(použitý)</span>

                      }
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="refs">
            <Link to={"/about"}>O nás</Link>
            <Link to={"/notes"}>Archiv aktualit</Link>
            <Link to={"/contacts"}>Kontakty</Link>
            <Link to={"/gdpr"}>GDPR</Link>
          </div>
        </div>
      );

    case /^\/lesson-view/.test(currentPath):
      return (
        <div className="sidebar-content">
          <button className="flex items-center close-sidebar" onClick={onClose}>
            <ArrowIcon />
          </button>
          <div className="icon-container">
            <SmileIcon className="smile-icon" />
            <div className="username-class-container">
              <p>{username}</p>
              <p>{studentsClass}</p>
            </div>
          </div>
          <div className="logout-container">
            <Logout />
          </div>
          <div className="view-container">
            <button className={`view-button ${dropdownOpen ? 'open' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
              <ArrowIcon />
              Soubor
            </button>
            {dropdownOpen && (
              <div className={`dropdown ${dropdownOpen ? 'visible' : 'hidden'}`}>
                <div className="view-option">
                  <p onClick={openPDFExport}>Exportovat do PDF</p>
                </div>
                <div className="view-option">
                  <p onClick={() => navigateTo(menu === 'calendar' ? '/calendar' : '/subjects')}>Zpět do menu</p>
                </div>
              </div>
            )}
            {userInfo.role === 'student' && (
              <div className='parental-access-dropdown'>
                <button className={`view-button ${isDropdownParentsOpen ? 'open' : ''}`} onClick={() => setDropdownParentsOpen(!isDropdownParentsOpen)}>
                  <ArrowIcon />
                  Rodičovský přístup
                </button>
                {isDropdownParentsOpen && (
                  <div className="dropdown-content">
                    {parentKeys.map((key, index) => (
                      <div className='key' key={index}>
                        <p>{key.key}</p>
                        {key.used && <span className="used">(použitý)</span>

                        }
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="refs">
            <Link to={"/about"}>O nás</Link>
            <Link to={"/notes"}>Archiv aktualit</Link>
            <Link to={"/contacts"}>Kontakty</Link>
            <Link to={"/gdpr"}>GDPR</Link>
          </div>
        </div>
      );

    case /^\/about/.test(currentPath):
    case /^\/contacts/.test(currentPath):
    case /^\/gdpr/.test(currentPath):
    case /^\/notes/.test(currentPath):
      return (
        <div className="sidebar-content">
          <button className="flex items-center close-sidebar" onClick={onClose}>
            <ArrowIcon />
          </button>
          {userInfo.role === 'student' && (
            <>
              <div className="icon-container">
                <SmileIcon className="smile-icon" />
                <div className="username-class-container">
                    <p>{username}</p>
                    <p>{studentsClass}</p>
                </div>
              </div>
              <div className="logout-container">
                <Logout />
              </div>
              <button className="view-button" onClick={() => navigateTo("/view-selection")}>
                Výběr pohledů
              </button>
                <div className='parental-access-dropdown'>
                  <button className={`view-button ${isDropdownParentsOpen ? 'open' : ''}`} onClick={() => setDropdownParentsOpen(!isDropdownParentsOpen)}>
                    <ArrowIcon />
                    Rodičovský přístup
                  </button>
                  {isDropdownParentsOpen && (
                    <div className="dropdown-content">
                      {parentKeys.map((key, index) => (
                        <div className='key' key={index}>
                          <p>{key.key}</p>
                          {key.used && <span className="used">(použitý)</span>

                          }
                        </div>
                      ))}
                    </div>
                  )}
                </div>
            </>
          )}
          <div className="refs">
            {!/^\/about/.test(currentPath) && <Link to={"/about"}>O nás</Link>}
            {!/^\/notes/.test(currentPath) && <Link to={"/notes"}>Archiv aktualit</Link>}
            {!/^\/contacts/.test(currentPath) && <Link to={"/contacts"}>Kontakty</Link>}
            {!/^\/gdpr/.test(currentPath) && <Link to={"/gdpr"}>GDPR</Link>}
          </div>
        </div>
      );

    case /^\//.test(currentPath):
    case /^\/registration/.test(currentPath):
    case /^\/changepassword/.test(currentPath):
    case /^\/newpassword/.test(currentPath):
      return (
        <div className="sidebar-content">
          <button className="flex items-center close-sidebar" onClick={onClose}>
            <ArrowIcon />
          </button>
          <div className="refs">
            {!/^\/about/.test(currentPath) && <Link to={"/about"}>O nás</Link>}
            {!/^\/notes/.test(currentPath) && <Link to={"/notes"}>Archiv aktualit</Link>}
            {!/^\/contacts/.test(currentPath) && <Link to={"/contacts"}>Kontakty</Link>}
            {!/^\/gdpr/.test(currentPath) && <Link to={"/gdpr"}>GDPR</Link>}
          </div>
        </div>
      );


    default:
      return null;
  }
}

const SideBar = ({ currentPath, open, onClose }: { currentPath: string, open: boolean, onClose: () => void }) => {
  return (
    <div className={`sidebar ${!open ? "sidebar-closed" : ""}`}>
      <SideBarContent currentPath={currentPath} onClose={onClose} />
    </div>
  );
}


export default SideBar;