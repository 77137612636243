import { useNavigate } from "react-router-dom";
import AppBar from "components/mobile-utils/AppBar";
import calendarSvg from "./calendar.svg";
import subjectsSvg from "./subjects.svg";

function MobileViewSelection() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <AppBar />
      <div className="p-3 border-b border-black border-dashed font-semibold">
        Vyberte pohled
      </div>
      <div className="p-8 overflow-auto flex flex-col items-center space-y-4">
        <img src={calendarSvg} alt="Kalendář" className="w-full" onClick={() => navigate('/calendar')} />
        <img src={subjectsSvg} alt="Předměty" className="w-full" onClick={() => navigate('/subjects')} />
      </div>
    </div>
  );
}

export default MobileViewSelection;
