import './MobileCalendarLessons.scss'
// SVGs
import { ReactComponent as SmileFace } from "images/student-lesson-menu-icons/smile-face.svg";
import { ReactComponent as Pending } from "../../../images/student-lesson-menu-icons/pending.svg";
import { ReactComponent as Ongoing } from "../../../images/student-lesson-menu-icons/ongoing.svg";
import { ReactComponent as Done } from "../../../images/student-lesson-menu-icons/done.svg";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLessonId, setTeachersId } from 'redux/editorSlice';

const MobileCalendarLessons = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const handleOpenButton = (index: number) => {
    if (!props.lessonDatas) return;
    dispatch(setLessonId(props.lessonDatas[index]._id));
    dispatch(setTeachersId(props.lessonDatas[index].teachers_data_id));
    navigate('/lesson-view');
  }

  return (
    <div className={`mobile-calendar-lesson-menu-lessons ${props.lessonDatas && props.lessonDatas.length > 0 ? "" : 'justify-center'}`}>
      {props.lessonDatas.length > 0 ? props.lessonDatas.map((lesson: { name: string, subject: string; teachingUnit: string, teacher: string, time: string}, index: number) => {

          return (
            <div key={index} onClick={() => handleOpenButton(index)} className="mobile-calendar-lesson-menu-lesson">
              <div className='flex-row flex'>
                <span className="subject">{lesson.subject}</span>
                {props.lessonProgress && props.lessonProgress.length === props.lessonProgress.length &&
                  props.lessonProgress[index] === "pending"
                    ? <Pending className="student-status-icon-mobile" />
                    : props.lessonProgress[index] === "ongoing"
                      ? <Ongoing className="student-status-icon-mobile" />
                      : props.lessonProgress[index] === "done"
                        ? <Done className="student-status-icon-mobile" />
                        : null}
              </div>
              <span className="time-and-teacher">
                {lesson.time}, {lesson.teacher}
              </span>
              <span className="teaching-unit">{lesson.teachingUnit}</span>
              <span className="lesson-name">{lesson.name}</span>
            </div>
          );
        })
        : <div className='no-lessons'>
            <SmileFace className='no-lessons-smile-face'/>
            <span className='no-lessons-text'>Nic zde není</span>
          </div>
      }
    </div>
  );
}
export default MobileCalendarLessons;