import { useDropzone } from 'react-dropzone';

interface FileUploadAreaProps {
  onFileUpload: (acceptedFiles: File[]) => void;
  file: string | null;
}

const FileUploadArea = ({ onFileUpload, file }: FileUploadAreaProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileUpload,
    maxFiles: 1
  });

  return (
    <div {...getRootProps()} className="dropzone align-middle leading-[19px] py-2 pl-3 pr-20 truncate cursor-pointer">
      <input {...getInputProps()} />
      {file ? (
        file
      ) : (
        "Sem přetáhněte soubor nebo klikněte pro výběr"
      )}
    </div>
  );
};

export default FileUploadArea;