import './StudentCalendarMenu.scss'
import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import axios from 'utils/axios';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import CalendarMenuWindow from 'components/student/calendar-menu/CalendarMenuWindow';
import MobileCalendarMenu from 'components/student/calendar-menu/MobileCalendarMenu';
import { Calendar, CalendarData } from 'interfaces/student-user-data-interface';
import _ from 'lodash'
import { setMenu } from 'redux/editorSlice';
import { useMobileQuery } from 'utils/useMediaQuery';
import dayjs from 'dayjs';

const StudentCalendarMenu = () => {
  const classId = useSelector((state: RootState) => state.auth.userInfo.class_id);
  const dispatch = useDispatch()
  const mobileViewport = useMobileQuery();

  // Lesson variables
  const [lessonNames, setLessonNames] = useState<string[]>([]);
  const [selectionLessons, setSelectionLessons] = useState<string[]>([]);
  const [activeLesson, setActiveLesson] = useState<string>();
  const [activeLessonIndex, setActiveLessonIndex] = useState<number>(0);
  const [lessonRating, setLessonRating] = useState<string>();

  // Calendar variables
  const [calendarData, setCalendarData] = useState<Calendar>();
  const [calendarDayData, setCalendarDayData] = useState<CalendarData[]>();
  const [lessonProgress, setLessonProgress] = useState<string[]>()
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  // Other variables
  const [lessonData, setLessonData] = useState<{ _id: string, subject: string, teachingUnit: string, teacher: string, teachers_data_id: string, times: string[]}>();
  const [savedMaterials, setSavedMaterials] = useState<{ name: string, type: string, url: string, description: string}[]>();
  const [lessons, setLessons] = useState<[{ key: string; value: string }] | []>([]);

  // Obtain calendar data when the page loads and obtain lessons for current date
  useEffect(() => {
    obtainCalendarData()
    dispatch(setMenu("calendar"))
  }, [])

  useEffect(() => {
    obtainLessons()
  }, [calendarData])

  useEffect(() => {
    setLastDate(selectedDate)
    if (selectedDate.getFullYear() === lastDate.getFullYear() && selectedDate.getMonth() === lastDate.getMonth()) obtainLessons();
    else obtainCalendarData()
  }, [selectedDate])

  useLayoutEffect(() => {
    getLessonProgress()
  }, [calendarDayData])

  useEffect(() => {
    // Skip for mobile
    if (mobileViewport || !activeLesson) {
      return;
    }
    getSetLessonMaterialsRating();
  }, [activeLesson])

  // Change lesson selection when another month in the calendar is clicked
  const obtainCalendarData = async () => {
    const formattedDate = selectedDate.toLocaleDateString('cs-CZ', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/\s/g, '');
    const calendarDataResponse = await axios.get("/api/student/calendar/" + classId + "/" + formattedDate)
    setCalendarData(calendarDataResponse.data.calendar)
    setLessons([])
    setLessonData(undefined)
    setLessonNames([])
    setSavedMaterials([])
    setActiveLesson(undefined)
  }

  // Sets lesson names and lessons list based on day
  const obtainLessons = () => {
    if (!calendarData) return
    const date: number = dayjs(Object.keys(calendarData)[0], 'D.M.YYYY', true).month();
    if (selectedDate.getMonth() !== date) obtainCalendarData();
    const calendarCopy: Calendar = _.cloneDeep(calendarData)
    const newLessons: [{ key: string; value: string }] = [{ key: '', value: '' }]
    const formattedDate = selectedDate.toLocaleDateString('cs-CZ', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/\s/g, '');
    for (const date in calendarCopy) {
      if (date === formattedDate) {
        const orderedLessons = [...calendarCopy[date]].sort((a, b) => {
          const aMinTsFrom = Math.min(...a.days_of_usage.map(day => new Date(day.ts_from).getTime()));
          const bMinTsFrom = Math.min(...b.days_of_usage.map(day => new Date(day.ts_from).getTime()));
          return aMinTsFrom - bMinTsFrom;
        });

        const lessonsNames: string[] = []
        orderedLessons.forEach((lesson: CalendarData) => {
          const originalName = lesson.name
          let newName = lesson.name
          let suffix = 2
          // eslint-disable-next-line
          while (true) {
            if (!lessonsNames.includes(newName)) {
              lessonsNames.push(newName)
              lesson.name = newName
              break;
            }
            newName = originalName + " (" + suffix + ")"
            suffix++;
          }
        })

        setCalendarDayData(orderedLessons)
        orderedLessons.forEach((lesson: CalendarData) => {
          if (newLessons[0].key === "") newLessons[0] = {key: lesson._id, value: lesson.name}
          else newLessons.push({key: lesson._id, value: lesson.name})
        })
        setLessons(newLessons)
        const valuesLessons = newLessons.map(object => object.value)
        if (valuesLessons[0] !== "") {
          setLessonNames(valuesLessons)
          setActiveLesson(valuesLessons[0])
        } else {
          setLessonNames([])
          setActiveLesson(undefined)
        }
        break;
      }
    }
  }

  const getLessonProgress = () => {
    if (!calendarDayData) return
    const currentDay = new Date()
    const newProgress: string[] = []
    for (let i = 0; i < calendarDayData.length; i++) {
      const lesson = calendarDayData[i];
      for (let j = 0; j < lesson.days_of_usage.length; j++) {
        const time = lesson.days_of_usage[j];
        if (new Date(time.ts_from).getDate() !== selectedDate.getDate()) continue;
        if (new Date(time.ts_from) > currentDay) {
          newProgress.push("pending");
          continue;
        }
        if (new Date(time.ts_from) < currentDay && new Date(time.ts_to) > currentDay) {
          newProgress.push("ongoing");
          continue;
        }
        if (new Date(time.ts_to) < currentDay) {
          newProgress.push("done");
          continue;
        }
      }
    }
    setLessonProgress(newProgress)
  }

  const rateLessonMaterials = async (rating: string) => {
    if (calendarDayData) {
      const lesson = calendarDayData[activeLessonIndex]
      const lessonId = lesson?._id;

      try {
        const response = await axios.post(`/api/lesson/${lessonId}/rate`, {
          rating: rating
        });

        if (response.status === 200) {
          setLessonRating(rating);
        } else {
          alert("Nepodařilo se uložit hodnocení");
        }
      } catch (error: any) {
        if (error.response.status === 403) {
          alert("Nelze hodnotit znovu nebo hodnocení není povoleno");
        } else {
          alert("Nepodařilo se uložit hodnocení");
        }
      }
    }
  };

  const getSetLessonMaterialsRating = async () => {
    if (calendarDayData) {
      const lesson = calendarDayData[activeLessonIndex]
      const lessonId = lesson?._id;
      try {
        const response = await axios.get(`/api/student/lesson/${lessonId}/rating`);

        if (response.status === 200) {
          setLessonRating(response.data.rating);
        }
      } catch (error: any) {
        if (error.response.status === 404) {
          setLessonRating("");
        } else {
          throw error;
        }
      }
    }
  };

  return (
    <section className="student-calendar-lesson-menu">
      <ResponsiveComponent
        desktop={
          <CalendarMenuWindow
            lessonNames={lessonNames}
            selectionLessons={selectionLessons}
            setSelectionLessons={setSelectionLessons}
            activeLesson={activeLesson}
            setActiveLesson={setActiveLesson}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            savedMaterials={savedMaterials}
            lessonData={lessonData}
            lessonProgress={lessonProgress}
            calendarDayData={calendarDayData}
            setSavedMaterials={setSavedMaterials}
            setLessonData={setLessonData}
            activeLessonIndex={activeLessonIndex}
            setActiveLessonIndex={setActiveLessonIndex}
            rateLessonMaterials={rateLessonMaterials}
            lessonRating={lessonRating}
          />
        }
        mobile={
          <MobileCalendarMenu
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            lessons={lessons}
            lessonProgress={lessonProgress}
            calendarDayData={calendarDayData}
            activeLesson={activeLesson}
            obtainCalendarData={obtainCalendarData}
            rateLessonMaterials={rateLessonMaterials}
            lessonRating={lessonRating}
          />
        }
      />
    </section>
  )
}
export default StudentCalendarMenu;