import React from "react";
import { Draggable, DraggableProvided, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { ReactComponent as InfoIcon } from '../../images/info.svg';

// Returns activity element
const Activity = ({ name, color, _id, index, clubUrl }: { name: string, color: string, _id: string, index: number, clubUrl: string }) => {
  function hex_to_RGB(hex:any) {
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    return {
        r: parseInt(m[1], 16) + 10,
        g: parseInt(m[2], 16) + 10,
        b: parseInt(m[3], 16) - 10
    };
  }
  const rgb = hex_to_RGB(color);
  // Sets the style of the draggable items
  const getItemStyle = ( isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined ) => ({
    background: isDragging ? `rgba(`+rgb.r+`, `+rgb.g+`, `+rgb.b+`)` : color,
    color: "black",
    border: `1px solid rgba(0, 0, 0, 0.3)`,
    borderRadius: `3px`,
    fontSize: `15px`,
    minHeight: `30px`,
    display: `flex`,
    margin: `18px 12px`,
    paddingLeft: `15px`,
    paddingRight: `5px`,
    alignItems: `center`,
    overflow: `hidden`,

    ...draggableStyle,
    transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)'
  })

  // Returns draggable activity item
  return (
    <Draggable draggableId={ _id } index={ index }>
      {( provided: DraggableProvided, snapshot: DraggableStateSnapshot ) => (
         <React.Fragment>
            <div ref={ provided.innerRef }
            { ...provided.draggableProps }
            { ...provided.dragHandleProps }
            style={ getItemStyle(snapshot.isDragging,  provided.draggableProps.style) }>
              <div className="content">
                {clubUrl !== "" ? 
                  <InfoIcon className='icon' onClick={() => window.open(clubUrl, "_blank", "noopener noreferrer")} />
                : 
                  <InfoIcon className='icon disabled'/>
                }
                <div className="text">
                  { name }
                </div>
              </div>
            </div>
            {snapshot.isDragging && (
              <div style={getItemStyle(false, undefined)}>
                <div className="content">
                  {clubUrl !== "" ? 
                    <InfoIcon className='icon' />
                  :
                    <InfoIcon className='icon disabled' />
                  }
                  <div className="text">
                    {name}
                  </div>
                </div>
              </div>
              )}
          </React.Fragment>
      )}
    </Draggable>
  )
}

export default Activity;