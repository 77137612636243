// Imports From Libraries
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// React Components
import NavStudent from '../components/student/NavStudent';
import LessonWindowStudent from '../components/student/LessonView';
// Stylesheets
import './LessonView.scss';
// Redux
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { Logout } from '../components/nav/logout/Logout';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileLessonWindow from 'components/student/LessonView/mobile/MobileLessonWindow';
import AppBar from 'components/mobile-utils/AppBar';
import { LessonProvider } from 'components/student/LessonView/LessonContext';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';

const LessonView = () => {
  const navigate = useNavigate();

  const lessonId = useSelector((state: RootState) => state.editor.lessonId);
  const teachersId = useSelector((state: RootState) => state.editor.teachersId);
  
  useEffect(() => {
    if (!lessonId || !teachersId) navigate("/view-selection");
  }, [lessonId, teachersId]);

  return (
    <LessonProvider>
      <ResponsiveComponent
        desktop={
          <section className="editorStudent">
            <KeyboardShortcuts context="lessonView"></KeyboardShortcuts>
            <div className='editorStudent-container'>
              <div className='toolbar'>
                <NavStudent />
                <Logout />
              </div>

              <div className='lesson-area'>
                <LessonWindowStudent />
              </div>

            </div>
          </section>
        }
        mobile={
          <div className="flex flex-col h-screen">
            <AppBar />
            <MobileLessonWindow />
          </div>
        }
      />
    </LessonProvider>
  );
}

export default LessonView;
