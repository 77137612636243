import Dropdown from "../dropdown";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import axios from "../../utils/axios";
import { PropsWithChildren } from "react";
import { setLessonId, setSubjectId } from "redux/editorSlice";
import { getFilenameFromResponseHeaders } from '../../utils/Tools';
import { iLessonMenuIndexes, iUserData } from "interfaces/user-data-interface";

interface InfoButtonsProps extends PropsWithChildren<any> {
  userData: iUserData;
  activeTeachingUnit: string;
  activeLesson: string;
  lessons: [{ key: string; value: string }] | []
  getActiveItemsIndexes: () => iLessonMenuIndexes;
}

export const InfoButtons = ({userData, activeTeachingUnit, activeLesson, lessons, getActiveItemsIndexes}: InfoButtonsProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditButton = () => {
    if(!userData) throw new Error("UserData are missing! UserData: ", userData )
    const indexes = getActiveItemsIndexes()
    const idOfSubject = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]._id
    const idOfLesson = lessons.find((lesson) => lesson.value === activeLesson)?.key;
    dispatch(setLessonId(idOfLesson));
    dispatch(setSubjectId(idOfSubject));
    navigate('/editor');
  }

  const downloadLesson = async () => {
    try {
      if (!userData) throw new Error("UserData are missing! UserData: ", userData )
      const indexes = getActiveItemsIndexes()
      const lessonId = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex].teaching_units[indexes.activeTeachingUnitIndex].lessons[indexes.activeLessonIndex]._id
      const response = await axios.get(`/api/export-lesson/${lessonId}`, { responseType: 'blob' });
      const filename = getFilenameFromResponseHeaders(response.headers);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const file = document.createElement('a');
      file.href = url;
      file.setAttribute('download', filename);
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      throw new Error("export lesson axios request failed:", error);
    } 
  }
  
  const handleExportTeachingUnit = async () => {
    try {
      if (!userData) throw new Error("UserData are missing! UserData: ", userData )
      const indexes = getActiveItemsIndexes()
      const id_of_teaching_unit = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex].teaching_units[indexes.activeTeachingUnitIndex]._id
      const link = "/api/export-teaching-unit/" + id_of_teaching_unit
      const response = await axios.get(link, { responseType: 'blob' });
      const filename = getFilenameFromResponseHeaders(response.headers);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const file = document.createElement('a');
      file.href = url;
      file.setAttribute('download', filename);
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      throw new Error("export teaching unit axios request failed:", error);
    }
  }


  return (
    <section className="info-buttons">
      <button hidden disabled={!activeLesson} onClick={downloadLesson} id={"exportLessonButton"}></button>
      <button hidden disabled={!activeLesson} onClick={() => {const url = `/pdf/student/${userInfo.data_id}/${lessons.find((lesson) => lesson.value === activeLesson)?.key}`;window.open(url, "_blank");}} id={"pdfExportButton"}></button>
      <Dropdown
        label="Exportovat"
        disabled={!activeTeachingUnit}
        upPlacement={true}
        className={"export"}
        menuMiddle={true}
        menuClasses="mb-[-0.5rem]"
      >
        <Dropdown.Item
          className="!p-3"
          onClick={handleExportTeachingUnit}
        >
          Exportovat výukový celek
        </Dropdown.Item>
        <Dropdown.Item
          className="!p-3"
          disabled={!activeLesson}
          onClick={downloadLesson}
        >
          Exportovat hodinu na disk
        </Dropdown.Item>
        <Dropdown.Item
          className="!p-3"
          disabled={!activeLesson}
          onClick={() => {
            if (!activeLesson) return;
            const url = `/pdf/teacher/${userInfo.data_id}/${lessons.find((lesson) => lesson.value === activeLesson)?.key}`;
            window.open(url, "_blank", "noopener noreferrer");
          }}>
          Exportovat do PDF pro učitele
        </Dropdown.Item>
        <Dropdown.Item
          className="!p-3"
          disabled={!activeLesson}
          onClick={() => {
            if (!activeLesson) return;
            const url = `/pdf/student/${userInfo.data_id}/${lessons.find((lesson) => lesson.value === activeLesson)?.key}`;
            window.open(url, "_blank");
          }}>
          Exportovat do PDF pro žáka
        </Dropdown.Item>
        <Dropdown.Item
          className="!p-3"
          disabled={!activeLesson}
          onClick={() => {
            if (!activeLesson) return;
            const url = `/pdf/after-lesson/${userInfo.data_id}/${lessons.find((lesson) => lesson.value === activeLesson)?.key}`;
            window.open(url, "_blank");
          }}>
          Exportovat do PDF pro žáka po hodině
        </Dropdown.Item>
      </Dropdown>
      <button className="edit" id={"editButton"} disabled={!activeLesson} onClick={handleEditButton}>Editovat</button>
  </section>
  );
}