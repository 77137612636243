// Imports From Libraries
import { useState, useEffect } from 'react';
import { DragDropContext, DropResult} from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
// React Components
import Nav from "../components/nav/Nav"
// Add new ActivityType
//import AddActivityType from '../components/activity-type/AddActivityType';
//import { newActivityType } from '../utils/editorUtilities';
import ActivityTypes from '../components/activity-type/ActivityTypes';
import ActivityWidget from '../components/activities/ActivityWidget';
import LessonWindow from '../components/editor/lesson-window/LessonWindow';
import Modal from '../components/edit-modal/Modal';
// JSON Interfaces
import { iLessonActivityItem, iLesson, iLessonRating } from '../interfaces/lesson-activity-interface';
import { iActivityTypeItem } from '../interfaces/activity-interface';
// JSON Data
import LessonActivitiesData from '../dummy-data/LessonActivitiesData.json';
// Stylesheets
import './Editor.scss';
// utils
import { startIndexOfMainActivities, calculateSumOfRowsMins, normalizeActivites, getMovedActivities } from '../utils/editorUtilities';
import { getFilenameFromResponseHeaders, useInterval } from 'utils/Tools';
// Redux
import { RootState } from '../redux/store';
import { setNotes, setLessonName, setGoal, setCurriculums, setSumOfRowsMins } from '../redux/editorSlice';
import { useSelector, useDispatch } from 'react-redux';
import axios from "utils/axios";
import { useSnackbar } from '../contexts/SnackbarProvider';
import { useModal } from '../contexts/ModalProvider';
import DownloadModal from '../components/nav/nav-modals/DownloadModal';
import { useThrowAsyncError } from '../utils/ErrorHandling';
import { ReactComponent as InfoIcon } from '../images/info.svg';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';

const Editor = () => {
  const throwAsyncError = useThrowAsyncError();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activitiesList: iLessonActivityItem[] =
  [
    {
      _id: '0',
      activities: [],
    },
    {
      _id: '1',
      activities: [],
    },
    {
      _id: '2',
      activities: [],
    },
    {
      _id: uuidv4(),
      activities: [],
    }
  ]
  const dispatch = useDispatch();
  const lessonName = useSelector((state: RootState) => state.editor.lessonName)
  const lessonId = useSelector((state: RootState) => state.editor.lessonId)
  const subjectId = useSelector((state: RootState) => state.editor.subjectId)
  const idsForLesson = [null, lessonId, null, subjectId];

  const { openSnackbar } = useSnackbar();

  const [ activityTypes, setActivityTypes ] = useState<iActivityTypeItem[]>([]);
  const [ activities, setActivities ] = useState<iLessonActivityItem[]>(activitiesList);
  const [ lengthInMins, setLengthInMins ] = useState<number>(LessonActivitiesData.length_in_mins);
  const [ lessonData, setLessonData ] = useState<iLesson>(LessonActivitiesData)
  //const [ notes, setNotesState ] = useState<string>(LessonActivitiesData.notes)
  const [ activityTypeHeading, setActivityTypeHeading ] = useState<string>()
  const [ activityTypeId, setActivityTypeId ] = useState<string>()
  const [ activityTypeClubUrl, setActivityTypeClubUrl ] = useState<string>("")
  const [ initActivities, setInitActivities ] = useState<iLessonActivityItem[]>(activitiesList);
  const [ initMetaData, setInitMetaData ] = useState<string[]>([])
  const [ lessonRatings, setLessonRatings ] = useState<iLessonRating[]>([])
  // Is lesson saved before closing the tab
  const [ isLessonChanged, setIsLessonChanged ] = useState<boolean>(false);

  const { showModal } = useModal();

  const lessonNameSelector = useSelector((state: RootState) => state.editor.lessonName)
  const lessonNotes = useSelector((state: RootState) => state.editor.notes)
  const lessonGoal = useSelector((state: RootState) => state.editor.goal)
  const lessonCurriculumsSelector = useSelector((state: RootState) => state.editor.curriculums)
  const [ lessonToUpdate, setLessonToUpdate ] = useState<any>()

  useEffect(() => {
    if (!lessonId) {
      navigate("/lesson-menu");
    }
  }, [lessonId, navigate]);

  useEffect(() => {
    loadActivities()
    setActivities([...normalizeActivites(activities, lengthInMins)])
    if(searchParams.get("refresh") !== null)
      window.location.href = "/editor";
  }, []);

  useEffect(() => {
    if (!idsForLesson) navigate("/lesson-menu");
  }, [idsForLesson]);

  useEffect(() => {
    const lessonDataCopy = lessonData;
    if(!lessonDataCopy) throw new Error("lessonData are missing! lessonData: ", lessonDataCopy )
    lessonDataCopy.length_in_mins = lengthInMins;
    lessonDataCopy.activities_list = activities.slice(1);
    lessonDataCopy.name = lessonName;
    setLessonData(lessonDataCopy);
  }, [activities, lengthInMins, lessonName])

  useEffect(() => {
    setActivities([...normalizeActivites(activities, lengthInMins)])
  }, [lengthInMins])

  useEffect(() => {
    setLessonToUpdate({ ...lessonToUpdate, name: lessonNameSelector ?? "", notes: lessonNotes ?? "", 
    goal: lessonGoal ?? "", curriculums: lessonCurriculumsSelector ?? []})
  }, [lessonNameSelector, lessonNotes, lessonGoal, lessonCurriculumsSelector])

  // To check if lesson was changed before closing the tab
  useEffect(() => {
    if (initMetaData.length === 0) return;

    const lessonChanged = wasLessonChanged();
    if (lessonChanged !== isLessonChanged) setIsLessonChanged(lessonChanged);
  }, [activities, lengthInMins, lessonName,
    lessonNotes, lessonGoal, lessonCurriculumsSelector,
    initMetaData])

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (isLessonChanged) {
      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      }
    }
  }, [isLessonChanged]);

  useInterval(() => {
    if (isLessonChanged) {
      console.info("autosaved");
      handleSaveLesson('database');
    }
  }, 60000);

  // Function that remove all id key:value pairs from object
  const removeIds = (array: any[]) => {
    if (!array) return [];

    return array.map((obj) => {
      for (const key in obj) {
        if (key === "_id") {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          obj[key] = removeIds(obj[key]);
        }
      }
      return obj;
    });
  };

  const wasLessonChanged = () => {
    const currentActivities = JSON.parse(JSON.stringify(activities)).slice(1);
    const initActivitiesCopy = JSON.parse(JSON.stringify(initActivities)).slice(1);
    // To make linter shut up
    let initCurriculum: string[] = [];
    if (initMetaData[3] !== undefined) initCurriculum = initMetaData[3].slice() as unknown as string[];
    const changed = JSON.stringify(removeIds(initActivitiesCopy)) !== JSON.stringify(removeIds(currentActivities)) ||
      initMetaData[0] !== lessonName ||
      initMetaData[1] !== lessonNotes ||
      initMetaData[2] !== lessonGoal ||
      initCurriculum.sort().join(',') !== lessonCurriculumsSelector.slice().sort().join(',') ||
      String(initMetaData[4]) !== String(lengthInMins);

    return changed;
  };

  const getTimeZone = () => {
    const offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  const getTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const microsecond = date.getMilliseconds().toString().padStart(6, '0');
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}.${microsecond}`;

    return formattedDate + getTimeZone()
  }

  interface iSaveLessonOptions {
    location: string,
    navigateAfterSave?: boolean,
    fileName?: string,
    callback?: () => void
  }

  const handleSaveLessonWithOptions = (options: iSaveLessonOptions) => {
    const { location, navigateAfterSave, fileName, callback } = options;
    handleSaveLesson(location, navigateAfterSave, fileName, callback);
  }

  const handleSaveLesson = async (location: string, navigateAfterSave?: boolean, fileName?: string, callback?: () => void) => {
    if (!lessonId) return
    const lessonData = lessonToUpdate
    const activitiesCopy = JSON.parse(JSON.stringify(activities));
    lessonData._id = lessonId;
    lessonData.length_in_mins = lengthInMins ?? 30;
    lessonData.ts_created = getTime() ?? "";
    lessonData.ts_updated = getTime() ?? "";
    lessonData.goal = lessonToUpdate.goal
    if (lessonToUpdate.name.trim() === "") {
      lessonData.name = ""
    } else {
      lessonData.name = lessonToUpdate.name
    }
    lessonData.curriculums = lessonToUpdate.curriculums
    lessonData.notes = lessonToUpdate.notes
    lessonData.activities_before = []
    lessonData.activities_after = []
    lessonData.activities_list = []
    activitiesCopy.slice(3, activitiesCopy.length).forEach((activity: any) => {
      lessonData.activities_list.push(activity)
    })
    lessonData.activities_before = activitiesCopy[1].activities ?? [];
    lessonData.activities_after = activitiesCopy[2].activities ?? [];
    lessonData.ratings = lessonRatings;
    if (location === "database") {
      try {
        await axios.post('/api/lesson', lessonData);
        setInitActivities([...activitiesCopy]);
        const metaData = [lessonData.name, lessonData.notes, lessonData.goal, lessonData.curriculums, lessonData.length_in_mins];
        setInitMetaData(metaData);
        openSnackbar("Hodina uložena.");
        if (navigateAfterSave) navigate("/lesson-menu");
        if (callback) callback();
      } catch (err: any) {
        // open modal with an option to save to drive
        showModal(<DownloadModal saveAfterError saveLesson={handleSaveLesson} />)
      }
    }
    else if (location === "drive") {
      const response = await axios.get(`/api/export-lesson/${lessonId}/${fileName}`, { responseType: 'blob' });
      const filename = getFilenameFromResponseHeaders(response.headers);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const file = document.createElement('a');
      file.href = url;
      file.setAttribute('download', filename);
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
      window.URL.revokeObjectURL(url);
    }
  }

  const loadActivities = async () => {

    let activitiesCopy = JSON.parse(JSON.stringify(activities));
    await axios.get("/api/activity-types")
      .then((result) => {
        activitiesCopy[0] = { _id: result.data._id, activities: result.data.activity_types }
        setActivityTypes(activitiesCopy[0].activities)
        setActivityTypeId(activitiesCopy[0].activities[0]._id)
        setActivityTypeClubUrl(activitiesCopy[0].activities[0].club_url)
      })
    .catch(err => throwAsyncError(err))

    await axios.get("/api/lesson/" + lessonId)
    .then((result) => {
      if(result === null) {
        return
      } else {
        activitiesCopy[1] = { _id: '1', activities: result.data.activities_before };
        activitiesCopy[2] = { _id: '2', activities: result.data.activities_after };
        activitiesCopy = [activitiesCopy[0].activities[0], activitiesCopy[1], activitiesCopy[2], ...result.data.activities_list]
        if (activitiesCopy.length === 3)
          activitiesCopy[3] = { _id: uuidv4(), activities: []}
        setActivityTypeHeading(activitiesCopy[0].name);
        dispatch(setLessonName(result?.data?.name));
        dispatch(setNotes(result?.data?.notes));
        dispatch(setGoal(result?.data?.goal));
        dispatch(setCurriculums(result?.data?.curriculums));
        setLengthInMins(result?.data?.length_in_mins);
        setActivities([...activitiesCopy])
        dispatch(setSumOfRowsMins(calculateSumOfRowsMins([...activitiesCopy], startIndexOfMainActivities)));
        setLessonRatings(result?.data?.ratings)
        //Create copies for compare when saving
        setInitActivities(JSON.parse(JSON.stringify(activitiesCopy)));
        const { name, notes, goal, curriculums, length_in_mins } = result?.data || {};
        const metaData = [name, notes, goal, curriculums, length_in_mins];
        setInitMetaData(metaData)
      }
    })
    .catch(err => throwAsyncError(err))
  }

  const onActivityTypeDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    const activityTypesCopy = activityTypes;
    const [removed] = activityTypesCopy.splice(source.index, 1);
    activityTypesCopy.splice(destination.index, 0, removed);
    setActivityTypes(activityTypesCopy);
  }

  const onActivityDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    let activitiesCopy = activities;
    const destIndex = activitiesCopy.findIndex((obj) => obj._id === destination.droppableId);
    activitiesCopy = getMovedActivities(activitiesCopy, destination, source, lengthInMins, activityTypeId)
    const lengthDelta = lengthInMins - calculateSumOfRowsMins(activitiesCopy, startIndexOfMainActivities);
    // If the new size of all activity rows is greater than the length of the lesson -> make the edited activity shorter
    if (lengthDelta < 0) activitiesCopy[destIndex].activities[destination.index].size += lengthDelta;
    setActivities([...activitiesCopy]);
    dispatch(setSumOfRowsMins(calculateSumOfRowsMins(activities, startIndexOfMainActivities)));
  }

  // Creates a new activity type
/*   const addActivityType = () => {
    activityTypes.push(newActivityType());
    setActivityTypes([...activityTypes]);
  } */

  // On activity change -> apply changes and set changed activities
  const handleActivityChange = (activity: any) => {
    let activitiesCopy = JSON.parse(JSON.stringify(activities));
    const row = activitiesCopy.findIndex((obj: { activities: any[]; }) => obj.activities.some(item => item._id === activity._id)); 
    if(row < 0)
      return;

    const index = activitiesCopy[row].activities.findIndex((obj: { _id: any; }) => obj._id === activity._id);
    activitiesCopy[row].activities[index] = activity
    const size = activity.size
    if(size > 0) {
      for (const [i] of activitiesCopy[row].activities.entries()) {
        activitiesCopy[row].activities[i].size = size;
      }
    }
    activitiesCopy = normalizeActivites(activitiesCopy, lengthInMins)
    const lengthDelta = lengthInMins - calculateSumOfRowsMins(activitiesCopy, startIndexOfMainActivities);
    // If the new size of all activity rows is greater than the length of the lesson -> make the edited activity shorter
    if (lengthDelta < 0) {
      activitiesCopy[row]?.activities?.map( (activity:any) => activity.size += lengthDelta)
    }
    setActivities(activitiesCopy)
    dispatch(setSumOfRowsMins(calculateSumOfRowsMins(activities, startIndexOfMainActivities)));
  }

  // On ActivityType button click -> set according menu activities
  const onActivityTypeClick = (_id: string) => {
    const activitiesCopy = JSON.parse(JSON.stringify(activities));
    const newActivityType: any = activityTypes.find(activityType => activityType._id === _id);
    setActivityTypeHeading(newActivityType.name);
    setActivityTypeClubUrl(newActivityType.club_url)
    activitiesCopy[0].activities = newActivityType.activities;
    setActivities([...activitiesCopy]);
  }

  if(activities[0]._id === undefined)
    return null;

  return (
    <section className="editor">
      <KeyboardShortcuts context="editor"></KeyboardShortcuts>
      <div className='editor-container'>
        <div className='toolbar'>
          <Nav
            setLengthInMins={ setLengthInMins }
            lessonActivities={ activities }
            setActivities={ setActivities }
            lessonData={ lessonData }
            handleSaveLesson={ handleSaveLesson }
            handleSaveLessonWithOptions={ handleSaveLessonWithOptions }
            initActivities={ initActivities }
            initMetaData={ initMetaData }
            lessonId={ lessonId }
          />
        </div>
        <DragDropContext onDragEnd={ onActivityTypeDragEnd }>
          <div className='activity-types'>
            {/* <AddActivityType addActivityType={ addActivityType } /> */}
            <ActivityTypes activityTypes={ activityTypes } onClick={ onActivityTypeClick } />
          </div>
        </DragDropContext>
        <DragDropContext onDragEnd={ onActivityDragEnd }>
          <div className='activities'>
            <h3 className='activitiesHeading'>{ activityTypeHeading }
              {activityTypeClubUrl !== "" ? 
                <InfoIcon className='icon' onClick={() => window.open(activityTypeClubUrl, "_blank", "noopener noreferrer")} />
              :
                <InfoIcon className='icon disabled' />
              }
            </h3>
            <ActivityWidget activitiesList={ activities[0] } />
          </div>
          <div className='lesson-area'>
            <LessonWindow lengthInMins={lengthInMins} activitiesBefore={ activities[1] } activitiesAfter={ activities[2] } activitiesList={ activities.slice(3, activities.length)} subjectId={subjectId} lessonId={lessonId} />
          </div>
        </DragDropContext>
      </div>
      <Modal onActivityChange={ handleActivityChange } />
    </section>
  )
}
export default Editor;