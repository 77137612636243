import { useRef } from 'react';
import '../../editor/lesson-window/lesson/accessories/Accessories.scss';
import { ReactComponent as AccessoriesIcon } from "../../../images/accessories.svg";
import { useLessonContext } from './LessonContext';

/**
 * A component that shows all the accessories needed for (before, after) the lesson.
 * @returns Accessories component.
 */
const AccessoriesStudent = () => {
  const accessoriesRef = useRef<HTMLDivElement>(null);
  const { gadgets, gadgetsBefore, gadgetsAfter } = useLessonContext();

  const handleTogglerClick = () => {
    if(accessoriesRef === undefined || accessoriesRef === null || accessoriesRef.current === undefined || accessoriesRef?.current === null) return;
    const acc = accessoriesRef.current;
    if(acc.style.right === "0px") {
      acc.style.right = "-260px";
      acc.classList.remove("collapsed");
      return;
    }
    acc.style.right = "0px";
    acc.classList.add("collapsed");
  }

  return (
    <div id="accessories" ref={accessoriesRef}>
      <div className='toggler' onClick={handleTogglerClick}>
        <AccessoriesIcon className='accessories-icon'/>
      </div>
      <div className="divider first">
          <h3 className='title'>Pomůcky</h3>
      </div>
      <div className="divider">
          <h4>Před:</h4>
          <ul>
            {gadgetsBefore !== null && gadgetsBefore.length > 0 ?
                gadgetsBefore?.map((gadget, index) => <li key={index}>{gadget}</li>)
              :
                <li>Není zde žádná pomůcka</li>
            }
          </ul>
      </div>
      <div className="divider">
          <h4>Během:</h4>
          <ul>
            {gadgets !== null && gadgets.length > 0 ?
                gadgets?.map((gadget, index) => <li key={index}>{gadget}</li>)
              :
                <li>Není zde žádná pomůcka</li>
            }
          </ul>
      </div>
      <div className="divider">
          <h4>Po:</h4>
          <ul>
            {gadgetsAfter !== null && gadgetsAfter.length > 0 ?
                gadgetsAfter?.map((gadget, index) => <li key={index}>{gadget}</li>)
              :
                <li>Není zde žádná pomůcka</li>
            }
          </ul>
      </div>
    </div>
  );
};

export default AccessoriesStudent;