import { AxiosResponseHeaders } from "axios";
import { useEffect, useRef } from "react";

export const getFilenameFromResponseHeaders = (headers: AxiosResponseHeaders | Partial<Record<string, string> & {"set-cookie"?: string[] | undefined}>) => {
    const contentDisposition = headers['content-disposition'];
    if (!contentDisposition) return 'file.zip';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
    return 'file.zip';
  };

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
  