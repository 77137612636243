import { useLessonContext } from '../LessonContext';

const Gadgets = () => {

  const { gadgets, gadgetsBefore, gadgetsAfter } = useLessonContext();

  return (
    <div className='overflow-auto max-h-[75vh]'>
      <div>
        <h4 className='font-semibold'>Před:</h4>
        <ul className='list-disc list-inside'>
          {gadgetsBefore !== null && gadgetsBefore.length > 0 ?
            gadgetsBefore?.map((gadget, index) => <li key={index}>{gadget}</li>)
            :
            <li>Není zde žádná pomůcka</li>
          }
        </ul>
      </div>
      <div>
        <h4 className='font-semibold'>Během:</h4>
        <ul className='list-disc list-inside'>
          {gadgets !== null && gadgets.length > 0 ?
            gadgets?.map((gadget, index) => <li key={index}>{gadget}</li>)
            :
            <li>Není zde žádná pomůcka</li>
          }
        </ul>
      </div>
      <div>
        <h4 className='font-semibold'>Po:</h4>
        <ul className='list-disc list-inside'>
          {gadgetsAfter !== null && gadgetsAfter.length > 0 ?
            gadgetsAfter?.map((gadget, index) => <li key={index}>{gadget}</li>)
            :
            <li>Není zde žádná pomůcka</li>
          }
        </ul>
      </div>
    </div>
  )
}

export default Gadgets;