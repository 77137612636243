import ContextMenuBase from 'utils/ContextMenuBase';

interface EmptyContextMenuProps {
  x: number;
  y: number;
  handlePaste: () => void;
  closeMenu: () => void;
}

const EmptyContextMenu = ({ x, y, handlePaste, closeMenu }: EmptyContextMenuProps) => {
  return (
    <ContextMenuBase x={x} y={y} closeMenu={closeMenu}>
      <ul className="space-y-1 text-sm">
        <li 
          className="cursor-pointer hover:bg-[#8007aa1a] px-2 py-1 rounded"
          onClick={handlePaste}
        >
          Vložit
        </li>
      </ul>
    </ContextMenuBase>
  );
};

export default EmptyContextMenu;
