// Library imports
import { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { setLessonId, setMenu, setTeachersId } from 'redux/editorSlice';

// Interfaces
import {
  iStudentSubjectsMenuData,
  iStudentSubject,
  iStudentTeachingUnit,
  iStudentLesson,
  iStudentLessonMenuIndexes
} from 'interfaces/student-user-data-interface';

export default () => {
  // User variables
  const [subjectsData, setSubjectsData] = useState<iStudentSubjectsMenuData>();
  const classId = useSelector((state: RootState) => state.auth.userInfo.class_id);

  // Subject variables
  const [subjectNames, setSubjectNames] = useState<string[]>([]);
  const [selectionSubjectNames, setSelectionSubjectNames] = useState<string[]>([]);
  const [activeSubject, setActiveSubject] = useState<string>('');
  const [unselectedSubjectNames, setUnselectedSubjectNames] = useState<boolean>(true);

  // Teaching units variables
  const [teachingUnitsNames, setTeachingUnitNames] = useState<string[]>([]);
  const [selectionTeachingUnitsNames, setSelectionTeachingUnitsNames] = useState<string[]>([]);
  const [activeTeachingUnit, setActiveTeachingUnit] = useState<string>('');
  const [unselectedTeachingUnitsNames, setUnselectedTeachingUnitsNames] = useState<boolean>(true);

  // Lesson variables
  const [lessonNames, setLessonNames] = useState<string[]>([]);
  const [selectionLessons, setSelectionLessons] = useState<string[]>([]);
  const [activeLesson, setActiveLesson] = useState<string>('');
  const [unselectedLessons, setUnselectedLessons] = useState<boolean>(true);
  const [lessons, setLessons] = useState<iStudentLesson[] | []>([]);
  const [lessonProgress, setLessonProgress] = useState<string[]>()
  const [lessonRating, setLessonRating] = useState<string>()

  // Other variables
  const [lessonData, setLessonData] = useState<{_id: string; subject: string; teachingUnit: string; teacher: string; teachers_data_id: string; times: string[];} | undefined>();
  const [savedMaterials, setSavedMaterials] = useState<{ name: string, type: string, url_to_file: string }[]>([]);

  const studentsEmail = useSelector((state: RootState) => state.auth.userInfo.email)
  const studentsClass = useSelector((state: RootState) => state.editor.class)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Obtain user data when the page loads
  useEffect(() => {
    obtainSubjectsData()
    dispatch(setMenu("subject"))
  }, [])

  useEffect(() => {
    if (!subjectsData) return;
    setNamesForDisplay(subjectsData.subjects)
  }, [subjectsData])

  // Update the lesson menu when the user clicks on an unselected subject
  useEffect(() => {
    updateSelections(selectionSubjectNames, setSelectionSubjectNames, subjectNames, activeSubject)
    obtainTeachingUnits(activeSubject)
  }, [activeSubject])

  // Update the lesson menu when the user clicks on an unselected teaching unit
  useEffect(() => {
    updateSelections(selectionTeachingUnitsNames, setSelectionTeachingUnitsNames, teachingUnitsNames, activeTeachingUnit)
    obtainLessons(activeSubject, activeTeachingUnit)
  }, [activeTeachingUnit])

  // When a lesson is selected -> Fetches lesson data and loads it into the page
  useEffect(() => {
    setLessonData(undefined);
    updateSelections(selectionLessons, setSelectionLessons, lessonNames, activeLesson);
    const lesson = lessons.find((lesson) => lesson.name === activeLesson)
    if (!lesson) return
      const times = []
      const optionsFrom: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
      const optionsTo: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
      for (const time of lesson.days_of_usage) {
        const dateFrom = new Date(time.ts_from);
        const dateTo = new Date(time.ts_to);
        const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(',', '');
        const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(',', '');
        times.push(`${formattedFrom} - ${formattedTo}`);
      }
    setLessonData({
      _id: lesson._id,
      subject: activeSubject,
      teachingUnit: activeTeachingUnit,
      teacher: lesson.teachers_email,
      teachers_data_id: lesson.teachers_data_id,
      times: times
    })

    getSetLessonMaterialsRating();
  }, [activeLesson])

  // Updates the list of lesson names when the list of lessons changes
  useEffect(() => {
    const valuesLessons = lessons.map(object => object.name)
    if (valuesLessons[0] !== "") {
      setLessonNames(valuesLessons)
      setActiveLesson(valuesLessons[0])
    } else {
      setLessonNames([])
    }
    getLessonProgress();
  }, [lessons])


  // Setting data for menu

  // Sets subjects in subjects data
  const setSubjectsInSubjectsData = (newSubjectNames: string[]) => {
    try {
      if (!subjectsData) throw new Error(`SubjectsData are missing!`)
      const newSubjects: iStudentSubject[] = []
      newSubjectNames.forEach(element => {
        const newSubject = subjectsData
          .subjects.find((x: any) => x.name === element)
        if (newSubject) newSubjects.push(newSubject)
      });
      setSubjectNames(newSubjectNames)
      subjectsData.subjects = newSubjects
    }
    catch (error: any) {
      console.error(error)
    }
  }

  // Sets teaching units in subjects data
  const setTeachingUnitsInSubjectsData = (newTeachingUnitNames: string[]) => {
    try {
      if (!subjectsData) throw new Error(`SubjectsData are missing!`)

      const newTeachingUnits: iStudentTeachingUnit[] = []
      const indexes = getActiveItemsIndexes()
      newTeachingUnitNames.forEach(element => {
        const newTeachingUnit = subjectsData
          .subjects[indexes.activeSubjectIndex]
          .teaching_units.find((x: any) => x.name === element)
        if (newTeachingUnit) newTeachingUnits.push(newTeachingUnit)
      });
      setTeachingUnitNames(newTeachingUnitNames)
      subjectsData
        .subjects[indexes.activeSubjectIndex]
        .teaching_units = newTeachingUnits
    }
    catch (error: any) {
      console.error(error)
    }
  }

  // Sets lessons in subjects data
  const setLessonsInSubjectsData = (newLessonNames: string[]) => {
    try {
      if (!subjectsData) throw new Error(`SubjectsData are missing!`)

      const newLessons: iStudentLesson[] = []
      const indexes = getActiveItemsIndexes()
      newLessonNames.forEach(element => {
        const newLesson = subjectsData
          .subjects[indexes.activeSubjectIndex]
          .teaching_units[indexes.activeTeachingUnitIndex]
          .lessons.find((x: any) => x.name === element)
        if (newLesson) newLessons.push(newLesson)
      });
      setLessonNames(newLessonNames)
      subjectsData
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons = newLessons
    }
    catch (error: any) {
      console.error(error)
    }
  }

  // Functions

  // Fetches subjects data
  const obtainSubjectsData = () => {
    axios.get(`/api/student/subjects/${classId}`)
    .then((response) => {
      setSubjectsData(response.data);
    })
    .catch((err) => {
      console.error(err)
    })
  }

  // Sets lesson names and lessons list based on active subject and teaching unit 
  const obtainLessons = (activeSubject: string, activeTeachingUnit: string) => {
    try {
      if (!subjectsData || activeSubject === '') return;
      const SelectedSubject = subjectsData.subjects.filter((obj: any) => obj.name === activeSubject)
      const mySubject = JSON.parse(JSON.stringify(SelectedSubject))

      if (!mySubject) throw new Error(`Subject not found!`)

      const teachingUnit: any[] = mySubject[0].teaching_units.filter((obj: any) => obj.name === activeTeachingUnit)

      if (!teachingUnit[0]) return setLessons([])
      if (!teachingUnit[0].lessons) teachingUnit[0].lessons = []

      if (teachingUnit[0].lessons.length < 1) return setLessons([])
      const orderedLessons = teachingUnit[0].lessons.sort((a: any, b: any) => {
        const aMinTsFrom = Math.min(...a.days_of_usage.map((day: any) => new Date(day.ts_from).getTime()));
        const bMinTsFrom = Math.min(...b.days_of_usage.map((day: any) => new Date(day.ts_from).getTime()));
        return aMinTsFrom - bMinTsFrom;
      });

      const counts: { [key: string]: number } = {};
      for (const item of orderedLessons) {
        if (counts[item.name]) {
          counts[item.name]++;
          item.name = `${item.name} (${counts[item.name]})`;
        } else {
          counts[item.name] = 1;
        }
      }

      setLessons(orderedLessons)
    }
    catch (error: any) {
      console.error(error)
    }
  }

  // Sets and sorts the unique subject names for display
  const setNamesForDisplay = (subjects: iStudentSubject[]) => {
    try {
      const subjectNames: string[] = []
      subjects.forEach((value: any) => {
        if (!subjectNames.includes(value.name)) subjectNames.push(value.name)
      })
      setSubjectNames(subjectNames.sort((a, b) => a.localeCompare(b, 'cs')))
      setActiveSubject(subjectNames.sort((a, b) => a.localeCompare(b, 'cs'))[0])
    } catch (error: any) {
      console.error(error)
    }
  }

  // Obtains and sets the unique teaching unit names for a given subject
  const obtainTeachingUnits = (subjectName: string) => {
    try {
      if (!subjectName) return setTeachingUnitNames([])
      if (!subjectsData) return;
      const activeSubject = subjectsData.subjects.find(
        (value: any) => value.name === subjectName,
      )
      if (!activeSubject) {
        setLessons([])
        setTeachingUnitNames([])
        return
      }
      const activeTeachingUnitNames: string[] = []
      activeSubject.teaching_units.forEach((value: any) => {
        if (activeTeachingUnitNames.includes(value.name)) throw new Error(`Teaching units contains duplicated values: teachingUnitNames = ${activeTeachingUnitNames}, value: ${value.name}`,)
        activeTeachingUnitNames.push(value.name)
      })
      setTeachingUnitNames(activeTeachingUnitNames)
      setActiveTeachingUnit(activeTeachingUnitNames[0])
    } catch (error: any) {
      console.error(error)
    }
  }

  // Retrieves the indexes of active items (subject, teaching unit, and lesson)
  const getActiveItemsIndexes = () => {
    const indexes: iStudentLessonMenuIndexes = {
      activeSubjectIndex: 0,
      activeTeachingUnitIndex: 0,
      activeLessonIndex: 0
    }
    if (!subjectsData) return indexes

    // Active subject
    if (!activeSubject) return indexes
    indexes.activeSubjectIndex = subjectsData.subjects.findIndex((value: any) => value.name === activeSubject)

    // Active teaching unit
    if (!activeTeachingUnit) return indexes
    indexes.activeTeachingUnitIndex = subjectsData.subjects[indexes.activeSubjectIndex]
      .teaching_units.findIndex((value: any) => value.name === activeTeachingUnit)

    // Active lesson
    if (!activeLesson) return indexes
    indexes.activeLessonIndex = subjectsData.subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .lessons.findIndex((value: any) => value.name === activeLesson)

    return indexes
  }

  // Updates the selection state of given selection list (subjectNames/teachingUnitsNames/lessonNames) based on the given active item and list
  const updateSelections = (
    selection: Array<string>,
    setSelection: React.Dispatch<React.SetStateAction<string[]>>,
    list?: Array<string>,
    active?: string
  ) => {
    const newSelection = new Array(selection.length);
    newSelection.fill("unselected")
    if (list && active) {
      const indexOfActiveItem = list.indexOf(active)
      newSelection[indexOfActiveItem] = "selected"
    }
    setSelection(newSelection)
  }

  const handleOpenButton = (clickedLesson: string) => {
    const lesson = lessons.find(lesson => lesson.name === clickedLesson)
    if (!lesson) return
    dispatch(setLessonId(lesson._id));
    dispatch(setTeachersId(lesson.teachers_data_id));
    navigate('/lesson-view');
  }

  const getLessonProgress = () => {
    if (!lessons) return
    const currentDay = new Date()
    const newProgress: string[] = []
    for (let i = 0; i < lessons.length; i++) {
      const lesson = lessons[i];
      for (let j = 0; j < lesson.days_of_usage.length; j++) {
        const time = lesson.days_of_usage[j];
        if (new Date(time.ts_from) > currentDay) {
          newProgress.push("pending");
          continue;
        }
        if (new Date(time.ts_from) < currentDay && new Date(time.ts_to) > currentDay) {
          newProgress.push("ongoing");
          continue;
        }
        if (new Date(time.ts_to) < currentDay) {
          newProgress.push("done");
          continue;
        }
      }
    }
    setLessonProgress(newProgress)
  }


  const rateLessonMaterials = async (rating: string) => {
    const lessonId = lessons.find((lesson: any) => lesson.name === activeLesson)?._id;
    try {
      const response = await axios.post(`/api/lesson/${lessonId}/rate`, {
        rating: rating
      });

      if (response.status === 200) {
        setLessonRating(rating);
      } else {
        alert("Nepodařilo se uložit hodnocení");
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        alert("Nelze hodnotit znovu nebo hodnocení není povoleno");
      } else {
        alert("Nepodařilo se uložit hodnocení");
      }
    }
  };

  const getSetLessonMaterialsRating = async () => {
    const lessonId = lessons.find((lesson: any) => lesson.name === activeLesson)?._id;
    try {
      const response = await axios.get(`/api/student/lesson/${lessonId}/rating`);

      if (response.status === 200) {
        setLessonRating(response.data.rating);
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setLessonRating("");
      } else {
        throw error;
      }
    }
  };


  return {
    // Variables
    userData: subjectsData,
    subjectNames,
    selectionSubjectNames,
    activeSubject,
    unselectedSubjectNames,
    teachingUnitsNames,
    selectionTeachingUnitsNames,
    activeTeachingUnit,
    unselectedTeachingUnitsNames,
    lessonNames,
    selectionLessons,
    activeLesson,
    unselectedLessons,
    lessonData, 
    savedMaterials,
    lessons,
    studentsEmail,
    studentsClass,
    lessonProgress,
    lessonRating,
    // Functions
    setUserData: setSubjectsData,
    setSubjectNames,
    setActiveSubject,
    setTeachingUnitNames,
    setActiveTeachingUnit,
    setLessonNames,
    setActiveLesson,
    setLessonData,
    setSavedMaterials,
    setLessons,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setUnselectedSubjectNames,
    setUnselectedTeachingUnitsNames,
    setUnselectedLessons,
    setSubjectsInUserData: setSubjectsInSubjectsData,
    setTeachingUnitsInUserData: setTeachingUnitsInSubjectsData,
    setLessonsInUserData: setLessonsInSubjectsData,
    setNamesForDisplay,
    setLessonRating,
    obtainTeachingUnits,
    obtainLessons,
    handleOpenButton,
    rateLessonMaterials
  }
}
