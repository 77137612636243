// Library imports
import { useEffect } from 'react';

// Components
import StudentSelectionMenu from '../components/student/lesson-menu/StudentSelectionMenu';
import StudentInfoMenu from '../components/student/lesson-menu/StudentInfoMenu';
import StudentMaterialsOverview from '../components/student/materials-overview/StudentMaterialsOverview';
import StudentLessonMenuNav from '../components/student/nav/StudentLessonMenuNav';

// Media and stylesheets
import './StudentLessonMenu.scss';

import useLessonMenuLogic from 'components/student/lesson-menu/hooks/useLessonMenuLogic';
import axios from 'utils/axios';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';

export enum MenuTypes {
  SUBJECT = 2,
  TEACHING_UNIT = 3,
  LESSON = 4
}

const StudentLessonMenu = () => {

  const {
    // Variables
    subjectNames,
    selectionSubjectNames,
    activeSubject,
    unselectedSubjectNames,
    teachingUnitsNames,
    selectionTeachingUnitsNames,
    unselectedTeachingUnitsNames,
    lessonNames,
    selectionLessons,
    activeLesson,
    unselectedLessons,
    lessonData,
    savedMaterials,
    studentsEmail,
    studentsClass,
    lessonProgress,
    lessonRating,
    lessons,
    // Functions
    setActiveSubject,
    setActiveTeachingUnit,
    setActiveLesson,
    setSavedMaterials,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setUnselectedSubjectNames,
    setUnselectedTeachingUnitsNames,
    setUnselectedLessons,
    setSubjectsInUserData,
    setTeachingUnitsInUserData,
    setLessonsInUserData,
    obtainLessons,
    handleOpenButton,
    rateLessonMaterials
  } = useLessonMenuLogic();

  // Update "Uložené materiály" section when lessonData changes
  useEffect(() => {
    if (!activeLesson || !lessonData) return
    getMaterials();
  }, [activeLesson, lessonData])

  // Get materials of the lesson from API endpoint
  const getMaterials = async () => {
    if (!lessonData) return
    const lesson = lessons.find((lesson) => lesson.name === activeLesson)
    if (!lesson) return
    const materials = await axios.get("/api/lesson/" + lesson._id + "/" + lesson.teachers_data_id + "/student-materials")
    const newMaterials: any[] = []
    materials.data.files.forEach((file: any) => {
      const newFile = {
        "name": file.name,
        "type": "file",
        "description": file.description,
        "url_to_file": file.url_to_file
      }
      newMaterials.push(newFile)
    })
    materials.data.urls.forEach((url: any) => {
      const newFile = {
        "name": url.name,
        "type": "url",
        "description": url.description,
        "url": ""
      }
      newMaterials.push(newFile)
    })
    setSavedMaterials(newMaterials)
  }

  return (
    <>
      <section className="student-lesson-menu">
        <KeyboardShortcuts context="studentSubjectMenu"></KeyboardShortcuts>
        {/* Navbar */}
        <StudentLessonMenuNav
          lessonData={lessonData}
        />
        {/* Left column */}
        <div className="student-left-column">
          {/* Name of the students class */}
          <div className='student-box-heading'>
            <label className="student-heading-small">{studentsEmail}</label><br />
            <label className="student-heading-medium">{studentsClass}</label>
          </div>
          {/* Menu for selecting "předmět" */}
          <div className="student-select-box">
            <div className='student-select-box-heading'>
              <label className="student-heading-medium">Předměty</label>
            </div>
            <div className='student-elements-box'>
              <StudentSelectionMenu
                list={subjectNames}
                setList={setSubjectsInUserData}
                selectionList={selectionSubjectNames}
                setSelectionList={setSelectionSubjectNames}
                unselected={unselectedSubjectNames}
                setUnselected={setUnselectedSubjectNames}
                setActiveList={setActiveSubject}
                subjectSelect={false}
                type={MenuTypes.SUBJECT}
              ></StudentSelectionMenu>
            </div>
          </div>
          {/* Menu for selecting "výukové celky" */}
          <div className="student-select-box">
            <div className='student-select-box-heading'>
              <label className="student-heading-medium">Výukové celky</label>
            </div>
            <div className='student-elements-box'>
              <StudentSelectionMenu
                list={teachingUnitsNames}
                setList={setTeachingUnitsInUserData}
                selectionList={selectionTeachingUnitsNames}
                setSelectionList={setSelectionTeachingUnitsNames}
                unselected={unselectedTeachingUnitsNames}
                setUnselected={setUnselectedTeachingUnitsNames}
                setActiveList={setActiveTeachingUnit}
                subjectSelect={true}
                obtainLessons={obtainLessons}
                activeSubject={activeSubject}
                type={MenuTypes.TEACHING_UNIT}
              />
            </div>
          </div>
        </div>
        {/* Middle column */}
        <div className="student-middle-column">
          {/* Content for middle column */}
          {/* Menu for selecting "hodiny" */}
          <div className="student-lection">
            <div className='student-select-box-heading'>
              <label className="student-heading-big">Hodiny</label>
            </div>
            <div className='student-select-box-body'>
              <StudentSelectionMenu
                list={lessonNames}
                setList={setLessonsInUserData}
                selectionList={selectionLessons}
                setSelectionList={setSelectionLessons}
                unselected={unselectedLessons}
                setUnselected={setUnselectedLessons}
                setActiveList={setActiveLesson}
                subjectSelect={false}
                lessonSelect={true}
                lessonProgress={lessonProgress}
                type={MenuTypes.LESSON}
                mobile={false}
              />
            </div>
          </div>
          <StudentMaterialsOverview materialsList={savedMaterials} rateLessonMaterials={rateLessonMaterials} lessonRating={lessonRating} />
        </div>
        {/* Right column */}
        <div className="student-right-column">
          {/* Content for right column */}
          <div className='flex flex-col gap-5'>
            <label className="student-heading-medium">Popis hodiny:</label>
            {lessonData ? (<StudentInfoMenu lessonData={lessonData} />) : null}
          </div>
          <div className="student-info-buttons">
            <button className="student-open" disabled={!activeLesson} id={"openButton"} onClick={() => handleOpenButton(activeLesson)}>Otevřít</button>
          </div>
        </div>
      </section>
    </>
  )
}

export default StudentLessonMenu;
