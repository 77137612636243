import "./PublishLesson.scss"
import { PropsWithChildren, useEffect, useState } from "react";
import { Calendar } from 'react-calendar'
import { iClass, iDaysOfUsage, iLessonMenuIndexes, iUserData } from "interfaces/user-data-interface";
import { iLesson } from "interfaces/lesson-activity-interface";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
// SVGs
import { ReactComponent as Next } from "../../images/student-lesson-menu-icons/next.svg";
import { ReactComponent as Previous } from "../../images/student-lesson-menu-icons/previous.svg";

interface PublishLessonProps extends PropsWithChildren<any> {
  userData: iUserData;
  lessonData: iLesson;
  getActiveItemsIndexes: () => iLessonMenuIndexes;
  updateClasses: (classes: iClass[], menuType?: number, publish?: boolean) => void;
  reloadUserData: () => void;
}

const PublishLesson = ({userData, lessonData, getActiveItemsIndexes, updateClasses, reloadUserData}: PublishLessonProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [status, setStatus] = useState<boolean>(false);
  const [lessonSize, setLessonSize] = useState<number>(0);
  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  const [lessonUsage, setLessonUsage] = useState<any>([]);

  useEffect(() => {
    if (!lessonData) {setTimeFrom(null); setTimeTo(null)}
    if (!lessonData || !userData) return;
    const indexes = getActiveItemsIndexes();
    const lesson = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
    .teaching_units[indexes.activeTeachingUnitIndex]
    .lessons[indexes.activeLessonIndex]
    setStatus(lesson.published)
    setLessonUsage(lesson.days_of_usage)
    setLessonSize(lessonData.length_in_mins)
  }, [lessonData])

  const handlePublishButton = async () => {
    if (!userData) return;
    const parsedTimeFrom = new Date(`${selectedDate.toDateString()} ${dayjs(timeFrom).get('hour') + ":" + dayjs(timeFrom).get('minute')}`);
    const parsedTimeTo = new Date(`${selectedDate.toDateString()} ${dayjs(timeTo).get('hour') + ":" + dayjs(timeTo).get('minute')}`);
    
    const overlap = lessonUsage.some((date: iDaysOfUsage) => {
      const dateFrom = new Date(date.ts_from);
      const dateTo = new Date(date.ts_to);

      return parsedTimeFrom < dateTo && parsedTimeTo > dateFrom
    });
    
    if (overlap) {
      alert("Čas použití hodin se nesmí překrývat!")
      return;
    }

    const indexes = getActiveItemsIndexes();
    userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .lessons[indexes.activeLessonIndex].published = true;
    userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .lessons[indexes.activeLessonIndex].days_of_usage.push({"ts_from": parsedTimeFrom.toISOString(), "ts_to": parsedTimeTo.toISOString()})
    await updateClasses(userData.classes, 4, true)
    await reloadUserData()
  }

  const handleTimeChange = (value: Date | null) => {
    setTimeFrom(value)
    if (value) {
      const dayjsValue = dayjs(value);
      const timeToValue = dayjsValue.add(lessonSize, 'minute').toDate();
      setTimeTo(timeToValue);
    } else {
      setTimeTo(null);
    }
  };

  return (
    <section className="publish">
      <label className="heading-medium">Přiřadit hodinu:</label>
      <Calendar
        className="publish-calendar"
        value={selectedDate}
        onChange={(newDate: any) => setSelectedDate(new Date(newDate))}
        locale='cs-CS'
        maxDate={new Date("2050-12-31")}
        minDate={new Date("2023-06-01")}
        tileClassName={'publish-calendar-tile'}
        nextLabel={<Next/>}
        prevLabel={<Previous/>}
        next2Label={null}
        prev2Label={null}
        minDetail={'year'}
        showFixedNumberOfWeeks={true}
      />
      <div className="times-container">
        <div className="time-container">
          <label className="heading-small">Čas (od)</label>
          <TimePicker className={"timepicker"} disabled={!lessonData} slots={undefined} value={timeFrom} ampm={false} onChange={handleTimeChange}/>
        </div>
        <div className="time-container-to">
          <label className="heading-small">Čas (do)</label>
          <TimePicker className={"timepicker"} disabled={!lessonData} value={timeTo} ampm={false} readOnly/>
        </div>
      </div>
      <div className="status-container">
        <label className="heading-medium">Aktualní status:</label>
        <span className="status">{status === false ? "Skryto" : "Publikováno"}</span>
      </div>
      <button className="publish-button" disabled={!lessonData || !timeFrom} onClick={handlePublishButton}>Publikovat</button>
    </section>
  );
};

export default PublishLesson;
