import './LessonPreview.scss';

const LessonPreview = (props: any) => {

  return (
    <>
      {props.activeLessonId && props.activeTeachersId ?
      <iframe
      src={`/pdf/min/student/${props.activeTeachersId}/${props.activeLessonId}`}
      title='Lesson Preview'
      className='lesson-preview-iframe'
      width={"100%"}
      height={"100%"}
    /> : null}
    </>
  )
}
export default LessonPreview;