import '../nav/Nav.scss';
import mainLogo from '../../images/logo-main.svg';
import Dropdown from "../dropdown";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { isMacSafariFirefox } from 'utils/browserUtils';
import { Button } from 'flowbite-react';
import ParentalAccess from './nav/parental-access/ParentalAccess';
import { useModal } from 'contexts/ModalProvider';

const NavStudent = () => {
  const navigate = useNavigate()
  const lessonId = useSelector((state: RootState) => state.editor.lessonId);
  const teachersId = useSelector((state: RootState) => state.editor.teachersId);
  const menu = useSelector((state: RootState) => state.editor.menu);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const {showModal} = useModal();

  const openPDFExport = () => {
    const url = `/pdf/student/${teachersId}/${lessonId}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          throw new Error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  }

  const handleMenu = () => {
    navigate(menu === 'calendar' ? '/calendar' : '/subjects')
  }
  
  return (
    <nav className="nav">
      <img src={mainLogo} alt="main-logo" id={"logoButton"} className="student-logo" onClick={handleMenu}/>
      <ul className="student flex justify-between items-center gap-3">
        <button hidden onClick={openPDFExport} id={"exportButton"}></button>
        <Dropdown menuMiddle={true} label="Soubor">
            <Dropdown.Item
              onClick={() => openPDFExport()}>
              Exportovat do PDF
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleMenu}>
              Zpět do menu
            </Dropdown.Item>
        </Dropdown>
        {userInfo.role === 'student' && <Button color="light" onClick={() => showModal(<ParentalAccess/>)}>Rodičovský přístup</Button>}
      </ul>

    </nav>
  )
}

export default NavStudent
