import React, { useState, useRef } from 'react';
import './Notes.scss';
import { RootState } from '../../../../../redux/store';
import { setNotes } from '../../../../../redux/editorSlice';
import { useSelector, useDispatch } from 'react-redux';

const Notes = () => {
  const dispatch = useDispatch();
  const notes = useSelector((state: RootState) => state.editor.notes)
  const [height, setHeight] = useState(-220);
  const noteRef = useRef<HTMLDivElement>(null);
  const handleOffsetChange = (value: React.SetStateAction<number>) => setHeight(value);

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 4095) return alert("Poznámky nesmí být delší než 4095 znaků!");
    dispatch(setNotes(event.target.value));
  }

  return (
    <div className="notes p-6" style={{bottom: height}} ref={noteRef}>
      <Toggler onOffsetChange={handleOffsetChange} noteRef={noteRef} />
      Poznámky:
      <textarea placeholder="Poznámky" maxLength={4095} value={notes} onChange={handleNotesChange}></textarea>
    </div>
  );
};

const Toggler = (
    {onOffsetChange, noteRef}:
    {
      onOffsetChange: React.Dispatch<React.SetStateAction<number>>,
      noteRef: any
    }
  ) => {
  const [collapsed, setCollapsed] = useState(true);
  const handleTogglerPress = () => {
    if(noteRef.current === undefined || noteRef.current === null)
      return;
    if(noteRef.current.style.bottom === "-220px") {
      noteRef.current.style.bottom = "0px";
      onOffsetChange(0);
      setCollapsed(false);
      return;
    }
    noteRef.current.style.bottom = "-220px";
    onOffsetChange(-220);
    setCollapsed(true);
  };

  return <div className={collapsed ? "collapsed toggler" : "toggler"} onClick={handleTogglerPress}></div>;
};

export default Notes;