import { useState, useEffect, useRef } from 'react';
import './Accessories.scss';
import { ReactComponent as AccessoriesIcon } from "../../../../../images/accessories.svg";

/**
 * A component that shows all the accessories needed for (before, after) the lesson.
 * @returns Accessories component.
 */
const Accessories = (props: any) => {
  const accessoriesRef = useRef<HTMLDivElement>(null);
  const [gadgetsBefore, setGadgetsBefore] = useState<string[] | null>([]);
  const [gadgetsList, setGadgetsList] = useState<string[] | null>([]);
  const [gadgetsAfter, setGadgetsAfter] = useState<string[] | null>([]);

  useEffect(() => {
    const getGadgets = (list: any) => {
      const arr: string[] = [];
      list.forEach((activity: any) =>
        activity.gadgets?.forEach((gadget: string) => {
          if (!arr.includes(gadget) && gadget !== "") arr.push(gadget);
        })
      );
      return arr.length > 0 ? arr : null;
    };
    setGadgetsBefore(getGadgets(props.activitiesBefore));
    setGadgetsAfter(getGadgets(props.activitiesAfter));
    const gadgetsDuring: string[] = [];
    props.activitiesList.forEach((activityList: any) => {
      const gadgets = getGadgets(activityList.activities);
      if (gadgets !== null) {
        gadgets.forEach((gadget: string) => {
          if (!gadgetsDuring.includes(gadget) && gadget !== "") gadgetsDuring.push(gadget);
        });
      }
    });
    setGadgetsList(gadgetsDuring);
  }, [props.activitiesBefore, props.activitiesList, props.activitiesAfter]);

  /**
   * Toggles the accessories div.
   */
  const handleTogglerClick = () => {
    if(accessoriesRef === undefined || accessoriesRef === null || accessoriesRef.current === undefined || accessoriesRef?.current === null) return;
    const acc = accessoriesRef.current;
    if(acc.style.right === "0px") {
      acc.style.right = "-260px";
      acc.classList.remove("collapsed");
      return;
    }
    acc.style.right = "0px";
    acc.classList.add("collapsed");
  }

  return (
    <div id="accessories" ref={accessoriesRef}>
      <div className='toggler' onClick={handleTogglerClick}>
        <AccessoriesIcon className='accessories-icon'/>
      </div>
      <div className="divider first">
          <h3 className='title'>Pomůcky</h3>
      </div>
      <div className='accessories-wrapper'>
        <div className="divider">
            <h4>Před:</h4>
            <ul>
              {gadgetsBefore !== null && gadgetsBefore.length > 0 ?
                  gadgetsBefore?.map((gadget, index) => <li key={index}>{gadget}</li>)
                :
                  <li>Není zde žádná pomůcka</li>
              }
            </ul>
        </div>
        <div className="divider">
            <h4>Během:</h4>
            <ul>
              {gadgetsList !== null && gadgetsList.length > 0 ?
                  gadgetsList?.map((gadget, index) => <li key={index}>{gadget}</li>)
                :
                  <li>Není zde žádná pomůcka</li>
              }
            </ul>
        </div>
        <div className="divider">
            <h4>Po:</h4>
            <ul>
              {gadgetsAfter !== null && gadgetsAfter.length > 0 ?
                  gadgetsAfter?.map((gadget, index) => <li key={index}>{gadget}</li>)
                :
                  <li>Není zde žádná pomůcka</li>
              }
            </ul>
        </div>
      </div>
    </div>
  );
};

export default Accessories;