import './ConfirmModal.scss';
import { KeyboardShortcuts } from '../../../utils/KeyboardShortcuts';
import { useModal } from '../../../contexts/ModalProvider';
// Modal that asks the user, if they want to save the lesson before exiting/creating a new one

const ConfirmModal = (props: any) => {
  const { closeModal } = useModal();

  const onDeclineHandle = () => {
    if (props.onDecline) {
      props.onDecline();
    }
    closeModal();
  };
  
  const onConfirmHandle = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
    closeModal();
  };

  return (
    <div className='save-modal'>
      <KeyboardShortcuts context="confirmModal"></KeyboardShortcuts>
      <h1>{props.title}</h1>
      <label>{props.message}</label>
      <div className='flex gap-3'>
        <button id="closeButtonConfirmModal" className="close-button" onClick={ () => onDeclineHandle() }>Ne</button>
        <button id="saveButtonConfirmModal" className="save-button" onClick={ () => onConfirmHandle() }>Ano</button>
      </div>
    </div>
  )
}
export default ConfirmModal;