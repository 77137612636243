import { useState } from 'react';
import 'components/editor/lesson-window/LessonWindow.scss';
import LessonStudent from '../LessonStudent';
import LessonRow from '../LessonRow';
import MobileLessonHeader from './MobileLessonHeader';
import MobileBottomBar from './MobileBottomBar';
import { useLessonContext } from '../LessonContext';

const MobileLessonWindow = () => {

  const { activities, activitiesBefore, activitiesAfter, lengthOfLesson, lessonName, lessonGoal, lessonCurriculums, materialVisibility } = useLessonContext();

  const [isAnyActivityOpen, setIsAnyActivityOpen] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);

  const handleRowClick = (index: number) => {
    if (openRowIndex === index) setOpenRowIndex(null);
    else setOpenRowIndex(index);
    setIsAnyActivityOpen(true);
  }

  return (
    <div className="lesson-window-mobile pb-12">
      <div className="items-center border-dashed border-b border-gray-900 pl-4 flex-shrink-0 bg-[#8007AA26]">
        <MobileLessonHeader 
          lessonName={lessonName}
          lessonGoal={lessonGoal}
          lessonCurriculums={lessonCurriculums}
        />
      </div>
      {activitiesBefore.length > 0 ?
      <div className={`flex flex-nowrap overflow-x-auto items-center border-dashed border-b border-gray-900 pl-4 ${isAnyActivityOpen ? 'h-auto' : 'h-20'}`}>
        <div className="mr-4 whitespace-nowrap text-black font-semibold select-none text-sm">Před hodinou:</div>
        <LessonRow
          beforeAfter
          key={0}
          activities={activitiesBefore}
          openActivityRow={() => handleRowClick(0)}
          isRowOpen={openRowIndex === 0}
          materialVisibility={materialVisibility}
        />
      </div>
      : null}
      <div className="py-6 overflow-x-auto">
        <LessonStudent activitiesList={activities} lengthOfLesson={lengthOfLesson} openActivityRow={handleRowClick} materialVisibility={materialVisibility}/>
      </div>
      {activitiesAfter.length > 0 ?
      <div className={`flex flex-nowrap overflow-x-auto items-center border-dashed border-t border-gray-900 pl-4 ${isAnyActivityOpen ? 'h-auto' : 'h-20'}`}>
        <div className="mr-4 whitespace-nowrap text-black font-semibold select-none text-sm">Po hodině:</div>
        <LessonRow
          beforeAfter
          key={1}
          activities={activitiesAfter}
          openActivityRow={() => handleRowClick(1)}
          isRowOpen={openRowIndex === 1}
          materialVisibility={materialVisibility}
        />
      </div>
      : null}
      <MobileBottomBar />
    </div>
  );
};

export default MobileLessonWindow;
