import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from "utils/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from 'react';

// password reset logic hook
export default () => {
  type Form = {
    email?: string;
    newPassword?: string;
    confirmPassword?: string;
    apiError: string;
    token?: string;
  }

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState<boolean>(false)
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const formSchemaEmail = yup.object().shape({
    email: yup.string()
      .email('E-mail není ve validním formátu!')
      .max(320, "E-mail nesmí být delší než 320 znaků!")
      .required('Toto pole je povinné!'),
  });

  const formSchemaPassword = yup.object().shape({
    newPassword: yup.string()
      .required("Toto pole je povinné!")
      .min(6, "Heslo musí být minimálně 6 znaků dlouhé!")
      .max(100, "Heslo nesmí být delší než 100 znaků!"),
    confirmPassword: yup.string()
      .required("Toto pole je povinné!")
      .oneOf([yup.ref("newPassword")], "Hesla se neshodují!")
  });

  const { setError: setErrorEmail, register: registerEmail, handleSubmit: handleSumbitEmail, formState: { errors: errorsEmail } } = useForm<Form>({ 
    resolver: yupResolver(formSchemaEmail)
  });

  const { setError: setErrorPassword, register: registerPassword, handleSubmit: handleSubmitPassword, formState: { errors: errorsPassword } } = useForm<Form>({ 
    resolver: yupResolver(formSchemaPassword)
  });

  const resetPassword = (data: Form) => {
    axios.post("/api/reset-password",{
      token: searchParams.get("token"),
      new_password: data.newPassword
    })
    .then(() => {
      setIsSuccessfullySubmitted(true);
      setTimeout(() => {
        navigate("/");
      }, 750);
    }).catch(err => {
      if (err.response.data?.errorMessage?.includes("This token doesn't exist!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Tento token neexistuje!',
        });
      } else if(err.response.data?.errorMessage?.includes("Token already expired!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Platnost tokenu vypršela!',
        });
      } else if(err.response.data?.errorMessage?.includes("This token was already used!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Tento token byl již použit!',
        });
      } else {
        setErrorPassword('apiError', {
          type: "server",
          message: "Někde nastala chyba zkuste to znovu!",
        });
      }
    })
  }

  const requestToken = (data: Form) => {
    axios.post("/api/request-token",{
      email: data.email,
      hostname: window.location.origin
    })
    .then(() => {
      setIsSuccessfullySubmitted(true);
    }).catch(err => {
      if(err.response.data?.errorMessage?.includes("This email is not registered!")) {
        setErrorEmail("apiError", {
          type: "server",
          message: 'Tento email není zaregistrován!',
        });
      } else {
        setErrorEmail('apiError', {
          type: "server",
          message: "Někde nastala chyba zkuste to znovu!",
        });
      }
    })
  }
  
  return {
    registerEmail,
    registerPassword,
    handleSumbitEmail,
    handleSubmitPassword,
    errorsEmail,
    errorsPassword,
    isSuccessfullySubmitted,
    resetPassword,
    requestToken
  }
}
