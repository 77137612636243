import { useState, useEffect } from 'react';
import  { Modal } from '../components/lesson-menu/LessonMenuModal';
import { v4 as uuidv4 } from 'uuid';
import SelectionMenu from '../components/lesson-menu/SelectionMenu';
import InfoMenu from '../components/lesson-menu/InfoMenu';
import { LessonMenuNav } from '../components/lesson-menu/LessonMenuNav';
import './LessonMenu.scss';
import { setLessonId, setSubjectId } from '../redux/editorSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useThrowAsyncError } from '../utils/ErrorHandling';
import { useSnackbar } from '../contexts/SnackbarProvider';
import { ReactComponent as TrashIcon } from "../images/trash.svg";
import { ReactComponent as SettingIcon } from "../images/setting.svg";
// Interfaces
import {
  iUserData,
  iClass,
  iSubject,
  iTeachingUnit,
  iMenuLesson,
  ModalProps,
  iLessonMenuIndexes
} from '../interfaces/user-data-interface';
import { iLesson } from '../interfaces/lesson-activity-interface'
import axios from "../utils/axios";
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { InfoButtons } from 'components/lesson-menu/InfoButtons';
import PublishLesson  from 'components/lesson-menu/PublishLesson';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';

export enum MenuTypes {
  CLASS = 1,
  SUBJECT = 2,
  TEACHING_UNIT = 3,
  LESSON = 4
}

const LessonMenu = () => {
  enum HandlerType {
  ADD = 1,
  EDIT = 2,
  DELETE = 3
  }

  // Variables for Drag and Drop. Had to use let, because useState didn't have time to update itself.
  let originalUserData: iUserData;
  let originalObject: string[];
  let classesToUpdate: iClass[];

  // Variables
  const [userData, setUserData] = useState<iUserData>()
  const [rawClassNames, setRawClassNames] = useState<string[]>([])
  const [rawSubjectNames, setRawSubjectNames] = useState<string[]>([])
  const [classNames, setClassNames] = useState<string[]>([])
  const [subjectNames, setSubjectNames] = useState<string[]>([])
  const [teachingUnitsNames, setTeachingUnitNames] = useState<string[]>([])
  const [lessons, setLessons] = useState<[{ key: string; value: string }] | []>([])
  const [activeClass, setActiveClass] = useState<string>('')
  const [activeSubject, setActiveSubject] = useState<string>('')
  const [activeTeachingUnit, setActiveTeachingUnit] = useState<string>('')
  const [activeLesson, setActiveLesson] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalProps, setModalProps] = useState<ModalProps>()
  const [selectionClassNames, setSelectionClassNames] = useState<string[]>([])
  const [unselectedClassNames, setUnselectedClassNames] = useState<boolean>(true);
  const [selectionSubjectNames, setSelectionSubjectNames] = useState<string[]>([])
  const [unselectedSubjectNames, setUnselectedSubjectNames] = useState<boolean>(true);
  const [selectionTeachingUnitsNames, setSelectionTeachingUnitsNames] = useState<string[]>([])
  const [unselectedTeachingUnitsNames, setUnselectedTeachingUnitsNames] = useState<boolean>(true);
  const [lessonNames, setLessonNames] = useState<string[]>([])
  const [selectionLessons, setSelectionLessons] = useState<string[]>([])
  const [unselectedLessons, setUnselectedLessons] = useState<boolean>(true);
  const [lessonData, setLessonData] = useState<iLesson>();
  const [skipEffect, setSkipEffect] = useState(false);
  const [notesTeachingUnit, setNotesTeachingUnit] = useState('');
  const resetSelections = new Map<string, (values?: string[]) => void>()
  const dataId = useSelector((state: RootState) => state.auth.userInfo.data_id)
  const linearView = useSelector((state: RootState) => state.editor.linearView)
  const [reload, setReload] = useState<boolean>(false)
  const [publishedLessons, setPublishedLessons] = useState<string[]>([])
  const [clipboard, setClipboard] = useState<Blob | null>(null)

  const throwAsyncError = useThrowAsyncError();
  const { openErrorSnackbar } = useSnackbar();

  // Effects
  
  useEffect(() => {
    obtainUserData()
  }, [])
  
  useEffect(() => {
    if (skipEffect) {setSkipEffect(false); return;}
    setLessonData(undefined)
    setLessons([])
    obtainTeachingUnits(activeClass, activeSubject)
    setActiveTeachingUnit('')
    updateSelections(selectionTeachingUnitsNames, setSelectionTeachingUnitsNames, undefined, undefined, setUnselectedTeachingUnitsNames)
  }, [activeClass, activeSubject])

  useEffect(() => {
    if (lessonNames.length === 1 && lessonNames[0] === "") {
      setLessonNames([])
    }
    if (!activeLesson) {
      updateSelections(selectionLessons, setSelectionLessons, undefined, undefined, setUnselectedLessons)
    }
    findPublished()//
  }, [lessons])

  useEffect(() => {
    if (unselectedClassNames) {
      updateSelections(selectionSubjectNames, setSelectionSubjectNames, subjectNames, activeSubject, undefined)
      setActiveClass('')
      setLessons([])
      setLessonNames([])
      setActiveLesson('')
      if (linearView) {
        setActiveSubject('')
        updateSelections(selectionSubjectNames, setSelectionSubjectNames, undefined, undefined, setUnselectedSubjectNames)
        setClassNames(rawClassNames)
      }
    }
  }, [unselectedClassNames])

  useEffect(() => {
    setLessonData(undefined)
    if (!unselectedClassNames) {
      updateSelections(selectionSubjectNames, setSelectionSubjectNames, subjectNames, activeSubject, undefined)
    } 
  }, [activeClass])

  useEffect(() => {
    setLessonData(undefined)
    updateSelections(selectionLessons, setSelectionLessons, lessonNames, activeLesson, undefined)
    const lessonId = lessons.find((lesson) => lesson.value === activeLesson)?.key;
    obtainLessonData(lessonId)
  }, [activeLesson])

  useEffect(() => {
    setLessonData(undefined)
    obtainTeachingUnits(activeClass, activeSubject)
    if (!unselectedSubjectNames) {
      updateSelections(selectionClassNames, setSelectionClassNames, classNames, activeClass, undefined)
    } 
  }, [activeSubject])

  useEffect(() => {
    setLessonData(undefined)
    setLessons([])
    setLessonNames([])
    setActiveLesson('')
    updateSelections(selectionClassNames, setSelectionClassNames, classNames, activeClass, undefined)
  }, [unselectedSubjectNames])

  useEffect(() => {
    if (skipEffect) {setSkipEffect(false); return;}
    setLessonData(undefined)
    updateSelections(selectionLessons, setSelectionLessons, undefined, undefined, setUnselectedLessons)
    setNotesTeachingUnit('')
    setActiveLesson('')
    if (userData && activeTeachingUnit) {
      const indexes = getActiveItemsIndexes()
      const notes = userData.classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .notes
      setNotesTeachingUnit(notes)
    }
    if (unselectedTeachingUnitsNames) {
      setLessons([])
      setLessonNames([])
    }
  }, [activeTeachingUnit])

  useEffect(() => {
    if (linearView) {
      if (!activeClass) {
        setSubjectNames([])
        updateSelections(selectionSubjectNames, setSelectionSubjectNames, undefined, undefined, setUnselectedSubjectNames)
        setActiveSubject("");
        filterClassNames("");
      }
    } else {
      if (activeClass) filterSubjectNames(activeClass);
      if (activeSubject) filterClassNames(activeSubject);
      if (!activeClass && !activeSubject) if (userData) setNamesForDisplay(userData?.classes);
    }
  }, [linearView])

  useEffect(() => {
    setReload(false)
    const lessonId = lessons.find((lesson) => lesson.value === activeLesson)?.key;
    filterSubjectNames(activeClass);
    filterClassNames(activeSubject);
    obtainTeachingUnits(activeClass, activeSubject);
    if (activeTeachingUnit) obtainLessons(activeClass, activeSubject, activeTeachingUnit);
    if (activeLesson) obtainLessonData(lessonId);
  }, [reload])

  // Setting data for menu

  const setClasses = async (newClassNames: string[]) => {
    try {
      if (!userData) throw new Error(`UserData are missing!`)
      originalUserData = {...userData}
      originalObject = [...classNames]

      const newClasses: iClass[] = []
      newClassNames.forEach(element => {
        const newClass = userData.classes.find((x) => x.name === element)
        if (newClass) newClasses.push(newClass)
      });
      userData.classes = newClasses

      setUserData({...userData, classes: newClasses})

      classesToUpdate = [...newClasses]
      setClassNames(newClassNames)
      setRawClassNames(newClassNames)
    } catch (error: any) {
      throw new Error("Error while setting classes: ", error);
    }
  }

  const setSubjects = async (newSubjectNames: string[]) => {
    try {
      if (!userData) throw new Error(`UserData are missing!`)
      originalUserData = {...userData}
      originalObject = [...subjectNames]

      const newSubjects: iSubject[] = []
      const indexes = getActiveItemsIndexes()
      newSubjectNames.forEach(element => {
        const newSubject = userData
          .classes[indexes.activeClassIndex]
          .subjects.find((x) => x.name === element)
        if (newSubject) newSubjects.push(newSubject)
      });

      const newUserData: iUserData = JSON.parse(JSON.stringify(userData));
      if (!newUserData) throw new Error(`UserData are missing!`)
      newUserData.classes[indexes.activeClassIndex].subjects = newSubjects;

      classesToUpdate = [...newUserData.classes]
      setSubjectNames(newSubjectNames)
      setUserData(newUserData);
    } catch (error: any) {
      throw new Error("Error while setting subjects: ", error);
    }
  }

  const setTeachingUnits = async (newTeachingUnitNames: string[]) => {
    try {
      if (!userData) throw new Error(`UserData are missing!`)
      originalUserData = {...userData}
      originalObject = [...teachingUnitsNames]

      const newTeachingUnits: iTeachingUnit[] = []
      const indexes = getActiveItemsIndexes()
      newTeachingUnitNames.forEach(element => {
        const newTeachingUnit = userData
          .classes[indexes.activeClassIndex]
          .subjects[indexes.activeSubjectIndex]
          .teaching_units.find((x) => x.name === element)
        if (newTeachingUnit) newTeachingUnits.push(newTeachingUnit)
      });

      const newUserData: iUserData = JSON.parse(JSON.stringify(userData));
      if (!newUserData) throw new Error(`UserData are missing!`)
      newUserData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex].teaching_units = newTeachingUnits;
      
      classesToUpdate = [...newUserData.classes]
      setTeachingUnitNames(newTeachingUnitNames)
      setUserData(newUserData);
    } catch (error: any) {
      throw new Error("Error while setting teaching units: ", error);
    }
  }

  const setLessonsInUserData = async (newLessonNames: string[]) => {
    try {
      if (!userData) throw new Error(`UserData are missing!`)
      originalUserData = {...userData}
      originalObject = [...lessonNames]

      const newLessons: iMenuLesson[] = []
      const indexes = getActiveItemsIndexes()
      newLessonNames.forEach(element => {
        const newTeachingUnit = userData
          .classes[indexes.activeClassIndex]
          .subjects[indexes.activeSubjectIndex]
          .teaching_units[indexes.activeTeachingUnitIndex]
          .lessons.find((x) => x.name === element)
        if (newTeachingUnit) newLessons.push(newTeachingUnit)
      });

      const newUserData: iUserData = JSON.parse(JSON.stringify(userData));
      if (!newUserData) throw new Error(`UserData are missing!`)
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons = newLessons;

      classesToUpdate = [...newUserData.classes]
      setLessonNames(newLessonNames)
      setUserData(newUserData);
    } catch (error: any) {
      throw new Error("Error while setting lessons in user data: ", error);
    }
  }

  // Functions

  const obtainUserData = async (skipNames = false) => {
    try {
      const response = await axios.get("/api/userdata/" + dataId)
      setUserData(response.data);
      if (!skipNames) setNamesForDisplay(response.data.classes)
    } catch(error) {
      throwAsyncError(error);
      openErrorSnackbar("Data se nepodařilo načíst.");
    }
  }

  const obtainLessons = (
    activeClass: string,
    activeSubject: string,
    activeTeachingUnit: string,
  ) => {
    try {
        if (!userData) throw new Error(`UserData are missing!`)
        const myClass = userData.classes.filter((obj) => obj.name === activeClass)

        if (myClass.length > 1) throw new Error(`Classes contain multiple values for name: ${activeClass}`,)
        const subject = myClass[0].subjects.filter((obj) => obj.name === activeSubject,)

        if (subject.length > 1) throw new Error(`Subjects contain multiple values for name: ${activeSubject}`)
        const teachingUnit = subject[0].teaching_units.filter((obj) => obj.name === activeTeachingUnit,)

        if (teachingUnit.length > 1) throw new Error(`Teaching units contain multiple values for name: ${activeTeachingUnit}`,)
        if (!teachingUnit[0]) return setLessons([])

        const lessons: [{ key: string; value: string }] = [{ key: '', value: '' }]
        teachingUnit[0].lessons.forEach((value, index) => {lessons[index] = { key: value._id, value: value.name }})
        setLessons(lessons)
        const valuesLessons = lessons.map(object => object.value)
        if (valuesLessons[0] !== "") {
          setLessonNames(valuesLessons)
        } else {
          setLessonNames([])
        }
    }
    catch (error: any) {
      console.error(error)
    }
  }

  const setNamesForDisplay = (classes: iClass[]) => {
    try {
      const classNames: string[] = []
      const subjectNames: string[] = []
      classes.forEach(function (value) {
        if (classNames.includes(value.name)) throw new Error('UserData contains wrong data')
        classNames.push(value.name)
        value.subjects.forEach(function (value) {
          if (!subjectNames.includes(value.name)) subjectNames.push(value.name)
        })
      })
      setRawClassNames(classNames)
      setClassNames(classNames)
      setRawSubjectNames(subjectNames)
      if (!linearView) {
        setSubjectNames(subjectNames.sort((a, b) => a.localeCompare(b, 'cs')))
      } else {
        setSubjectNames([])
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const obtainTeachingUnits = (className: string, subjectName: string) => {
    try {
      if (!className || !subjectName) return setTeachingUnitNames([])
      const activeClass = userData?.classes.find(
        (value) => value.name === className,
      )
      if (!activeClass) {
        setLessons([])
        setTeachingUnitNames([])
        return
      }
      const activeSubject = activeClass.subjects.find(
        (value) => value.name === subjectName,
      )
      if (!activeSubject) {
        setLessons([])
        setTeachingUnitNames([])
        return
      }
      const activeTeachingUnitNames: string[] = []
      activeSubject.teaching_units.forEach((value) => {
        if (activeTeachingUnitNames.includes(value.name)) throw new Error( `Teaching units contains duplicated values: teachingUnitNames = ${activeTeachingUnitNames}, value: ${value.name}`,)
        activeTeachingUnitNames.push(value.name)
      })
      setTeachingUnitNames(activeTeachingUnitNames)
    } catch (error: any) {
      console.error(error)
    }
  }

  const filterClassNames = (subjectName: string) => {
    try {
      if (subjectName === '') return setClassNames(rawClassNames)
      if (!userData) throw new Error("UserData are missing! UserData: ", userData )
      const classNames: string[] = []
      for (const myClass of userData.classes) {
        for (const subject of myClass.subjects) {
          if (subject.name === subjectName) classNames.push(myClass.name)
        }
      }
      setClassNames(classNames)
    } catch (error: any) {
      console.error(error)
    }
  }
  const filterSubjectNames = (className: string) => {
    try {
      if (className === '' && !linearView) return setSubjectNames(rawSubjectNames)
      if (className === '' && linearView) return setSubjectNames([])
      if (!userData) throw new Error("UserData are missing! UserData: ", userData )
      const myClass = userData.classes.filter((obj) => {
        return obj.name === className
      })
      if (myClass.length > 1) throw new Error('Classes contains same name!')
      const subjectNames: string[] = []
      myClass[0].subjects.forEach((value) => {
        subjectNames.push(value.name)
      })
      setSubjectNames(subjectNames)
    } catch (error: any) {
      console.error(error)
    }
  }

  const setModal = (handlerType: HandlerType, title:string, label: string, modalCallback: any, curriculum: boolean, defaultInput = "", confirmFormButtonValue = "", classNames: string[] = [], showClassesList = false) => {
    let idOfSubject = undefined
    if (curriculum) {
      if (handlerType === HandlerType.EDIT) {
        idOfSubject = findIdOfSubject();
      }
      else if (handlerType === HandlerType.ADD) {
        idOfSubject = "newSubject";
      }
    }
    try {
      const showConfirmForm = handlerType === HandlerType.DELETE ? true : false 
      const showInput = handlerType === HandlerType.DELETE ? false : true
      const modalProps : ModalProps = {
          title: title,
          label: label,
          confirmFormButtonValue: confirmFormButtonValue,
          showConfirmForm: showConfirmForm,
          inputShow: showInput,
          closeModal: modalCloseCallback,
          callbackFunction: modalCallback,
          curriculumArray: [curriculum, idOfSubject],
          defaultInput: defaultInput,
          showClassesList: showClassesList,
          classNames: classNames
        }
        setModalProps(modalProps)
        setShowModal(true)
    }
    catch (error: any) {
      console.error(error)
    }
  }
  
  //#region Class handlers
  const modalAddClassCallback = async (value: string) => {
    try {
      value = value.trim()
      if (rawClassNames.includes(value)) return alert("Třída se stejným názvem již existuje!")
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Ročník musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")

      const classId = await createClass(value);

      const newUserData = userData

      const newClass :iClass = {
        _id: classId,
        name: value,
        subjects: []
      }

      newUserData.classes.push(newClass)

      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("addClass")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při přidávání třídy došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalEditClassCallback = async (value: string) => {
    try {
      value = value.trim()
      if (rawClassNames.includes(value) && activeClass !== value) return alert("Třída se stejným názvem již existuje!")
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Ročník musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")
      
      const indexes = getActiveItemsIndexes()

      const classId = userData.classes[indexes.activeClassIndex]._id
      const newClassId = await renameClass(classId, value);

      const newUserData = userData

      newUserData.classes[indexes.activeClassIndex].name = value
      newUserData.classes[indexes.activeClassIndex]._id = newClassId

      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("editClass")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler([value])
      setShowModal(false)
    } 
    catch (error: any) {
      openErrorSnackbar("Při editaci třídy došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalDeleteClassCallback = async () => {
    try {
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )

      const classId = userData.classes[getActiveItemsIndexes().activeClassIndex]._id
      await deleteClass(classId)

      const newUserData = userData
      const newClasses = newUserData.classes.filter((item) => item.name !== activeClass)
      newUserData.classes = newClasses
      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("deleteClass")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při mazání třídy došlo k chybě.");
      setShowModal(false);
    }
  } 
//#endregion
  
  //#region Subject handlers
  const modalAddSubjectCallback = async (value: string, curriculum?: any) => { 
    try {
      value = value.trim()
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Předmět musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")

      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      if (newUserData
        .classes[indexes.activeClassIndex]
        .subjects.find((item) => item.name === value)) return alert("Předmět se stejným názvem již existuje!")
      const newSubject :iSubject = {
        _id:uuidv4(),
        name: value,
        teaching_units: []
      }
      newUserData
      .classes[indexes.activeClassIndex]
      .subjects.push(newSubject)
      await updateClasses(newUserData.classes)
      
      if (curriculum.length > 0) {
        await axios.post("/api/curriculum/assign", {
          id_of_subject: newSubject._id,
          assigned_curriculums: curriculum
        })
      }

      setUserData(newUserData)
      const selectionHandler = resetSelections.get("addSubject")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při přidávání předmětu došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalEditSubjectCallback = async (value: string, curriculum: any) => {
    try {
      value = value.trim()
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Předmět musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")
      
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      if (newUserData
        .classes[indexes.activeClassIndex]
        .subjects.find((item) => item.name === value) && activeSubject !== value) return alert("Předmět se stejným názvem již existuje!")

      if (newUserData
        .classes[indexes.activeClassIndex]
        .subjects.find((item) => item.name !== value)) {
          newUserData
            .classes[indexes.activeClassIndex]
            .subjects[indexes.activeSubjectIndex]
            .name = value
        }

      if (curriculum) {
        const idOfSubject = newUserData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]._id
        await axios.post("/api/curriculum/assign", {
          id_of_subject: idOfSubject,
          assigned_curriculums: curriculum
        })
      }
      await updateClasses(newUserData.classes)

      setUserData(newUserData)
      const selectionHandler = resetSelections.get("editSubject")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler([activeClass, value])
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při editaci předmětu došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalDeleteSubjectCallback = async () => {
    try {
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      const newSubjects = newUserData.classes[indexes.activeClassIndex].subjects.filter((item) => item.name !== activeSubject)
      newUserData.classes[indexes.activeClassIndex].subjects = newSubjects
      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("deleteSubject")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při mazání předmětu došlo k chybě.");
      setShowModal(false);
    }
  } 
  //#endregion

  //#region TeachingUnit handlers
  const modalAddTeachingUnitCallback = async (value: string) => {
    try {
      value = value.trim()
      if (teachingUnitsNames.includes(value)) return alert("Výukový celek se stejným názvem již existuje!")
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Výukový celek musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")

      const newUserData = userData
      const newTeachingUnit: iTeachingUnit = {
        _id:uuidv4(),
        name: value,
        notes: "",
        lessons: []
      }
      const indexes = getActiveItemsIndexes()
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units.push(newTeachingUnit)

      await updateClasses(newUserData.classes)

      setUserData(newUserData)
      const selectionHandler = resetSelections.get("addTeachingUnit")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při přidávání výukového celku došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalEditTeachingUnitCallback = async (value: string) => {
    try {
      value = value.trim()
      if (teachingUnitsNames.includes(value)  && activeTeachingUnit !== value) return alert("Výukový celek se stejným názvem již existuje!")
      if(!userData) throw new Error("UserData are missing! UserData: ", userData)
      if(value === "") return alert("Výukový celek musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")
  
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .name = value
      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("editTeachingUnit")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler([activeClass, activeSubject, value])
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při editaci výukového celku došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalDeleteTeachingUnitCallback = async () => {
    try {
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      const newTeachingUnit = newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units
        .filter((item) => item.name !== activeTeachingUnit)
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units = newTeachingUnit
      await updateClasses(newUserData.classes)
      setUserData(newUserData)
      const selectionHandler = resetSelections.get("deleteTeachingUnit")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při mazání výukového celku došlo k chybě.");
      setShowModal(false);
    }
  } 
  //#endregion

  //#region Lesson handlers
  const modalAddLessonCallback = async (value: string) => {
    try {
      value = value.trim()
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if(value === "") return alert("Hodina musí mít název!")
      if(value.length > 63) return alert("Název nesmí být delší než 63 znaků!")

      const newUserData = userData
      const newLesson :iMenuLesson = {
        _id:uuidv4(),
        name: value,
        published: false,
        days_of_usage: []
      }
      const indexes = getActiveItemsIndexes()
      if (newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons.find((item) => (item.name === value))) return alert("Hodina se stejným názvem již existuje!")

      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons.push(newLesson)

      // this is used to run multiple promises at once
      await Promise.all([
        updateClasses(newUserData.classes),
        updateLesson(newLesson._id, newLesson.name)
      ]);

      // everything down here is executed only if the above promises are resolved successfully
      setUserData(newUserData)
      setActiveLesson(value)
      setUnselectedLessons(false)
      const selectionHandler = resetSelections.get("addLesson")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při přidávání hodiny došlo k chybě.");
      setShowModal(false)
    }
  }
  const modalEditLessonCallback = async (lessonName: string) => {
    try {
      lessonName = lessonName.trim()
      if (!lessons) throw new Error("Lessons are empty!")
      if (lessons.find((value) => (value.value === lessonName))  && activeLesson !== lessonName) return alert("Hodina se stejným názvem již existuje!")
      if(!userData) throw new Error("UserData are missing! UserData: ", userData)
      if(lessonName === "") return alert("Hodina musí mít název!")
      if(lessonName.length > 63) return alert("Název nesmí být delší než 63 znaků!")
  
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons[indexes.activeLessonIndex]
        .name = lessonName

      await Promise.all([
        updateClasses(newUserData.classes),
        updateLesson(newUserData.classes[indexes.activeClassIndex]
          .subjects[indexes.activeSubjectIndex]
          .teaching_units[indexes.activeTeachingUnitIndex]
          .lessons[indexes.activeLessonIndex]
          ._id, lessonName)
      ]);

      setUserData(newUserData)
      const selectionHandler = resetSelections.get("editLesson")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler([activeClass, activeSubject, activeTeachingUnit, lessonName])
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při editaci hodiny došlo k chybě.");
      setShowModal(false);
    }
  }
  const modalDeleteLessonCallback = async () => {
    try {
      if(!userData) throw new Error("UserData are missing! UserData: ", userData )
      if (!lessons) throw new Error("Lessons are empty!")
      const newUserData = userData
      const indexes = getActiveItemsIndexes()
      const deletedLesson = lessons.filter((item) => item.value === activeLesson)[0]
      const newLessons = newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons
        .filter((item) => item.name !== activeLesson)
      newUserData
        .classes[indexes.activeClassIndex]
        .subjects[indexes.activeSubjectIndex]
        .teaching_units[indexes.activeTeachingUnitIndex]
        .lessons = newLessons

      await Promise.all([
        updateClasses(newUserData.classes),
        updateLesson(deletedLesson.key, "")
      ]);

      setUserData(newUserData)
      const selectionHandler = resetSelections.get("deleteLesson")
      if (!selectionHandler) throw new Error("Method doesn't exists!");
      selectionHandler()
      setShowModal(false)
    }
    catch (error: any) {
      openErrorSnackbar("Při mazání hodiny došlo k chybě.");
      setShowModal(false);
    }
  } 

  const handleCopy = async () => {
    if (clipboard) {
      openErrorSnackbar("Před kopírováním je třeba vložit obsah schránky.");
      return;
    }
    if (!lessonData) return;

    try {
      const response = await axios.get(`/api/export-lesson/${lessonData._id}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/zip' });
      setClipboard(blob);
    } catch (error) {
      openErrorSnackbar("Kopírování se nepodařilo."); 
    }
  };
  
  const handleCut = async () => {
    if (clipboard) {
      openErrorSnackbar("Před vyjmutím je třeba vložit obsah schránky.");
      return;
    }
    if (!userData || !lessonData) return;
  
    await handleCopy();

    try {
      await modalDeleteLessonCallback();
    } catch (error) {
      openErrorSnackbar("Hodinu se nepodařilo odstranit.");
    }
  };
  
  const handlePaste = async () => {
    if (!clipboard || !userData) return;
    if (activeTeachingUnit === "") {
      openErrorSnackbar("Nejprve vyberte výukový celek, do kterého chcete hodinu vložit.");
      return;
    }
  
    try {
      const indexes = getActiveItemsIndexes();
      const id_of_teaching_unit = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex].teaching_units[indexes.activeTeachingUnitIndex]._id;
  
      const formData = new FormData();
      formData.append('file', clipboard, 'lesson.zip');
      formData.append('id_of_teaching_unit', id_of_teaching_unit);
  
      await axios.post("/api/import-lesson", formData);
      await reloadUserData();
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        openErrorSnackbar("Jedna kopie již existuje! Přejmenujte ji a zkuste to znovu.");
      } else {
        openErrorSnackbar("Vložení se nepodařilo.");
      }
    }
  
    setClipboard(null);
  };
  
  //#endregion

  const modalCloseCallback = () => {
    setModalProps(undefined)
    setShowModal(false)
  } 

  // Reseting selections, when action is made
  resetSelections.set('addClass', () => {
    if (userData) {setNamesForDisplay(userData.classes)}
    if (activeSubject) { filterClassNames(activeSubject) }
    if (activeClass) { filterSubjectNames(activeClass) }
  })

  resetSelections.set('editClass', (values?: string[]) => {
    if (!values) {return;}
    if (values[0] === activeClass) {return;}
    setSkipEffect(true)
    setActiveClass(values[0])
    if (userData) {setNamesForDisplay(userData.classes)}
    if (activeSubject) {filterClassNames(activeSubject)}
    filterSubjectNames(values[0])
    if (activeTeachingUnit) {obtainTeachingUnits(values[0], activeSubject)}
    if (activeLesson) {obtainLessons(values[0], activeSubject, activeTeachingUnit)}
  })

  resetSelections.set('deleteClass', () => {
    if (userData) {setNamesForDisplay(userData.classes)}
    setSkipEffect(true)
    setActiveClass('')
    setUnselectedClassNames(true)
    setSelectionClassNames([])
  })

  resetSelections.set('addSubject', () => {
    if (userData) {setNamesForDisplay(userData.classes)}
    if (activeSubject) { filterClassNames(activeSubject) }
    if (activeClass) { filterSubjectNames(activeClass) }
  })

  resetSelections.set('editSubject', (values?: string[]) => {
    if (!values) {return;}
    if (values[1] === activeSubject) {return;}
    if (userData) {setNamesForDisplay(userData.classes)}
    filterClassNames(values[1])
    setSkipEffect(true)
    setActiveSubject(values[1])
    filterSubjectNames(values[0])
    setActiveClass(activeClass)
    if (activeTeachingUnit) {obtainTeachingUnits(activeClass, values[1])}
    if (activeLesson) {obtainLessons(activeClass, values[1], activeTeachingUnit)}
  })

  resetSelections.set('deleteSubject', () => {
    if (userData) {setNamesForDisplay(userData.classes)}
    setSkipEffect(true)
    setActiveSubject('')
    setUnselectedSubjectNames(true)
    setSelectionSubjectNames([])
    filterSubjectNames(activeClass)
    setTeachingUnitNames([])
    if (activeTeachingUnit) {
      setActiveTeachingUnit('')
      setUnselectedTeachingUnitsNames(true)
      setSelectionTeachingUnitsNames([])
      if (activeLesson) {
        setActiveLesson('')
        setUnselectedLessons(true)
        setSelectionLessons([])
      }
      setLessonNames([])
    }
  })

  resetSelections.set('addTeachingUnit', () => {
    obtainTeachingUnits(activeClass, activeSubject)
  })

  resetSelections.set('editTeachingUnit', (values?: string[]) => {
    if (!values) {return;}
    if (values[2] === activeTeachingUnit) {return;}
    setSkipEffect(true)
    setActiveTeachingUnit(values[2])
    obtainTeachingUnits(activeClass, activeSubject)
    filterSubjectNames(activeClass)
    if (activeLesson) {obtainLessons(activeClass, activeSubject, values[2])}
  })

  resetSelections.set('deleteTeachingUnit', () => {
    setSkipEffect(true)
    setActiveTeachingUnit('')
    setUnselectedTeachingUnitsNames(true)
    setSelectionTeachingUnitsNames([])
    obtainTeachingUnits(activeClass, activeSubject)
    setActiveLesson('')
    setUnselectedLessons(true)
    setSelectionLessons([])
    setLessonNames([])
  })

  resetSelections.set('addLesson', () => {
    obtainLessons(activeClass, activeSubject, activeTeachingUnit)
  })

  resetSelections.set('editLesson', (values?: string[]) => {
    if (!values) {return;}
    if (values[3] === activeLesson) {return;}
    obtainLessons(activeClass, activeSubject, activeTeachingUnit)
    setActiveLesson(values[3])
  })

  resetSelections.set('deleteLesson', () => {
    //setSkipEffect(true) leaving this here if it is needed in the future but it causes selection menu bugs
    setActiveLesson('')
    setUnselectedLessons(true)
    setSelectionLessons([])
    obtainLessons(activeClass, activeSubject, activeTeachingUnit)
  })

  const findIdOfSubject = () => {
    const indexes = getActiveItemsIndexes()
    return userData?.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]._id
  }

  const findPublished = () => {
    if (!userData || !lessons || !activeTeachingUnit) return;
    const newPublishedLessons: string[] = []
    const indexes = getActiveItemsIndexes()
    if (!userData.classes[indexes.activeClassIndex]) return;
    const rawLessons = userData.classes[indexes.activeClassIndex].subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex].lessons
    rawLessons.forEach(lesson => {
      if (lesson.published) newPublishedLessons.push(lesson.name);
    });
    setPublishedLessons(newPublishedLessons)
  }

  const updateClasses = async (classes?: iClass[], menuType?: number, publish?: boolean) => {
    try {
      if (!classes) classes = classesToUpdate
      const payload = {
        publish: publish ? true : false,
        classes
      };
      await axios.post("/api/classes", payload);
    } catch (error: any) {
      if (menuType) restoreUserData(menuType);
      if (error.response.data.message === "Days of usage are overlaping!") {alert("Jiná hodina je v tuto dobu již publikována!");return}
      throw new Error("updateClasses axios request failed:", error);
    }
  }

  const createClass = async (name: string): Promise<string> => {
    const res = await axios.post("/api/class/create", {
      class_name: name,
    });
    return res.data.class_id;
  }

  const renameClass = async (classId: string, newName: string): Promise<string> => {
    const res = await axios.post(`/api/class/${classId}/rename`, {
      new_class_name: newName,
    });
    return res.data.class_id;
  }

  const deleteClass = async (classId: string) => {
    return axios.post(`/api/class/${classId}/delete`);
  }




  const restoreUserData = (menuType: number) => {
    if (!originalUserData || !originalObject) return;
    setUserData({...originalUserData})
    switch (menuType) {
      case 1: {
        setClassNames([...originalObject])
        break;
      }
      case 2: {
        setSubjectNames([...originalObject])
        break;
      }
      case 3: {
        setTeachingUnitNames([...originalObject])
        break;
      }
      case 4: {
        setLessonNames([...originalObject])
        break;
      }
    }
  }

  const getActiveItemsIndexes = (): iLessonMenuIndexes => {
    // This should maybe return null value insted of -1
    // It could make a bug with -1
    const indexes: iLessonMenuIndexes = {
      activeClassIndex: -1,
      activeSubjectIndex: -1,
      activeTeachingUnitIndex: -1,
      activeLessonIndex: -1
    }
    if(!userData) return indexes
    // Active class
    if (!activeClass) return indexes
    indexes.activeClassIndex = userData.classes.findIndex((value) => value.name === activeClass )

    // Active subject
    if (!activeSubject) return indexes
    indexes.activeSubjectIndex = userData.classes[indexes.activeClassIndex]
      .subjects.findIndex((value) => value.name === activeSubject )

    // Active teaching unit
    if (!activeTeachingUnit) return indexes
    indexes.activeTeachingUnitIndex = userData.classes[indexes.activeClassIndex]
      .subjects[indexes.activeSubjectIndex]
      .teaching_units.findIndex((value) => value.name === activeTeachingUnit)

    // Active lesson
    if (!activeLesson) return indexes
    indexes.activeLessonIndex = userData.classes[indexes.activeClassIndex]
      .subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .lessons.findIndex((value) => value.name === activeLesson)
    
    return indexes
    
  }

  const updateSelections = (selection: Array<string>, setSelection: React.Dispatch<React.SetStateAction<string[]>>, list?: Array<string>, active?: string, unselected?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const newSelection = new Array(selection.length);
    newSelection.fill("unselected")
    if (list && active) {
      const indexOfActiveItem = list.indexOf(active)
      newSelection[indexOfActiveItem] = "selected"
    }
    if (unselected) {
      unselected(true)
    }
    setSelection(newSelection)
  }

  const handleNotesChange = async (e: any) => {
    if(e.target.value.length > 4095) return alert("Poznámky nesmí být delší než 4095 znaků!")
    setNotesTeachingUnit(e.target.value)
    if(!userData) throw new Error("UserData are missing! UserData: ", userData )
    const indexes = getActiveItemsIndexes()
    const newUserData = userData
    newUserData.classes[indexes.activeClassIndex]
      .subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .notes = e.target.value
    setUserData(newUserData)

    // i left this at the end because it might slow down the typing
    await updateClasses(newUserData.classes)
  }

  const updateLesson = async (_id: string, name: string) => {
    try {
      await axios.post("/api/update-lesson", { _id, name });
    } catch (error: any) {
      throw new Error("updateLesson axios request failed:", error);
    }
  };

  const reloadUserData = async () => {
    await obtainUserData(true);
    if (!userData) throw new Error("UserData are missing! UserData: ", userData);
    setReload(true)
  }

  const obtainLessonData = async (lessonId: string | undefined ) => {
    if (!lessonId) return
    try {
      const response = await axios.get("/api/lesson/" + lessonId)
      setLessonData(response.data)
    } catch (error) {
      throwAsyncError(error);
      openErrorSnackbar("Data se nepodařilo načíst.");
    }
  }

  return (
    <>
      <section className="lesson-menu">
        <KeyboardShortcuts context="lessonMenu"></KeyboardShortcuts>
        {/* Navbar */}
        <LessonMenuNav
          userData={userData}
          activeClass={activeClass}
          activeSubject={activeSubject}
          activeTeachingUnit={activeTeachingUnit}
          getActiveItemsIndexes={getActiveItemsIndexes}
          reloadUserData={reloadUserData}
        />
        {/* Left column */}
        <div className="left-column">
          {/* Dropdown for selecting "ročník" */}
          <div className="select-box">
            <div className='select-box-heading'>
              <label className="heading-medium">Třída</label>
              <div className='icons'>
                <div className="tooltip-container">
                  <button onClick={() => setModal(HandlerType.ADD, "Vytvoření nové třídy", "Název třídy: ", modalAddClassCallback, false, "", "", classNames, true)} disabled={(activeSubject !== '')}>
                    <FontAwesomeIcon className={`plus ${(activeSubject !== '') ? 'disabled' : ''}`} icon={solid("plus")} />
                  </button>
                  {activeSubject !== '' && (
                    <span className="tooltip-text" style={{marginTop: 1}}>
                      Ročník nelze vytvořit, pokud máte vybraný předmět!
                    </span>
                  )}
                </div>
                <div className="tooltip-container">
                  <button onClick={() =>setModal(HandlerType.DELETE,`Smazání třídy`,`Opravdu chcete smazat třídu '${activeClass}'?`,modalDeleteClassCallback,false,'','Smazat',)} style={{ color: 'red' }} disabled={!activeClass || activeSubject !== ''}>
                  <TrashIcon className={`trash ${(!activeClass || activeSubject !== '') ? 'disabled' : ''}`} />
                  </button>
                  {activeSubject !== '' && (
                    <span className="tooltip-text">
                      Ročník nelze smazat, pokud máte vybraný předmět!
                    </span>
                  )}
                </div>
                 <button onClick={() => setModal(HandlerType.EDIT, `Úprava třídy '${activeClass}'.`, `Zadejte nový název třídy: `, modalEditClassCallback, false, activeClass)} style={{padding:5, color:'blue'}} disabled={!activeClass}>
                  <SettingIcon className={`setting ${!activeClass ? 'disabled' : ''}`} />
              </button>
              </div>
            </div>
            <div className='elements-box'>
              <SelectionMenu
                list={classNames}
                setList={setClasses}
                selectionList={selectionClassNames}
                setSelectionList={setSelectionClassNames}
                unselected={unselectedClassNames}
                setUnselected={setUnselectedClassNames}
                setActiveList={setActiveClass}
                filter={filterSubjectNames}
                subjectSelect={false}
                type={MenuTypes.CLASS}
                activeSubject={activeSubject}
                updateClasses={updateClasses}
              ></SelectionMenu>
            </div>
          </div>
          {/* Dropdown for selecting "předmět" */}
          <div className="select-box">
            <div className='select-box-heading'>
              <label className="heading-medium">Předmět</label>
              <div className='icons'>
                <div className="tooltip-container">
                  <button onClick={() => setModal(HandlerType.ADD, `Vytvoření nového předmětu do třídy '${activeClass}'.`, "Název předmětu: ", modalAddSubjectCallback, true)} disabled={activeClass === ''}>
                    <FontAwesomeIcon className={`plus ${activeClass === '' ? 'disabled' : ''}`} icon={solid("plus")} />
                  </button>
                  {activeClass === '' && (
                      <span className="tooltip-text" style={{marginTop: 1}}>
                        Předmět nelze vytvořit, pokud nemáte vybraný ročník!
                      </span>
                    )}
                </div>
                <button onClick={() => setModal(HandlerType.DELETE, "Smazání předmětu", `Opravdu chcete smazat předmět '${activeSubject}' z třídy '${activeClass}'?`, modalDeleteSubjectCallback, false, "", "Smazat")} style={{color:'red'}} disabled={activeClass === '' || activeSubject === ''}>
                  <TrashIcon className={`trash ${activeClass === '' || activeSubject === '' ? 'disabled' : ''}`} />
                </button>
                <button onClick={() => setModal(HandlerType.EDIT, `Úprava předmětu '${activeSubject}' z třídy '${activeClass}'.`, `Zadejte nový název předmětu: `, modalEditSubjectCallback, true, activeSubject)} style={{padding:5, color:'blue'}} disabled={activeClass === '' || activeSubject === ''}>
                  <SettingIcon className={`setting ${activeClass === '' || activeSubject === '' ? 'disabled' : ''}`} />
                </button>
              </div>
            </div>
            <div className='elements-box'>
              <SelectionMenu
                list={subjectNames}
                setList={setSubjects}
                selectionList={selectionSubjectNames}
                setSelectionList={setSelectionSubjectNames}
                unselected={unselectedSubjectNames}
                setUnselected={setUnselectedSubjectNames}
                setActiveList={setActiveSubject}
                filter={filterClassNames}
                subjectSelect={false}
                type={MenuTypes.SUBJECT}
                unselectedClassNames={unselectedClassNames}
                updateClasses={updateClasses}
              ></SelectionMenu>
            </div>
          </div>
          {/* Dropdown for selecting "výukové celky" */}
          <div className="select-box">
            <div className='select-box-heading'>
              <label className="heading-medium">Výukový celek</label>
              <div className='icons'>
                <div className="tooltip-container">
                  <button onClick={() => setModal(HandlerType.ADD, `Přidání výukového celku do přemětu '${activeSubject}' z třídy '${activeClass}'.`, "Název výukového celku: ", modalAddTeachingUnitCallback, false)} disabled={activeClass === '' || activeSubject === ''}>
                    <FontAwesomeIcon className={`plus ${activeClass === '' || activeSubject === '' ? 'disabled' : ''}`} icon={solid("plus")} />
                  </button>
                  {activeSubject === '' && (
                    <span className="tooltip-text" style={{marginTop: 1}}>
                      Výukový celek nelze vytvořit, pokud nemáte vybraný přdmět!
                    </span>
                  )}
                </div>
                <button onClick={() => setModal(HandlerType.DELETE, "Smazání výukového celku", `Opravdu chcete smazat výukový celek '${activeTeachingUnit}' z předmětu '${activeSubject}' z třídy '${activeClass}'?`, modalDeleteTeachingUnitCallback, false, "", "Smazat")} style={{color:'red'}} disabled={activeTeachingUnit === ''}>
                  <TrashIcon className={`trash ${activeTeachingUnit === '' ? 'disabled' : ''}`} />
                </button>
                <button onClick={() => setModal(HandlerType.EDIT, `Úprava výukového celku '${activeTeachingUnit}' z předmětu '${activeSubject}' z třídy '${activeClass}'.`, `Zadejte nový název výukového celku: `, modalEditTeachingUnitCallback, false, activeTeachingUnit)} style={{padding:5, color:'blue'}} disabled={activeTeachingUnit === ''}>
                  <SettingIcon className={`setting ${activeTeachingUnit === '' ? 'disabled' : ''}`} />
                </button>
              </div>
            </div>
            <div className='elements-box'>
              <SelectionMenu
                list={teachingUnitsNames}
                setList={setTeachingUnits}
                selectionList={selectionTeachingUnitsNames}
                setSelectionList={setSelectionTeachingUnitsNames}
                unselected={unselectedTeachingUnitsNames}
                setUnselected={setUnselectedTeachingUnitsNames}
                setActiveList={setActiveTeachingUnit}
                subjectSelect={true}
                obtainLessons={obtainLessons}
                activeClass={activeClass}
                type={MenuTypes.TEACHING_UNIT}
                activeSubject={activeSubject}
                updateClasses={updateClasses}
              ></SelectionMenu>
            </div>
          </div>
        </div>
        {/* Middle column */}
        <div className="middle-column">
          {/* Content for middle column */}
          <div className="lection">
            <div className='select-box-heading'>
              <label className="heading-big">Hodiny</label>
              <div className='icons'>
                <div className="tooltip-container">
                  <button onClick={() => setModal(HandlerType.ADD, `Přidání hodiny do výukového celku '${activeTeachingUnit}' v předmětu '${activeSubject}' ve třídě '${activeClass}'.`, "Název nové hodiny: ", modalAddLessonCallback, false)} disabled={activeTeachingUnit === ''}>
                    <FontAwesomeIcon className={`plus big ${activeTeachingUnit === '' ? 'disabled' : ''}`} icon={solid("plus")} />
                  </button>
                  {activeSubject === '' && (
                    <span className="tooltip-text" style={{marginTop: 1}}>
                      Hodina nelze vytvořit, pokud nemáte vybraný výukový celek!
                    </span>
                  )}
                </div>
                <button onClick={() => setModal(HandlerType.DELETE,  "Smazání hodiny", `Opravdu chcete smazat hodinu '${activeLesson}' z výukového celku '${activeTeachingUnit}' z předmětu '${activeSubject}' z třídy '${activeClass}'?`, modalDeleteLessonCallback, false, "", "Smazat")} style={{color:'red'}} disabled={activeLesson === ''}>
                  <TrashIcon className={`trash big ${activeLesson === '' ? 'disabled' : ''}`} />
                </button>
                <button onClick={() => setModal(HandlerType.EDIT, `Úprava hodiny '${activeLesson}' z výukového celku '${activeTeachingUnit}' z předmětu '${activeSubject}' z třídy '${activeClass}'.`, `Zadejte nový název hodiny: `, modalEditLessonCallback, false, activeLesson)} style={{padding:5, color:'blue'}} disabled={activeLesson === ''}>
                  <SettingIcon className={`setting big ${activeLesson === '' ? 'disabled' : ''}`} />
                </button>
              </div>
            </div>
            <div className='select-box-body'>
              <div className='selection-menu'>
            <SelectionMenu
              list={lessonNames}
              setList={setLessonsInUserData}
              selectionList={selectionLessons}
              setSelectionList={setSelectionLessons}
              unselected={unselectedLessons}
              setUnselected={setUnselectedLessons}
              setActiveList={setActiveLesson}
              subjectSelect={false}
              type={MenuTypes.LESSON}
              lessonSelect={true}
              updateClasses={updateClasses}
              publishedLessons={publishedLessons}
              findPublished={findPublished}
              handleCopy={handleCopy}
              handleCut={handleCut}
              handlePaste={handlePaste}
            />
          </div>
          </div>
          </div>
          <div className="desc">
            <label className="heading-medium">Popis výukového celku:</label>
            <textarea className='desc-menu resize-none rounded-md w-full h-32' maxLength={4095} disabled={!activeTeachingUnit} value={notesTeachingUnit} onChange={handleNotesChange}></textarea>
            {/* Nevím jestli je úplně chytrý to dělat onChange. Protože při každý změně se odešle post request na backend. */}
          </div>
        </div>
        {/* Right column */}
        <div className="right-column">
          {/* Content for right column */}
          <div className='info-wrapper flex flex-col gap-2 p-5 informations-container'>
            <label className="heading-medium">Informace:</label>
            {lessonData ? (
                <InfoMenu 
                  lessonData={lessonData}
                  userData={userData}
                  getActiveItemsIndexes={getActiveItemsIndexes}
                  updateClasses={updateClasses}
                  reloadUserData={reloadUserData}
                ></InfoMenu>
            ) : null }
          </div>
          <PublishLesson
            userData={userData}
            lessonData={lessonData}
            getActiveItemsIndexes={getActiveItemsIndexes}
            updateClasses={updateClasses}
            reloadUserData={reloadUserData}
          />
          <InfoButtons 
            userData={userData}
            activeTeachingUnit={activeTeachingUnit}
            activeLesson={activeLesson}
            lessons={lessons}
            getActiveItemsIndexes={getActiveItemsIndexes}
            setLessonId={setLessonId}
            setSubjectId={setSubjectId}
            />
        </div>
      </section>
      <div>
        {showModal && modalProps && 
          <Modal 
            title= {modalProps.title}
            confirmFormButtonValue= {modalProps.confirmFormButtonValue}
            label= {modalProps.label}
            showConfirmForm= {modalProps.showConfirmForm}
            showClassesList= {modalProps.showClassesList}
            inputShow= {modalProps.inputShow}
            closeModal= {modalProps.closeModal}
            callbackFunction= {modalProps.callbackFunction}
            curriculumArray={modalProps.curriculumArray}
            defaultInput={modalProps.defaultInput}
            classNames={modalProps.classNames}/> }
      </div>
    </>
  )
}

export default LessonMenu;
