import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Editor from './pages/Editor';
import LessonMenu from "./pages/LessonMenu";
import Login from './pages/Login';
import Registration from "./pages/Registration";
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import SnackbarProvider from "./contexts/SnackbarProvider";
import { ModalProvider } from "./contexts/ModalProvider";
import { setError } from './redux/errorSlice';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from './utils/ErrorHandling';
import ViewSelection from "./pages/ViewSelection";
import StudentLessonMenu from "./pages/StudentLessonMenu";
import LessonView from './pages/LessonView';
import StudentCalendarMenu from 'pages/StudentCalendarMenu';
import PasswordResetEmail from './pages/PasswordResetEmail';
import PasswordResetNewPassword from 'pages/PasswordResetNewPassword';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileLessonMenu from 'components/student/lesson-menu/MobileLessonMenu';
import InfoPage from 'pages/InfoPage';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Global error handler for uncaught exceptions
    window.onerror = (message, source, lineno, colno, error) => {
      console.error('App handler: Uncaught Error:', { message, source, lineno, colno, error });
      dispatch(setError(JSON.stringify({ message, source, lineno, colno, error })));
    }

    // Global handler for unhandled promise rejections
    window.onunhandledrejection = (event) => {
      console.error('App handler: Unhandled Promise Rejection:', event.promise, 'Reason:', event.reason);
      dispatch(setError(JSON.stringify({ event })));
    }
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs }>
            <ModalProvider>
              <Routes>
                <Route path="/" element={<ProtectedRoute redirectLoggedUser><Login /></ProtectedRoute>}></Route>
                <Route path="/editor" element={<ProtectedRoute userIsNeeded allowedRoles={['teacher']}><Editor /></ProtectedRoute>}></Route>
                <Route path="/registration" element={<ProtectedRoute redirectLoggedUser><Registration /></ProtectedRoute>}></Route>
                <Route path="/lesson-menu" element={<ProtectedRoute userIsNeeded allowedRoles={['teacher']}><LessonMenu /></ProtectedRoute>}></Route>
                <Route path="/view-selection" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><ViewSelection /></ProtectedRoute>}></Route>
                <Route path="/subjects/*" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><ResponsiveComponent desktop={<StudentLessonMenu />} mobile={<MobileLessonMenu />}/></ProtectedRoute>}/>
                <Route path="/lesson-view" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><LessonView/></ProtectedRoute>}></Route>
                <Route path="/calendar" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><StudentCalendarMenu/></ProtectedRoute>}></Route>
                <Route path="/changepassword" element={<ProtectedRoute redirectLoggedUser><PasswordResetEmail/></ProtectedRoute>}></Route>
                <Route path="/newpassword" element={<ProtectedRoute redirectLoggedUser><PasswordResetNewPassword/></ProtectedRoute>}></Route>
                <Route path="/about" element={<ProtectedRoute><InfoPage page={"about"}/></ProtectedRoute>}></Route>
                <Route path="/contacts" element={<ProtectedRoute><InfoPage page={"contacts"}/></ProtectedRoute>}></Route>
                <Route path="/gdpr" element={<ProtectedRoute><InfoPage page={"gdpr"}/></ProtectedRoute>}></Route>
                <Route path="/notes" element={<ProtectedRoute><InfoPage page={"notes"}/></ProtectedRoute>}></Route>
              </Routes>
            </ModalProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ErrorBoundary >
  )
}

export default App
