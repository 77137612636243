import ViewSelectionWindow from '../components/view-selection/ViewSelectionWindow';
import Carousel from '../components/login/Carousel';
import './ViewSelection.scss';
import { useEffect } from 'react';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileViewSelection from 'components/view-selection/MobileViewSelection';
import axios from 'utils/axios';
import { useDispatch } from 'react-redux';
import { setClass } from 'redux/editorSlice';
import { useWindowSize } from 'utils/useWindowSize';

function ViewSelection() {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    loadStudentsInfo()
  }, [])

  const loadStudentsInfo = async () => {
    const studentsInfo = await axios.get("/api/current-student")
    dispatch(setClass(studentsInfo.data.class));
  }

  return (
    <ResponsiveComponent
      desktop={
        <section className="view-selection">
          {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
          <ViewSelectionWindow />
        </section>
      }
      mobile={
        <MobileViewSelection />
      }
    />
  )
}

export default ViewSelection;
